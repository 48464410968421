import qs from 'qs';
import _flow from 'lodash/flow';
import _uniqBy from 'lodash/uniqBy';

export const sanitizeQueryString = (urlString) =>
  urlString.split('?').pop() || '';

export const parseSanitizedQueryString = (sanitizedQueryString) =>
  qs.parse(sanitizedQueryString);

const remapFilterPayloads = (filters) => {
  return filters.map(([type, value]) => ({ type, value }));
};

const sanitizeFilterPayloadValue = (valuesArray) =>
  valuesArray.length === 1 && valuesArray[0] === '' ? [] : valuesArray;

const reformatFilterPayloadValues = (filters) =>
  filters.map(({ value, ...rest }) => ({
    ...rest,
    value: sanitizeFilterPayloadValue(value.split(',')),
  }));

export const DEFAULT_TAG_FILTER = { type: 'byAllTags', value: [] };
export const DEFAULT_CONTENT_TYPE_FILTER = { type: 'contentTypes', value: [] };

const DEFAULT_FILTERS = [DEFAULT_CONTENT_TYPE_FILTER, DEFAULT_TAG_FILTER];

const supplyDefaultFilters = (filters) => {
  const filtersWithSuggested = [...filters, ...DEFAULT_FILTERS];
  return _uniqBy(filtersWithSuggested, ({ type }) => type);
};

const determineFiltersCriteria = ({ filters = [] }) =>
  _flow(remapFilterPayloads, reformatFilterPayloadValues, supplyDefaultFilters)(
    filters,
  );

const determinePaginationCriteria = ({ perPage = 3, currentPage = 1 }) => ({
  perPage,
  currentPage,
});

export const getFeedCriteriaFromUrl = (urlString) => {
  const parsed = _flow(sanitizeQueryString, parseSanitizedQueryString)(
    urlString,
  );
  return {
    filters: determineFiltersCriteria(parsed),
    pagination: determinePaginationCriteria(parsed),
  };
};

const transformFilterValuesToUrl = (filters) =>
  filters.map(({ type, value }) => [type, value.join(',')]);

export const stitchNewFilterState = (prevCriteriaState, nextFilterState) => ({
  ...prevCriteriaState,
  filters: prevCriteriaState.filters.map((filterState) =>
    filterState.type === nextFilterState.type ? nextFilterState : filterState,
  ),
});

export const generateUrlFromFeedCriteria = ({
  location,
  feedCriteria,
}) => () => {
  const { filters, pagination } = feedCriteria;
  const queryParamsObject = {
    filters: transformFilterValuesToUrl(filters),
    ...pagination,
  };
  const pathWithoutQueryString = location.href.split('?')[0];
  return `${pathWithoutQueryString}?${qs.stringify(queryParamsObject)}`;
};
const navigateToUrl = (navigate) => (url) => navigate(url);
const setNextCriteriaState = ({ setCriteriaState, nextCriteriaState }) => () =>
  setCriteriaState(nextCriteriaState);

export const handleNextCriteria = ({
  setCriteriaState,
  locationAndNavigate: { location, navigate },
  nextCriteriaState,
}) =>
  _flow(
    generateUrlFromFeedCriteria({ location, feedCriteria: nextCriteriaState }),
    navigateToUrl(navigate),
    setNextCriteriaState({ setCriteriaState, nextCriteriaState }),
  );
