export const maintainAspectRatioWrapperStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  paddingBottom: '100%',
  overflow: 'hidden',
};

export const maintainAspectRatioInnerStyle = {
  position: 'absolute',
  width: 'auto',
  maxWidth: 'none',
  height: '100%',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
};
