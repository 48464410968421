import React from 'react';

import Observer from 'react-intersection-observer';

import { Box, theme } from 'design-system';

const fadeAnimation = theme.transitions.easeOut.xLong;

export const AnimateOnScroll = ({ revealDelay = 0, style, ...props }) => (
  <Observer>
    {({ inView, ref }) => (
      <div
        ref={ref}
        style={{
          ...style,
          opacity: `${inView ? 1 : 0}`,
          transition: `opacity ${fadeAnimation} ${revealDelay}s`,
        }}
      >
        {props.children}
      </div>
    )}
  </Observer>
);
