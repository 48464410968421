import system from 'system-components';
import React from 'react';
import { withStyles } from '../styles';

const StyledListItem = system(
  {
    is: 'li',
    blacklist: ['displayOnBreakpoint', 'displayBp'],
  },
  'space',
  'color',
  'display',
  'justifyContent',
  'alignItems',
  'position',
  'textAlign',
  'borders',
  'borderColor',
  'fontSize',
  'lineHeight',
  'fontFamily',
  // displayOnBreakpoint,
);

const listStyles = (theme) => ({
  default: {
    m: 0,
    p: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'left',
  },
  bullet: {
    fontSize: ['bodyS', 'bodyS', 'bodyS', 'bodyM'],
    // old [1, 1, 1, 2]
    color: theme.colors.text[0],
    fontFamily: 'regular',
    lineHeight: [4, 4, 4, 4, 4, 5],
    mb: theme.verticalSpace.xSmall,
  },
});

export const ListItem = withStyles(listStyles, StyledListItem);
ListItem.displayName = 'ListItem';

export const BulletListItem = (props) => (
  <ListItem variant="bullet" {...props} />
);
