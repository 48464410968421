import React from 'react';
import system from 'system-components';
import Observer from 'react-intersection-observer';
import { theme } from '../theme';

const fadeAnimation = theme.transitions.easeOut.xLong;

export const BaseImage = ({ revealDelay = 0, style, ...props }) => (
  <Observer>
    {({ inView, ref }) => (
      <img
        ref={ref}
        {...props}
        style={{
          ...style,
          opacity: `${inView ? 1 : 0}`,
          transition: `opacity ${fadeAnimation} ${revealDelay}s`,
        }}
        // TODO: add in alt text
        alt=""
      />
    )}
  </Observer>
);

export const Image = system(
  {
    is: BaseImage,
    width: '100%',
    display: 'block',
    height: 'auto',
  },
  'width',
  'height',
  'top',
  'left',
  'position',
);
Image.displayName = 'Image';
