import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../design-system';
import { ResponsiveProvider, NavProvider } from '../utils';

export const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveProvider>
        <NavProvider>{children}</NavProvider>
      </ResponsiveProvider>
    </ThemeProvider>
  );
};
