import React from 'react';
import { Box, Column, theme } from 'design-system';
import {
  SmallHeading,
  MediumHeading,
  LargestHeading,
  SmallCTA,
  TextParagraph,
} from 'components';

const headingSizeMap = {
  largest: LargestHeading,
  medium: MediumHeading,
  small: SmallHeading,
};

export const DefaultCard = ({
  heading,
  headingSize = 'small',
  paragraphText,
  cta,
  length,
  noXpadding,
}) => {
  const column = length !== 3 ? 1 / 2 : 1 / 3;

  const Heading = headingSizeMap[headingSize] || SmallHeading;

  let paddingBottom;

  if (length > 2) {
    paddingBottom = [theme.verticalSpace.medium, theme.verticalSpace.medium];
  } else {
    paddingBottom = [theme.verticalSpace.medium, '0'];
  }

  const BoxOrColumn = noXpadding ? Box : Column;

  return (
    <BoxOrColumn
      width={[1, column]}
      display="flex"
      flexDirection="column"
      pb={paddingBottom}
      data-qaid={heading ? 'defaultCard' : null}
    >
      <Heading
        text={heading}
        fontFamily="regular"
        pb={theme.verticalSpace.xSmall}
      />
      <TextParagraph
        text={paragraphText}
        pb={cta ? theme.verticalSpace.small : null}
      />
      {cta && <SmallCTA iconLink={cta} />}
    </BoxOrColumn>
  );
};
