import React from 'react';
import { fontSize } from 'design-system';
import { BaseText } from './Typography.Style';
import styled from 'styled-components';

const BaseButton = styled(BaseText)`
  cursor: pointer;
  background-color: transparent;
`;

export const Button = ({ label, theme, children, isActive, ...props }) => {
  return (
    <BaseButton
      as="button"
      data-on="click"
      data-event-category="Button"
      data-event-action="click"
      data-event-label={label}
      fontSize={fontSize.small}
      colors={`${theme}BackgroundCTAs`}
      className={isActive ? 'active' : ''}
      {...props}
    >
      {children}
    </BaseButton>
  );
};
