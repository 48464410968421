import React from 'react';
import { Heading, theme } from 'design-system';
import Observer from 'react-intersection-observer';

export const FooterHeading = ({
  darkBackground,
  revealDelay = false,
  text,
}) => (
  <Observer>
    {({ inView, ref }) => (
      <Heading
        is="h4"
        fontSize={['bodyL', 'bodyL', 'bodyL', 'subHeadingXl']} // old- {[3, 3, 3, 5]}
        lineHeight={[6, 6, 6, '44px']}
        colors={darkBackground ? 'darkBackgroundHeadings' : null}
        data-qaid={text ? 'mediumHeading' : null}
        innerRef={ref}
        m={0}
        mb={theme.verticalSpace.small}
        css={`
          opacity: ${!inView && revealDelay ? 0 : 1};
          transition: opacity ${theme.transitions.easeOut.long}
            ${theme.animationTime[1]}s;
        `}
      >
        {text}
      </Heading>
    )}
  </Observer>
);
