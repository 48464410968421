import React from 'react';
import { Text } from 'design-system';

export const TextFooter = ({ fontFamily, fontSize, paddingRight, text }) => (
  <Text
    fontSize={fontSize}
    color="text.1"
    fontFamily={fontFamily || 'light'}
    pr={paddingRight}
    pb={[3, 0]}
    lineHeight={5}
    letterSpacing="0.19px"
    data-qaid={text ? 'text' : null}
  >
    {text}
  </Text>
);
