import React from 'react';
import { Box, Column, theme } from 'design-system';
import { SmallCTA, SmallHeading, TextParagraph } from 'components';

export const ImageCard = ({ heading, paragraphText, cta, image, length }) => {
  const column = length !== 3 ? 1 / 2 : 1 / 3;
  let paddingBottom;
  let imageContextWidth;
  let imageContextGutterCount;
  if (length > 2) {
    paddingBottom = [theme.verticalSpace.medium, theme.verticalSpace.medium];
  } else {
    paddingBottom = [theme.verticalSpace.medium, '0'];
  }

  if (length === 3) {
    imageContextWidth = 0.33;
    imageContextGutterCount = 2;
  } else {
    imageContextWidth = 0.5;
    imageContextGutterCount = 1;
  }

  let imageWithContext = null;

  if (image !== null && image !== undefined) {
    imageWithContext = React.Children.map(image, (eachImage) =>
      React.cloneElement(eachImage, {
        imageContextWidth,
        imageContextGutterCount,
      }),
    );
  }

  return (
    <Column
      width={[1, column]}
      display="flex"
      flexDirection="column"
      pb={paddingBottom}
      data-qaid={heading ? 'imageCard' : null}
    >
      <Box pb={theme.verticalSpace.small}>{imageWithContext}</Box>
      <SmallHeading
        text={heading}
        fontFamily="regular"
        pb={theme.verticalSpace.xSmall}
      />
      <TextParagraph text={paragraphText} pb={theme.verticalSpace.xSmall} />
      <SmallCTA iconLink={cta} />
    </Column>
  );
};
