import React from 'react';
import Helmet from 'react-helmet';
import { SITE_CONFIG } from 'utils';

export const GeneralMeta = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="application-name" content={SITE_CONFIG.siteTitleMeta} />
      {SITE_CONFIG.appleTouchIcons.map(({ sizes, path }) => (
        <link
          key={`apple-touch-icon-precomposed-${sizes}`}
          rel="apple-touch-icon-precomposed"
          sizes={sizes}
          href={path}
        />
      ))}
      {SITE_CONFIG.favicons.map(({ sizes, path }) => (
        <link
          key={`favicon-${sizes}`}
          rel="icon"
          type="image/png"
          sizes={sizes}
          href={path}
        />
      ))}
      <meta name="msapplication-TileColor" content={SITE_CONFIG.tileColor} />
      <meta
        name="msapplication-TileImage"
        content="/images/mstile-144x144.png"
      />
      <meta
        name="msapplication-square70x70logo"
        content="/images/mstile-70x70.png"
      />
      <meta
        name="msapplication-square150x150logo"
        content="/images/mstile-150x150.png"
      />
      <meta
        name="msapplication-wide310x150logo"
        content="/images/mstile-310x150.png"
      />
      <meta
        name="msapplication-square310x310logo"
        content="/images/mstile-310x310.png"
      />
    </Helmet>
  );
};
