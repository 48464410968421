import React, { Component } from 'react';
import { theme, Box, Text } from 'design-system';

const BreadcrumbDivider = (
  <Text
    is="span"
    fontFamily="light"
    fontSize="footnote" // old {0}
    mx="16px"
    color="text.0"
  >
    /
  </Text>
);

const BreadcrumbBackIcon = (
  <Text
    is="span"
    fontFamily="light"
    fontSize="bodyM" // old {2}
    lineHeight={3}
    mr="8px"
    color="text.0"
  >
    ←
  </Text>
);

export class Breadcrumbs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // isFiltered: false,
      windowWidth: undefined,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    });

  render() {
    const { children } = this.props;
    const { windowWidth } = this.state;

    let BreadcrumbsSet;

    if (windowWidth < theme.rawBreakpoints[1]) {
      // For Mobile & Tablet
      BreadcrumbsSet = [BreadcrumbBackIcon, children[0]];
    } else {
      // For Desktop
      BreadcrumbsSet = [children[0], BreadcrumbDivider, children[1]];
    }

    // To do: get data from React Router or do a GraphQL query for the page data and relational info to make this dynamic
    return (
      <Box
        pt={[
          theme.verticalSpace.medium,
          theme.verticalSpace.small,
          '92px',
          theme.verticalSpace.large,
        ]}
        pb={[
          theme.verticalSpace.small,
          theme.verticalSpace.small,
          theme.verticalSpace.small,
          theme.verticalSpace.medium,
        ]}
      >
        {BreadcrumbsSet}
      </Box>
    );
  }
}
