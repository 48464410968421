import system from 'system-components';

import {
  firstChild,
  lastChild,
  nthChild,
  nthLastChild,
  textDecoration,
} from '../styles';

export const Text = system(
  {
    is: 'div',
    fontSize: 'bodyL', // old 3
    color: 'text.0',
    fontFamily: 'light',
    blacklist: [
      'ellipsis',
      'firstChild',
      'lastChild',
      'nthChild',
      'nthLastChild',
    ],
  },
  // core
  'position',
  'display',
  'top',
  'space',
  'width',
  'color',
  'fontSize',
  'display',
  // typography
  'fontFamily',
  'fontWeight',
  'textAlign',
  'lineHeight',
  'letterSpacing',
  'hover',
  textDecoration,
  firstChild,
  lastChild,
  nthChild,
  nthLastChild,
  (props) => {
    const styles = {};
    if (props.ellipsis) {
      styles.textOverflow = 'ellipsis';
      styles.overflow = 'hidden';
      styles.whiteSpace = 'nowrap';
      if (props.is === 'span') {
        styles.display = 'inline-block';
      }
    }
    return styles;
  },
);
Text.displayName = 'Text';
