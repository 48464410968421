/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'whatwg-fetch';

export const onInitialClientRender = () => {
  window.pageTransitionsOn = true;
};

/* eslint-disable  */
const backtracksPlayerScript = function(p, l, a, y, e, r, s) {
  if (p[y]) return;
  if (p[e]) return p[e]();
  s = l.createElement(a);
  l.head.appendChild(((s.async = p[y] = true), (s.src = r), s));
};
/* eslint-enable  */

export const onRouteUpdate = () => {
  if (document.activeElement) {
    // const main = document.getElementById('main')
    // main.tabIndex = 5
    document.activeElement.blur();
  }

  backtracksPlayerScript(
    window,
    document,
    'script',
    '__btL',
    '__btR',
    'https://player.backtracks.fm/embedder.js',
  );
};
