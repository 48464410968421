import React from 'react';
import { Text, Column, withStyles } from '../../../design-system';
import { TextMarkdown } from '../../elements/TextMarkdown';
import { AccordionPanel } from '../../elements/AccordionPanel';

const SummaryText = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      ...theme.typography[themeType].body2,
      m: 0,
      // override body2 color for hover from SummaryContainer
      color: 'inherit',
      fontWeight: 'normal',
    },
  }),
  Text,
);

const SummaryContent = ({ children }) => (
  <Column px={[0]} width={[3 / 4, 5 / 6, 5 / 6, 7 / 8]}>
    <SummaryText is="h3">{children}</SummaryText>
  </Column>
);

const DetailContent = ({ children }) => (
  <Column px={0} width={[1, 5 / 6, 5 / 6, 7 / 8]}>
    <TextMarkdown>{children}</TextMarkdown>
  </Column>
);

export const AccordionItem = ({
  entryId = (Math.random() / 10 ** -16).toString(16),
  buildContext,
  accordionItemName,
  accordionItemSummary = '',
  accordionItemDetails = '',
  ...props
}) => {
  const QAID = {
    'data-qaid':
      accordionItemSummary && accordionItemDetails ? 'AccordionItem' : null,
  };
  return (
    <AccordionPanel
      headingLevel={3}
      name={accordionItemName || entryId}
      summary={<SummaryContent>{accordionItemSummary}</SummaryContent>}
      details={<DetailContent>{accordionItemDetails}</DetailContent>}
      {...props}
      {...QAID}
    />
  );
};
AccordionItem.displayName = 'AccordionItem';
