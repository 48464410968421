import React from 'react';
import {
  Box,
  BulletListItem,
  Column,
  Image,
  List,
  Row,
  theme,
} from 'design-system';
import {
  MediumHeading,
  SmallCTA,
  FullTextMarkdown,
  TextMarkdown,
  TextParagraph,
} from 'components';
import { getImageURL } from 'utils';

export const SectionHeader = ({
  entryId,
  buildContext,
  //
  hidden = false,
  hideTitle: hideTitleProp = false,
  imageAboveTitle,
  imageAboveTitleAltText,
  title,
  paragraphText,
  useFullTextMarkdown = false,
  ctaSection,
  textPosition = 'Left',
  image,
  embedCode,
  bullets,
  textLeftPadding = false,
  mediaMobileOrder = '0',
  hideMediaColumn = false,
  ...props
}) => {
  const imageAboveTitleURL = getImageURL(imageAboveTitle);
  const hideTitle = hideTitleProp || !title;

  let tabletWidthLeft;
  let tabletWidthRight;
  let imageWithContext = null;

  if (image !== undefined && image != null) {
    tabletWidthLeft = [1, 1 / 2];
    tabletWidthRight = [1, 1 / 2];
    imageWithContext = React.cloneElement(image, {
      imageContextWidth: 0.5,
      imageContextGutterCount: 1,
    });
  } else if (embedCode !== undefined && embedCode != null) {
    tabletWidthLeft = [1, 1 / 2];
    tabletWidthRight = [1, 1 / 2];
  } else if (!image && textPosition === 'Left') {
    if (!paragraphText) {
      tabletWidthLeft = [1, 2 / 3, 2 / 3, 3 / 4];
    } else {
      tabletWidthLeft = [1, 2 / 3, 2 / 3, 1 / 2];
      tabletWidthRight = [1, 1 / 3, 1 / 3, 1 / 2];
    }
  } else if (!image && textPosition === 'Right') {
    tabletWidthRight = [1, 2 / 3, 2 / 3, 1 / 2];
    tabletWidthLeft = [1, 1 / 3, 1 / 3, 1 / 2];
  }
  const paddingBottom =
    !image && !paragraphText && !ctaSection && title
      ? theme.verticalSpace.medium
      : null;
  const content = (
    <Box width={1}>
      {imageAboveTitleURL ? (
        <Box
          display="block"
          // pb={theme.verticalSpace.small}
        >
          <Image
            src={imageAboveTitleURL}
            alt={imageAboveTitleAltText}
            css="max-width: 21.9%;"
          />
        </Box>
      ) : null}

      <MediumHeading
        darkBackground={false}
        text={title}
        pb={
          paragraphText || ctaSection
            ? theme.verticalSpace.xSmall
            : paddingBottom
        }
        mt={imageAboveTitleURL ? theme.verticalSpace.small : null}
        // pb={paragraphText || ctaSection ? theme.verticalSpace.xSmall : null}
        display={hideTitle ? 'none' : 'flex'}
      />

      {paragraphText ? (
        <Box pb={ctaSection ? theme.verticalSpace.small : null}>
          <TextParagraph
            width={image ? [1, 1, 1, 1, 1, 3 / 4] : [1]}
            mb={theme.verticalSpace.small}
          >
            {useFullTextMarkdown ? (
              <FullTextMarkdown>{paragraphText}</FullTextMarkdown>
            ) : (
              <TextMarkdown>{paragraphText}</TextMarkdown>
            )}
          </TextParagraph>
          {bullets ? (
            <List data-qaid={bullets ? 'BulletList' : null} {...props}>
              {React.Children.map(bullets, (item) => (
                <BulletListItem>{item}</BulletListItem>
              ))}
            </List>
          ) : null}
        </Box>
      ) : null}
      {ctaSection ? (
        <SmallCTA
          mt={theme.verticalSpace.xSmall}
          darkBackground={false}
          iconLink={ctaSection}
        />
      ) : null}
    </Box>
  );

  const mediaContent = imageWithContext || embedCode || null;

  const mobileLayout = (
    <Box display="flex" flexDirection="row" flexWrap="wrap" width={1}>
      {mediaContent ? (
        <Column
          width={1}
          pb={theme.verticalSpace.small}
          order={mediaMobileOrder}
        >
          {/*  Image goes here if it exists */}
          {mediaContent}
        </Column>
      ) : null}
      <Column width={1}>
        {/* Text Content Always go here */}
        {content}
      </Column>
    </Box>
  );

  let desktopLayout;

  if (mediaContent) {
    desktopLayout = (
      <Box display="flex" flexDirection="row" width={1}>
        <Column width={tabletWidthLeft}>
          {textPosition === 'Left' ? content : mediaContent}
        </Column>
        <Column width={tabletWidthRight}>
          {textPosition === 'Right' ? content : mediaContent}
        </Column>
      </Box>
    );
  } else if (!mediaContent && hideMediaColumn) {
    desktopLayout = (
      <Box display="flex" width={1}>
        <Column width={1}>{content}</Column>
      </Box>
    );
  } else if (!mediaContent && !hideMediaColumn) {
    desktopLayout = (
      <Box display="flex" width={1}>
        <Column width={tabletWidthLeft}>
          {textPosition === 'Left' ? content : null}
        </Column>
        <Column width={tabletWidthRight}>
          {textPosition === 'Right' ? content : null}
        </Column>
      </Box>
    );
  }

  return (
    <Box
      display={hidden ? 'none' : 'flex'}
      width={textLeftPadding && 1}
      mx={textLeftPadding && 0}
      {...props}
    >
      <Row
        width={1}
        flexDirection="row"
        data-qaid={title ? 'sectionHeader' : null}
      >
        <Box display={['flex', 'none']}>{mobileLayout}</Box>

        <Box display={['none', 'flex']} width={1}>
          {desktopLayout}
        </Box>
      </Row>
    </Box>
  );
};
