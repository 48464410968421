import React from 'react';
import { Box, Caption, theme } from 'design-system';
import { LargeCTA } from 'components';

export const NewsCard = (props) => {
  const { title, ctaLink, datePublished } = props;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={['column']}
      pt={theme.verticalSpace.xSmall}
      pb={theme.verticalSpace.large}
      data-qaid={title ? 'NewsCard' : null}
      borderTop={`1px solid ${theme.colors.uiDivider}`}
    >
      <LargeCTA iconLink={ctaLink} pb={theme.verticalSpace.small} />
      <Caption is="p" m="0">
        {datePublished}
      </Caption>
    </Box>
  );
};
