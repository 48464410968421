import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { Box } from 'design-system';
import * as typeformEmbed from '@typeform/embed';
import { Link as AnchorLink } from 'react-scroll';

import { BaseText } from './Typography.Style';
import { camelize } from '../patterns';
import {
  verticalSpace,
  horizontalSpace,
  fontSize,
} from '../../design-system/theme';
import { FadeInWrapper } from '../../utils/components';

const styles = {
  fontWeight: 'medium',
};

const fontSizeMap = {
  small: fontSize.xSmall,
  medium: fontSize.small,
  large: fontSize.medium,
};

const buttonStyles = {
  display: 'inline-block',
  flexWrap: 'wrap',
  justifyContent: 'center',
  textDecoration: 'none',
  height: '100%',
  px: verticalSpace.large,
  py: verticalSpace.xSmall,
  fontSize: fontSize.small,
};

const CTA = styled(BaseText)`
  ${styles}
`;

const noop = () => {};

export const Link = ({
  paddingH = 'none',
  linkConfig = { props: { url: '' } },
  hideArrow = false,
  arrowOverride,
  arrowPosition = 'right',
  theme = 'light',
  label,
  type = 'small',
  children,
  alignment = 'left',
  width,
  handleOnHover = noop,
  handleOnHoverLeave = noop,
  isHoverable,
  isActive,
  isReducedMotion,
  animation,
  ...props
}) => {
  let popup;

  let { url, analyticsLabel } = linkConfig.props;
  if (!url) {
    url = '';
  }

  const { scrollY } = props;
  const Wrapper =
    isReducedMotion || !scrollY || animation ? React.Fragment : FadeInWrapper;
  const wrapperProps = isReducedMotion || !scrollY ? {} : { ...props };
  const isMailTo = (url) => /^mailto?/i.test(url);
  const isHttp = (url) => /^http?/i.test(url);
  const isAnchor = /^#/i.test(url);
  const isTypeform = url.indexOf('typeform') > -1;
  const isExternal = isHttp(url) || isMailTo(url);

  const createPopup = () => {
    const url = linkConfig.props.url;
    popup = typeformEmbed.makePopup(url, {});
  };

  useEffect(() => {
    if (isTypeform) {
      createPopup();
    }
  });

  // The &nbsp; ensure that the arrow wraps with the last word in the label (only works for text icons)
  const LinkArrow = ({ arrowPosition }) => {
    let arrow = '→';
    if (isExternal) {
      arrow = '↗';
    } else if (isAnchor) {
      arrow = '↓';
    }
    return hideArrow ? null : (
      <>
        {arrowPosition === 'right' && <>&nbsp;</>}
        {arrowOverride || arrow}
        {arrowPosition === 'left' && <>&nbsp;</>}
      </>
    );
  };

  const CTAComponent = isExternal ? 'a' : isAnchor ? AnchorLink : GatsbyLink;

  const isButton = type === 'Button' || type === 'Secondary Button';

  const internalProps = { to: url.startsWith('/') ? url : `/${url}`};
  const externalProps = {
    href: url,
    target: isMailTo(url) ? '_self' : '_blank',
  };
  const anchorProps = {
    to: url ? url.replace('#', '') : '',
    smooth: true,
    spy: false,
    hashSpy: false,
    duration: 500,
    offset: -95,
    tabIndex: 0,
    ariaLabel: `Jump down to ${label}`,
    href: url,
  };
  const typeformProps = {
    role: 'button',
    onClick: () => popup.open(),
  };

  // url is '' when a component in contentful is not published
  return (
    url !== '' && (
      <Box
        px={horizontalSpace[camelize(paddingH)]}
        textAlign={camelize(alignment)}
      >
        {url ? (
          <Wrapper {...wrapperProps}>
            <CTA
              as={CTAComponent}
              className={isActive ? 'active' : ''}
              {...(isExternal && !isTypeform ? externalProps : internalProps)}
              {...(isTypeform ? typeformProps : {})}
              {...(isAnchor ? anchorProps : {})}
              fontSize={fontSizeMap[camelize(type)]}
              {...(isButton ? buttonStyles : {})}
              colors={`${theme}Background${
                type === 'Secondary Button' ? 'Secondary' : ''
              }${isButton ? 'Button' : 'CTAs'}`}
              width={width === '100%' ? '100%' : 'auto'}
              data-on="click"
              data-event-category="CTA"
              data-event-action="click"
              data-event-label={`${analyticsLabel || label} | ${url}`}
              onMouseEnter={isHoverable ? handleOnHover : noop}
              onMouseLeave={isHoverable ? handleOnHoverLeave : noop}
              display="inline-block"
              {...props}
            >
              {!isTypeform && arrowPosition === 'left' && (
                <LinkArrow arrowPosition={arrowPosition} />
              )}
              <span className="label">{label || children}</span>
              {!isTypeform && arrowPosition === 'right' && (
                <LinkArrow arrowPosition={arrowPosition} />
              )}
            </CTA>
          </Wrapper>
        ) : (
          <span>No LinkConfig was provided</span>
        )}
      </Box>
    )
  );
};
