import React from 'react';
import { Heading } from 'design-system';

export const MediumHeading = ({ darkBackground, text, ...props }) => (
  <Heading
    is="h2"
    fontSize={['bodyL', 'bodyL', 'bodyL', 'subHeadingXl']}
    // old [3, 3, 3, 5]
    lineHeight={[6, 6, 6, '44px']}
    m={0}
    colors={darkBackground ? 'darkBackgroundHeadings' : null}
    data-qaid={text ? 'mediumHeading' : null}
    {...props}
  >
    {text}
  </Heading>
);
