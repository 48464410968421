import { getImageURL } from 'utils';
import moment from 'moment';

export const nodeNormalizers = {
  podcast: (node) => ({
    ...node,
    nodeType: 'podcast',
    publishedAt: node.props.publishedDate,
    slug: node.props.slug,
  }),
  blog: (node) => ({
    ...node,
    nodeType: 'blog',
    publishedAt: node.published_at,
  }),
};

const createRenderObjForPodcast = ({ nodeType, props: podcastProps }) => ({
  nodeType,
  title: podcastProps.heading,
  imageUrl: getImageURL(podcastProps.featuredImage),
  publishedDate: moment(podcastProps.publishedDate).format('MMMM D, YYYY'),
  tagsString: (podcastProps.tags || [])
    .map(({ props: tagProps }) => tagProps.title)
    .join(', '),
  linkUrl: `/podcasts/${podcastProps.slug}`,
});

const createRenderObjForBlog = ({
  nodeType,
  title,
  feature_image: imageUrl,
  published_at_pretty: publishedDate,
  tags,
  slug,
}) => ({
  nodeType,
  title,
  imageUrl,
  publishedDate,
  tagsString: tags.map(({ name }) => name).join(', '),
  linkUrl: `/blog/${slug}`,
});

const renderNormalizers = {
  podcast: createRenderObjForPodcast,
  blog: createRenderObjForBlog,
};

export const normalizeNodesForRender = (nodes) =>
  nodes.map((node) => renderNormalizers[node.nodeType](node));
