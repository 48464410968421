import React, { useState, useEffect, useRef } from 'react';
import { Box } from 'design-system';

import { camelize, LazyLoadWrapper } from '../patterns';
import { PlayPauseButton } from '../elements/PlayPauseButton';
import { horizontalSpace } from '../../design-system/theme';
import { Image } from '.';

const BaseCloudinaryVideo = ({
  paddingH = 'none',
  publicId,
  autoplay = false,
  muted = false,
  cloudName = 'rangle',
  previewImageTime = '0',
}) => {
  const player = useRef(null);
  const [isPlaying, setIsPlaying] = useIsPlaying(
    publicId,
    player,
    autoplay,
    muted,
  );
  const togglePlayingStatus = () => setIsPlaying(!isPlaying);

  return (
    <Box
      px={horizontalSpace[camelize(paddingH)]}
      position="relative"
      onClick={() => togglePlayingStatus()}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={player}
        height="100%"
        width="100%"
        controls={isPlaying}
        controlslist="nodownload"
        playsInline
        preload="auto"
        autoPlay={autoplay}
        muted={muted}
      >
        <source
          src={`https://res.cloudinary.com/${cloudName}/video/upload/rangle.io/${publicId}.webm`}
          type="video/webm"
        />
        <source
          src={`https://res.cloudinary.com/${cloudName}/video/upload/rangle.io/${publicId}.mp4`}
          type="video/mp4"
        />
        <source
          src={`https://res.cloudinary.com/${cloudName}/video/upload/rangle.io/${publicId}.ogv`}
          type="video/ogg"
        />
        Your browser doesn't support HTML5 video tag.
      </video>
      {(!player.current || player.current && player.current.currentTime === 0 && !isPlaying) && (
        <Box position="absolute" bottom="0" width="80%">
          <Image
            isAnimated={false}
            isReducedMotion={true}
            overideUrl={`https://res.cloudinary.com/${cloudName}/video/upload/so_${previewImageTime}/rangle.io/${publicId}.jpg`}
          />
        </Box>
      )}
      {!isPlaying && (
        <Box
          position="absolute"
          zIndex={3}
          left="calc(50% - 24px)"
          bottom="calc(50% - 24px)"
        >
          <PlayPauseButton
            play={isPlaying}
            aria-label="Play video"
            fontSize="subHeadingM" // old "24px"
          />
        </Box>
      )}
    </Box>
  );
};

const useIsPlaying = (publicId, player, autoplay, muted) => {
  const initalRender = useRef(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const play = () => setIsPlaying(true);
  const pause = () => setIsPlaying(false);
  const track = (action, value, nonInteraction) =>
    window.gtag &&
    window.gtag('event', action, {
      event_category: 'Video',
      event_label: publicId,
      ...(value ? { value } : null),
      non_interaction: !!nonInteraction,
    });
  const trackStart = () => track('Start', null, true);
  const trackPlay = () => track('Play', null, autoplay);
  const trackTimeUpdate = () =>
    track('Time Update', player.current.currentTime.toFixed(1), true);
  const trackPause = () => track('Pause', null, autoplay);
  const trackSeeking = () => {
    play();
    return track('Seeking', player.current.currentTime.toFixed(1), true);
  };
  const trackSeeked = () =>
    track('Seek', player.current.currentTime.toFixed(1), true);
  const trackEnded = () => track('Ended', null, true);
  const trackVolumeChange = (p) =>
    track('Volume Change', player.current.volume.toFixed(1), muted);
  const trackError = () => track('Error', null, true);

  useEffect(() => {
    if (initalRender.current) {
      player.current.addEventListener('play', play, false);
      player.current.addEventListener('pause', pause, false);
      player.current.addEventListener('playing', trackStart, false);
      player.current.addEventListener('play', trackPlay, false);
      player.current.addEventListener('timeupdate', trackTimeUpdate, false);
      player.current.addEventListener('pause', trackPause, false);
      player.current.addEventListener('seeking', trackSeeking, false);
      player.current.addEventListener('seeked', trackSeeked, false);
      player.current.addEventListener('ended', trackEnded, false);
      player.current.addEventListener('volumechange', trackVolumeChange, false);
      player.current.addEventListener('error', trackError, false);
      initalRender.current = false;
      player.current.disablePictureInPicture = true;
      return;
    }

    if (isPlaying) {
      player.current.play();
    } else if (!player.current.seeking) {
      player.current.pause();
    }

    return () => {
      player.current.removeEventListener('play', play, false);
      player.current.removeEventListener('pause', pause, false);
      player.current.removeEventListener('playing', trackStart, false);
      player.current.removeEventListener('play', trackPlay, false);
      player.current.removeEventListener('timeupdate', trackTimeUpdate, false);
      player.current.removeEventListener('pause', trackPause, false);
      player.current.removeEventListener('seeking', trackSeeking, false);
      player.current.removeEventListener('seeked', trackSeeked, false);
      player.current.removeEventListener('ended', trackEnded, false);
      player.current.removeEventListener(
        'volumechange',
        trackVolumeChange,
        false,
      );
      player.current.removeEventListener('error', trackError, false);
    };
  }, [isPlaying]);

  return [isPlaying, setIsPlaying];
};

export const CloudinaryVideo = ({isReducedMotion, ...props}) => (
  <LazyLoadWrapper isReducedMotion={isReducedMotion}>
    <BaseCloudinaryVideo {...props}/>
  </LazyLoadWrapper>
);
