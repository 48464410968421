import system from 'system-components';
import { textDecoration } from '../styles';

export const OverflowLabel = system(
  {
    is: 'span',
    color: 'inherit',
    blacklist: ['ellipsis'],
  },
  'space',
  'color',
  'colorStyle',
  'flexDirection',
  'flexWrap',
  'flex',
  textDecoration,
  (props) => {
    const styles = {};
    if (props.ellipsis) {
      styles.textOverflow = 'ellipsis';
      styles.overflow = 'hidden';
      styles.whiteSpace = 'nowrap';
    }
    return styles;
  },
);
OverflowLabel.displayName = 'OverflowLabel';
