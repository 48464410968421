import system from 'system-components';

export const RangleTriangle = system(
  {
    is: 'div',
    width: 0,
    height: 0,
    borderBottom: '100px solid rgba(251,91,27,1)',
    borderLeft: '100px solid transparent',
  },
  // core
  'space',
  'colors',
  'borders',
);
RangleTriangle.displayName = 'RangleTriangle';
