import styled from 'styled-components';
import { space, color } from 'styled-system';
import { Box } from './Box';

export const ExpansionPanelDetail = styled(Box)`
  ${space}
  ${color}
  p: 0;
  m: 0;
`;

ExpansionPanelDetail.displayName = 'ExpansionPanelDetail';
