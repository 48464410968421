import React, { cloneElement, useEffect, useState } from 'react';
import { useViewportScroll } from 'framer-motion';

import '../../utils/importIntersectionObserver';
import { Box, columnWidth, verticalSpace } from 'design-system';
import { PageHelmet } from 'components';
import { getImageURL, formatTitle, withNavState } from 'utils';

import { applyTheme, themeMap } from '../../utils';
import { camelize } from '../patterns/Camelize';

const language = 'en';

export const BasePage = ({
  title = '',
  description = '',
  tags = '',
  metaImage = null,
  pageType,
  pageTopic,
  slug,
  pageHeader,
  sections,
  impressions = [],
  blocks = [],
  backgroundColour = 'white',
  data = {},
  extraMeta,
  hideSideNav,
  updateNavThemes,
}) => {
  const [isReducedMotion, setIsReducedMotion] = useState(false);
  const { scrollY } = useViewportScroll();

  const handleOnMotionQueryChange = () => {
    const motionQuery = window.matchMedia('(prefers-reduced-motion)');
    setIsReducedMotion(motionQuery.matches);
  };

  useEffect(() => {
    if (window) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Custom-PageView',
        pageType: `${pageType}`,
        pageTopic: `${pageTopic}`,
        pagePath: `${slug}`,
        pageTitle: `${formatTitle(title)}`,
      });

      const motionQuery = window.matchMedia('(prefers-reduced-motion)');
      motionQuery.onchange = handleOnMotionQueryChange;
      const isReducedMotion = motionQuery.matches;
      setIsReducedMotion(isReducedMotion);
    }

    const pageTheme = themeMap[camelize(backgroundColour)];
    let topBlockTheme = 'default';
    let hasVideo = false;
    // We can remove this check once we depreciate sections and section headers
    if (blocks.length > 0) {
      const {
        props: {
          backgroundColour: layoutColour,
          mediaFileName,
          componentType,
          blocks: layoutBlocks,
        },
      } = applyTheme(blocks, undefined, backgroundColour)[0];

      hasVideo = !!mediaFileName;

      if (layoutBlocks) {
        topBlockTheme = layoutColour
          ? themeMap[camelize(layoutColour)]
          : pageTheme;

        if (componentType === 'fullBleed' && hasVideo) {
          topBlockTheme = 'dark';
        }
      }
    }

    updateNavThemes({ topBlockTheme, pageTheme });
  }, []);

  const blocksWithProps = blocks.map((block, index) =>
    cloneElement(block, {
      isReducedMotion,
      scrollY,
      ...(index === 0 ? { pt: verticalSpace.topNavSpacing } : {}), // topnav-offset spacing
    }),
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-page
      bg={camelize(backgroundColour)}
      ml={[0, 0, hideSideNav ? 0 : columnWidth(2)]}
      // Note(bertrandk): data-page is used by Sticky.js to target the transition wrapper underneath it
    >
      <PageHelmet
        language={language}
        title={title}
        description={description}
        tags={tags}
        impressions={impressions}
        image={getImageURL(metaImage)}
        pageType={pageType}
        pageTopic={pageTopic}
        data={data}
        extraMeta={extraMeta}
      />
      <Box name="page-content" id="main-content">
        {pageHeader}
        {applyTheme(blocksWithProps, undefined, backgroundColour)}
        <Box flex="1 0">{sections}</Box>
      </Box>
    </Box>
  );
};

export const Page = withNavState(BasePage);
