import React, { useState } from 'react';
import _flow from 'lodash/flow';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import qs from 'qs';

import { getSiteSettings, withLocation } from 'utils';

const defaultTrackingQueryParameters = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];
const getSearchFromLocation = (location) => _get(location, 'search', '');
const sanitizeSearch = (searchString) => searchString.split('?').pop();
const parseSearch = (searchString) => qs.parse(searchString);
const restrictParsedParams = (whitelist) => (parsedSearch) =>
  _pick(parsedSearch, whitelist);

const getTrackableQueryValues = ({ location, whitelist }) =>
  _flow(
    getSearchFromLocation,
    sanitizeSearch,
    parseSearch,
    restrictParsedParams(whitelist),
  )(location);

const withTrackingQueryParameters = (Wrapped) =>
  withLocation(({ location: locationAndNavigate, ...wrappedProps }) => {
    const [trackingValues, setTrackingValues] = useState(null);

    const siteSettings = getSiteSettings();
    const trackingQueryParameters = _get(
      siteSettings,
      'props.trackingSettings.props.trackingQueryParameters',
      defaultTrackingQueryParameters,
    );
    const trackableQueryValues = getTrackableQueryValues({
      location: locationAndNavigate.location,
      whitelist: trackingQueryParameters,
    });

    if (trackingValues === null && !_isEqual(trackableQueryValues, {})) {
      setTrackingValues(trackableQueryValues);
    }

    return <Wrapped {...wrappedProps} trackingValues={trackingValues} />;
  });

const TrackingContext = React.createContext({
  queryParameterValues: null,
});

export const TrackingProvider = withTrackingQueryParameters(
  ({ children, trackingValues }) => (
    <TrackingContext.Provider value={trackingValues}>
      {children}
    </TrackingContext.Provider>
  ),
);

export const withTrackingValues = (Wrapped) => (wrappedProps) => (
  <TrackingContext.Consumer>
    {(trackingContextValue) => (
      <Wrapped {...wrappedProps} trackingValues={trackingContextValue} />
    )}
  </TrackingContext.Consumer>
);
