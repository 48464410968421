import React from 'react';
import { theme, Box, Text } from 'design-system';
import { SmallCTA } from 'components';

export const EmphasizedParagraph = ({
  title,
  content,
  cta,
  largerTitle = false,
}) => (
  <Box data-qaid={title && content ? 'EmphasizedParagraph' : null}>
    <Text
      data-qaid="Text"
      color="text.0"
      fontFamily={largerTitle ? 'light' : 'regular'}
      fontSize={
        largerTitle
          ? ['bodyM', 'bodyL', 'bodyL', 'subHeadingXl']
          : ['bodyM', 'bodyM', 'bodyM', 'bodyL']
      }
      // {largerTitle ? [2, 3, 3, 5] : [2, 2, 2, 3]}
      lineHeight={largerTitle ? [6, 6, 6, '44px'] : [4, 4, 4, 6]}
      mb={theme.verticalSpace.xSmall}
    >
      {title}
    </Text>
    <Text
      data-qaid="Text"
      color="text.3"
      fontFamily="light"
      fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
      // old [2, 2, 2, 3]
      lineHeight={[4, 4, 4, 6]}
      mb={
        cta
          ? [
              theme.verticalSpace.small,
              theme.verticalSpace.small,
              theme.verticalSpace.xSmall,
            ]
          : 0
      }
    >
      {content}
    </Text>
    {cta && <SmallCTA iconLink={cta} data-qaid="Small CTA" />}
  </Box>
);
