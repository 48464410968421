import React from 'react';
import { theme, Box, Text } from 'design-system';

import { camelize } from '../patterns/Camelize';
import { horizontalSpace } from '../../design-system/theme';

const YOUTUBE_REGEX_MATCH = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
const getVideoEmbedUrl = (url) => {
  const id = url.match(YOUTUBE_REGEX_MATCH)[1];
  const embedUrl = `https://www.youtube.com/embed/${id}`;
  return embedUrl;
};

export const YoutubeVideo = ({
  youtubeShareLink,
  title,
  paddingH = 'none',
}) => {
  return youtubeShareLink ? (
    <Box
      px={horizontalSpace[camelize(paddingH)]}
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      {title && (
        <Text is="h3" mt="0" fontFamily="rangle riforma" fontWeight="medium">
          {title}
        </Text>
      )}
      {/* This is to ensure we have a 16:9 aspect ratio */}
      <Box position="relative" pb="56.25%">
        <iframe
          title={title || youtubeShareLink}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          src={getVideoEmbedUrl(youtubeShareLink)}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    </Box>
  ) : null;
};
