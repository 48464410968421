import React from 'react';
import Observer from 'react-intersection-observer';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Box } from 'design-system';
import { LottieAnimation } from 'components';
import { camelize, LazyLoadWrapper } from '../patterns';
import { horizontalSpace, getOffset } from '../../design-system/theme';
import { ParallaxImageWrapper } from '../../utils/components';

// NOTE: auto prefixed 'q_auto' and 'f_auto' in the url is to ensure image quality
// Read to find more about it
// https://cloudinary.com/blog/the_holy_grail_of_image_optimization_or_balancing_visual_quality_and_file_size
const positionMap = {
  top: 'flex-start',
  bottom: 'flex-end',
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

export const Image = ({
  url = '',
  alt = '',
  paddingH = 'none',
  width = '100%',
  height = 'auto',
  positionV = 'center',
  positionH = 'center',
  offset,
  opacity = '1',
  isLoop = false,
  isReducedMotion,
  overideUrl,
  animation,
  imageStyles,
  color,
  ...props
}) => {
  const ImageWrapperComponent =
    !isReducedMotion && animation ? ParallaxImageWrapper : Box;
  const isSVG = /.*\.(svg)$/g.test(url);
  const isGIF = /.*\.(gif)$/g.test(url);
  const isJson = /.*\.(json)$/g.test(url);
  let imageUrl =
    overideUrl ||
    `https://res.cloudinary.com/rangle/${
      isJson ? 'raw' : 'image'
    }/upload/${color || ''}${
      isSVG || isGIF || isJson ? '' : 'q_auto,f_auto/'
    }rangle.io/${url}`;
  const styles = {
    wrapper: {
      width,
    },
    image: {
      width: '100%',
      height: height,
      display: 'block',
      objectFit: width === '100%' && height === '100%' ? 'cover' : 'unset',
      ...imageStyles,
    },
  };

  const Lottie = () => (
    <Observer>
      {({ inView, ref }) => (
        <div ref={ref}>
          <LottieAnimation
            url={imageUrl}
            isLoop={isLoop}
            inView={inView}
            isReducedMotion={isReducedMotion}
          />
        </div>
      )}
    </Observer>
  );

  const Video = () => (
    <video autoPlay loop muted playsinline width="100%">
      <source src={imageUrl.replace('gif', 'webm')} type="video/webm" />
      <source src={imageUrl.replace('gif', 'mp4')} type="video/mp4" />
    </video>
  );

  const PlainImage = () => (
    <img
      style={styles.image}
      src={url.length === 0 && !overideUrl ? ' ' : imageUrl}
      alt={alt}
    />
  );

  const buildImage = () => {
    if (isJson) {
      return Lottie();
    } else if (isGIF) {
      // This ensures that gifs are static when reduce motion is turned on
      if (isReducedMotion) {
        imageUrl = imageUrl.replace('gif', 'png');
        return PlainImage();
      }

      return Video();
    } else {
      return PlainImage();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent={positionMap[camelize(positionH)]}
      px={horizontalSpace[camelize(paddingH)]}
      className="image"
      width="100%"
      height={height}
      opacity={opacity}
      alignSelf={positionMap[camelize(positionV)]}
      mt={getOffset(camelize(offset))}
      {...props}
    >
      <LazyLoadWrapper isReducedMotion={isReducedMotion}>
        <ImageWrapperComponent style={styles.wrapper} {...props}>
          {buildImage()}
        </ImageWrapperComponent>
      </LazyLoadWrapper>
    </Box>
  );
};
