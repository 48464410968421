import React from 'react';
import { Button } from './Button';
import { withStyles } from '../styles';

/* const StyledSummaryButton = system(
    {
        is: Button,
        bg: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        textAlign: 'left',
        blacklist: ['textDecoration']
    },
    textDecoration,
    ({ disabled = false, ignorePointer = false }) => ({
        outline: 'none',
        cursor: disabled || ignorePointer ? 'default' : 'pointer',
    })
); */

const StyledSummary = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      colors: props.colors || `${themeType}BackgroundCTAs`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      textAlign: 'left',
    },
  }),
  Button,
);

export class ExpansionPanelSummary extends React.Component {
  handleChange = (event) => {
    const { onChange, onClick } = this.props;

    if (onChange) {
      onChange(event);
    }
    if (onClick) {
      onClick(event);
    }
  };

  render() {
    const {
      id,
      children,
      expanded,
      expandIcon = null,
      IconProps,
      onChange,
      ...other
    } = this.props;

    return (
      <StyledSummary
        id={id}
        role="button"
        onClick={this.handleChange}
        aria-expanded={expanded}
        {...other}
      >
        {children}
        {expandIcon}
      </StyledSummary>
    );
  }
}
ExpansionPanelSummary.displayName = 'ExpansionPanelSummary';
