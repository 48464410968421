import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export const FadeInWrapper = ({ children, scrollY, isInline = false }) => {
  const ref = useRef();
  const [elementTop, setElementTop] = useState(0);
  const [active, setActive] = useState(false);

  const onResize = () => {
    setElementTop(ref.current.offsetTop);
  };

  useEffect(() => {
    if (!ref.current) return;

    const topOffset = 100 - window.innerHeight;

    onResize();
    window.addEventListener('resize', onResize);
    window.removeEventListener('resize', onResize);

    setActive(scrollY.get() >= elementTop + topOffset);
    const unsubscribeScrollY = scrollY.onChange((y) => {
      setActive(y >= elementTop + topOffset);
      if (active) unsubscribeScrollY();
    });

    // eslint-disable-next-line consistent-return
    return () => unsubscribeScrollY();
  }, [active, elementTop, scrollY]);

  const variants = {
    visible: {
      opacity: 1,
      y: '0',
      display: isInline ? 'inline-block' : 'block',
    },
    hidden: {
      opacity: 0,
      y: '50px',
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      variants={variants}
      animate={active ? 'visible' : 'hidden'}
      transition={{
        duration: 0.4,
        ease: 'easeOut',
        delay: 0.1,
      }}
    >
      {children}
    </motion.div>
  );
};
