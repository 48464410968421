import system from 'system-components';

export const Avatar = system(
  {
    is: 'img',
    borderRadius: '100%',
    maxWidth: '100%',
  },
  'width',
  'height',
  'maxWidth',
  'maxHeight',
);
Avatar.displayName = 'Avatar';
