import React from 'react';
import { theme, Box, Text } from 'design-system';

export const SubHeading = ({ heading, caption }) => {
  return (
    <Box pt="12px" borderTop={`1px solid ${theme.colors.uiDivider}`}>
      <Text
        mb="4px"
        color="ui.1"
        fontSize="bodyS" // old {1}
        lineHeight={3}
      >
        {heading}
      </Text>
      <Text
        fontSize="bodyS" // old {1}
        lineHeight={4}
        color="ui.0"
      >
        {caption}
      </Text>
    </Box>
  );
};
