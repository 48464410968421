import React from 'react';
import { Collapse, Box, Link, withStyles } from 'design-system';

const Group = withStyles(
  (theme) => ({
    default: {
      position: 'relative',
      border: `1px solid ${theme.colors.btnTextHover[0]}`,
    },
  }),
  Box,
);

const StackedGroup = ({ children, ...props }) => {
  return (
    <Group {...props}>
      {React.Children.map(children, (child, idx) => {
        // first child is relative, to obtain height for transition.
        const position = idx > 0 ? 'absolute' : 'relative';
        return React.cloneElement(child, {
          css: `
              position: ${position};
              top: 0;
            `,
        });
      })}
    </Group>
  );
};

export const SkipLink = withStyles(
  () => ({
    default: {
      display: 'block',
      bg: 'bg.0',
      fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
      // old [2, 2, 2, 3],
      lineHeight: [4, 4, 4, 6],
      textAlign: 'center',
      p: ['18px', '27px'],
      width: '100%',
      color: '#fff',
      focus: {
        textDecoration: 'underline',
        outline: 'none',
        color: 'orange',
      },
      hover: {
        textDecoration: 'underline',
        color: '#fff',
      },
    },
  }),
  Link,
);

export class SkipLinksPanel extends React.Component {
  listRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocusIn = this.handleFocusIn.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('focusin', this.handleFocusIn);
      window.addEventListener('focusout', this.handleFocusOut);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('focusin', this.handleFocusIn);
      window.removeEventListener('focusout', this.handleFocusOut);
    }
  }

  /**
   * NOTE:  The following are not called with A11y-Focus Events on Android
   */
  handleFocus = (e) => {
    if (e.currentTarget) {
      e.currentTarget.style.zIndex = 10;
    }
  };

  handleBlur = (e) => {
    if (e.currentTarget) {
      e.currentTarget.style.zIndex = 5;
    }
  };

  handleFocusIn(e) {
    const { expanded } = this.state;
    const list = this.listRef.current;
    const element = e.target;
    if (list.contains(element) && !expanded) {
      this.setState({
        expanded: true,
      });
    }
  }

  handleFocusOut(e) {
    const { expanded } = this.state;
    const list = this.listRef.current;
    const element = e.relatedTarget;
    if (!list.contains(element) && expanded) {
      this.setState({
        expanded: false,
      });
    }
  }

  render() {
    const { children: childrenProp, ...props } = this.props;
    const { expanded } = this.state;

    const cLength = React.Children.count(childrenProp) - 1;
    const children = React.Children.map(childrenProp, (child, idx) => {
      return React.cloneElement(child, {
        // TODO: get a real key in here
        // eslint-disable-next-line react/no-array-index-key
        key: `skiplink_${idx}`,
        onFocus: this.handleFocus,
        onBlur: this.handleBlur,
        tabIndex: cLength - idx,
      });
    });

    return (
      <Collapse
        name="skip-links-panel"
        expanded={expanded}
        setVisibility={false}
        {...props}
      >
        <div ref={this.listRef}>
          <StackedGroup innerRef={this.listRef}>{children}</StackedGroup>
        </div>
      </Collapse>
    );
  }
}
