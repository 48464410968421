import React from 'react';
import { NavSocialLink } from 'components';
import { getSiteSettings } from 'utils';
import { theme, Box } from 'design-system';

export const NavSocialLinks = ({ variant }) => {
  const siteSettings = getSiteSettings();
  const { navigationSocialLinks } = siteSettings.props;
  return (
    <Box mx={theme.gutters[1] * -1}>
      {navigationSocialLinks.map(({ entryId, props }) => {
        return (
          <NavSocialLink
            key={entryId}
            variant={variant}
            platform={props.platform}
            href={props.url}
          />
        );
      })}
    </Box>
  );
};
