import React from 'react';
import { Box } from './Box';

export class Collapse extends React.Component {
  wrapperRef = null;

  constructor(props) {
    super(props);
    this.state = { wrapperHeight: 0 };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (this.wrapperRef) {
      if ('MutationObserver' in window) {
        const { wrapperHeight } = this.state;
        this.observer = new MutationObserver(() => {
          if (wrapperHeight !== this.wrapperRef.clientHeight) {
            this.handleResize();
          }
        });
      }
      this.setState({ wrapperHeight: this.wrapperRef.clientHeight });
      this.observer.observe(this.wrapperRef, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    const { expanded } = this.props;
    if (nextProps.expanded !== expanded) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.observer.disconnect();
  }

  handleResize() {
    if (this.wrapperRef) {
      this.setState({ wrapperHeight: this.wrapperRef.clientHeight });
    }
  }

  render() {
    const {
      expanded,
      children,
      setVisibility = true,
      collapsedHeight = 0,
      transitionDuration = 250,
      transitionType = 'cubic-bezier(0.4, 0, 0.2, 1)',
      ...other
    } = this.props;
    const { wrapperHeight: wrapperHeightState } = this.state;
    const wrapperHeight = wrapperHeightState || 0;
    const height = expanded ? wrapperHeight : collapsedHeight;
    const visibility = !setVisibility
      ? ''
      : `visibility: ${expanded ? 'visible' : 'collpased'};`;
    const css = `
            ${visibility}
            overflow: hidden;
            transition: height ${transitionDuration}ms ${transitionType};
        `;
    return (
      <Box
        minHeight={collapsedHeight}
        css={css}
        height={`${height}px`}
        {...other}
      >
        <div
          ref={(ref) => {
            this.wrapperRef = ref;
          }}
        >
          {children}
        </div>
      </Box>
    );
  }
}
Collapse.displayName = 'Collapse';
