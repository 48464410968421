import { createGlobalStyle } from 'styled-components';
import { globalFonts } from './fonts';

export const GlobalStyle = createGlobalStyle`
${globalFonts}

* {
  box-sizing: border-box;
  word-break: break-word;
}
`;
