import { style } from 'styled-system';

/**
 * for applying `list-style-type` property to styled-system components.
 */
export const listStyleType = style({
  prop: 'listStyleType',
  cssProperty: 'listStyleType',
  alias: 'marker',
});
