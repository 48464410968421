import React from 'react';

import '../../assets/css/hubspot-form.css';

let elementId = 0;

export class HubspotForm extends React.Component {
  constructor(props) {
    super(props);
    this.containerClass = `hubspot-container-${this.elementId}`;
    this.containerRef = null;
    // Hubspot User Account Id
    this.portalId = '8190977';
    this.hubspotFormScript = 'https://js.hsforms.net/forms/v2.js';
  }

  componentDidMount() {
    this.registerFormHandlers();
    if (!window.hbspt) {
      this.loadFormScript();
    } else {
      this.createForm();
    }
  }

  loadFormScript() {
    const script = document.createElement('script');
    script.defer = true;
    script.onload = () => {
      this.createForm();
    };
    script.src = this.hubspotFormScript;
    document.head.appendChild(script);
  }

  createForm() {
    const { formId } = this.props;
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: this.portalId,
        formId,
        target: `.${this.containerClass}`,
        groupErrors: false,
      });
      elementId++;
    } else {
      setTimeout(this.createForm, 1);
    }
  }

  registerFormHandlers() {
    window.addEventListener('message', (event) => {
      const isHubspotEvent = event.data.type === 'hsFormCallback';
      const isHubspotFormReady = event.data.eventName === 'onFormReady';

      if (isHubspotEvent && isHubspotFormReady) {
        const formContainer = document.querySelector(`.${this.containerClass}`);
        const formInputs = Array.from(
          formContainer.querySelectorAll('div.hs-fieldtype-text input'),
        );
        const formTextAreas = Array.from(
          formContainer.querySelectorAll('div.hs-fieldtype-textarea textarea'),
        );
        const formSelects = Array.from(
          formContainer.querySelectorAll('div.hs-fieldtype-select select'),
        );
        const inputContainer = formContainer.querySelector('div.input');
        const columnSpacing = window.getComputedStyle(inputContainer);
        const formSubmitContainer = formContainer.querySelector('div.hs-submit');

        // Align the submit button container with hubspot 1-column and 2-column layouts.
        formSubmitContainer.style.setProperty('margin-right', columnSpacing.marginRight);

        /*  Note: Hubspot may dynamically add classes to inputs during form validation which overwrite ours.
          To avoid our classes being overwritten, we set states through the data attribute for CSS selector visibility. */
        formInputs.forEach((formInput) => {
          formInput.addEventListener('change', (event) => {
            const element = event.target || event.srcElement;
            const label = formContainer.querySelector(
              `label[for=${element.id}]`,
            );
            if (element.value === '') {
              label.classList.remove('float-label');
              delete element.dataset.state;
            } else {
              label.classList.add('float-label');
              element.dataset.state = 'value';
            }
          });

          formInput.addEventListener('focus', (event) => {
            const element = event.target || event.srcElement;
            const label = formContainer.querySelector(
              `label[for=${element.id}]`,
            );
            label.classList.add('float-label');
            element.dataset.state = 'focus';
          });

          formInput.addEventListener('blur', (event) => {
            const element = event.target || event.srcElement;
            const label = formContainer.querySelector(
              `label[for=${element.id}]`,
            );
            if (element.value === '') {
              label.classList.remove('float-label');
              delete element.dataset.state;
            } else {
              element.dataset.state = 'value';
            }
          });

          // Update element CSS for pre-filled values
          formInput.dispatchEvent(new Event('change'));
        });

        formTextAreas.forEach((formTextArea) => {
          formTextArea.addEventListener('change', (event) => {
            const element = event.target || event.srcElement;
            if (element.value === '') {
              delete element.dataset.state;
            } else {
              element.dataset.state = 'value';
            }
          });

          formTextArea.addEventListener('focus', (event) => {
            const element = event.target || event.srcElement;
            element.dataset.state = 'focus';
          });

          formTextArea.addEventListener('blur', (event) => {
            const element = event.target || event.srcElement;
            if (element.value === '') {
              delete element.dataset.state;
            } else {
              element.dataset.state = 'value';
            }
          });

          formTextArea.dispatchEvent(new Event('change'));
        });

        formSelects.forEach((formSelect) => {
          formSelect.addEventListener('change', (event) => {
            const element = event.target || event.srcElement;
            if (element.value === '') {
              delete element.dataset.state;
            } else {
              element.dataset.state = 'value';
            }
          });

          formSelect.addEventListener('focus', (event) => {
            const element = event.target || event.srcElement;
            element.dataset.state = 'focus';
          });

          formSelect.addEventListener('blur', (event) => {
            const element = event.target || event.srcElement;
            if (element.value === '') {
              delete element.dataset.state;
            } else {
              element.dataset.state = 'value';
            }
          });

          formSelect.dispatchEvent(new Event('change'));
        });
      }
    });
  }

  render() {
    return (
      <div
        className={`hubspot-container ${this.containerClass}`}
        ref={(el) => {
          this.containerRef = el;
        }}
      />
    );
  }
}
