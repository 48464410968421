import React from 'react';
import { Row } from 'design-system';

export const CardGrid = ({ card, ...props }) => (
  <Row {...props}>
    {React.Children.map(card, (eachCard, index) =>
      React.cloneElement(eachCard, {
        length: card.length,
        // TODO: get a real key in here
        // eslint-disable-next-line react/no-array-index-key
        key: index,
      }),
    )}
  </Row>
);

export const ImageCardGrid = ({ imageCard, ...props }) => (
  <Row {...props}>
    {React.Children.map(imageCard, (eachCard, index) =>
      React.cloneElement(eachCard, {
        length: imageCard.length,
        // TODO: get a real key in here
        // eslint-disable-next-line react/no-array-index-key
        key: index,
      }),
    )}
  </Row>
);

export const ArticleCardGrid = ({ articleCard, ...props }) => (
  <Row {...props}>
    {React.Children.map(articleCard, (eachCard, index) =>
      React.cloneElement(eachCard, {
        length: articleCard.length,
        // TODO: get a real key in here
        // eslint-disable-next-line react/no-array-index-key
        key: index,
      }),
    )}
  </Row>
);
