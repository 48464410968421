import React from 'react';
import { Text } from 'design-system';

export const QuoteAuthor = ({ text, ...props }) => (
  <Text
    is="span"
    fontSize={['bodyS', 'bodyM', 'bodyM', 'subHeadingM']}
    // old [1, 2, 2, 4]
    color="text.3"
    m="0"
    data-qaid={text ? 'QuoteAuthor' : null}
    {...props}
  >
    {text}
  </Text>
);
