import { useState } from 'react';

export const useHover = ({
  mouseEnterDelayMS = 0,
  mouseLeaveDelayMS = 0,
} = {}) => {
  const [isHovering, setIsHovering] = useState(false);
  let mouseEnterTimer;
  let mouseOutTimer;

  const handleMouseEnter = () => {
    clearTimeout(mouseOutTimer);
    mouseEnterTimer = setTimeout(() => setIsHovering(true), mouseEnterDelayMS);
  };
  const handleMouseLeave = () => {
    clearTimeout(mouseEnterTimer);
    mouseOutTimer = setTimeout(() => setIsHovering(false), mouseLeaveDelayMS);
  };
  return [
    isHovering,
    {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onFocus: handleMouseEnter,
      onBlur: handleMouseLeave,
    },
  ];
};
