// to convert any case (ex. contentful case) to camel case (code in theme.js)
export const camelize = (str) => {
  if (str === undefined) {
    return '';
  }
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
