import React from 'react';
import ReactDOM from 'react-dom';

import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { theme, Icon, Box, Text, withStyles } from 'design-system';
import { keycode } from 'utils';

const Z_INDEX = 200;

function ownerDocument(node) {
  return (node && node.ownerDocument) || document;
}

class Select extends React.Component {
  state = {
    isOpen: false,
    // isMouseOverOptions: false,
    value: undefined,
    currentTabIndex: null,
  };

  comboRef = null;

  listboxRef = null;

  selectedItemRef = undefined;

  blurTimer = null;

  ignoreNextBlur = false;

  componentDidMount() {
    // this.resetTabIndex();
  }

  componentWillUnmount() {
    // clearTimeout(this.blurTimer);
  }

  toggleOpen = (
    isOpen = undefined,
    // ignoreBlur = false
  ) => {
    // this.ignoreNextBlur = ignoreBlur;
    const { isOpen: isOpenState } = this.state;
    this.setState(
      (prevState) => ({
        isOpen: typeof isOpen !== 'undefined' ? isOpen : !prevState.isOpen,
      }),
      () => {
        if (isOpenState && this.listboxRef) {
          this.handleListboxFocus();
        } else {
          this.comboRef.focus();
        }
      },
    );
  };

  handleChange = (value) => {
    const { onChange } = this.props;
    this.setState({
      value,
      // isMouseOverOptions: false
    });
    this.toggleOpen(false);
    onChange(value);
  };

  // handleMouseEnterOptions = () => this.setState({ isMouseOverOptions: true });

  // handleMouseLeaveOptions = () => this.setState({ isMouseOverOptions: false });

  /* handleBlur = (e) => {

    if( this.ignoreNextBlur === true ) {
      e.stopPropagation();
      this.ignoreNextBlur = false;
      return;
    }

    //this.props.onBlur(e);
  } */

  handleKeyDown = (e) => {
    if (['space', 'arrowup', 'arrowdown'].indexOf(keycode(e)) !== -1) {
      e.preventDefault();
      // this.ignoreNextBlur = true;
      this.toggleOpen(true, true);
    }
  };

  handleItemKeyDown = (e) => {
    const { options } = this.props;
    const list = this.listboxRef;
    const key = keycode(e);
    const currentFocus = ownerDocument(list).activeElement;

    if (key === 'tab') {
      e.preventDefault();
      return;
    }
    if (key === 'escape') {
      e.preventDefault();
      this.toggleOpen(false);
      return;
    }

    if (key === 'enter' && currentFocus) {
      const resultValue = options.filter(
        (opt) => opt.value === currentFocus.dataset.value,
      );
      if (resultValue && resultValue.length) {
        e.preventDefault();
        this.handleChange(resultValue[0]);
      }
      return;
    }

    if (
      ['arrowup', 'arrowdown'].indexOf(key) !== -1 &&
      (!currentFocus || (currentFocus && !list.contains(currentFocus)))
    ) {
      if (this.selectedItemRef) {
        this.selectedItemRef.focus();
      } else {
        list.firstChild.focus();
      }
    } else if (key === 'arrowdown') {
      e.preventDefault();
      if (currentFocus.nextElementSibling) {
        currentFocus.nextElementSibling.focus();
      }
    } else if (key === 'arrowup') {
      e.preventDefault();
      if (currentFocus.previousElementSibling) {
        currentFocus.previousElementSibling.focus();
      }
    }
  };

  setTabIndex = (idx) => {
    this.setState({ currentTabIndex: idx });
  };

  handleListboxFocus = () => {
    const { currentTabIndex } = this.state;
    const list = this.listboxRef;
    if (!list || !list.children || !list.firstChild) {
      return;
    }

    if (currentTabIndex && currentTabIndex >= 0) {
      list.children[currentTabIndex].focus();
    } else {
      list.firstChild.focus();
    }
  };
  /* handleListboxBlur = (e) => {
    this.blurTimer = setTimeout(()=>{
      if(this.listboxRef) {
        const list = this.listboxRef;
        const currentFocus = ownerDocument(list).activeElement;
        if( !list.contains(currentFocus)) {
          this.resetTabIndex();
        }
      }
    }, 30);
  } */

  /* resetTabIndex = () => {
    const list = this.listboxRef;
    const currentFocus = ownerDocument(list).activeElement;

    const items = Array.from(list.children);
    const currentFocusIndex = items.indexOf(currentFocus);

    if (currentFocusIndex !== -1) {
      return this.setTabIndex(currentFocusIndex);
    }

    if (this.selectedItemRef) {
      return this.setTabIndex(items.indexOf(this.selectedItemRef));
    }


  } */

  handleItemFocus = (e) => {
    const list = this.listboxRef;
    if (list) {
      const children = Array.from(list.children);
      const tabIndex = children.findIndex((c) => c === e.currentTarget);
      this.setTabIndex(tabIndex);
    }
  };

  render() {
    const {
      name,
      value,
      placeholder,
      defaultValue,
      options,
      isInvalid,
      // Extension.
      containerProps,
    } = this.props;
    const { value: stateValue, isOpen, currentTabIndex } = this.state;

    const selectedOption = find(options, (option) => isEqual(option, value)) ||
      find(options, (option) => isEqual(option, stateValue)) ||
      find(options, (option) => isEqual(option, defaultValue)) || {
        label: null,
      };

    const displayLabel = (selectedOption.label && (
      <SelectLabel>{selectedOption.label}</SelectLabel>
    )) || <SelectPlaceholder>{placeholder || '\u00A0'}</SelectPlaceholder>;

    // TODO: Refactor - whole component needs work
    // console.log('DropDown Value:', this.state.value, value, isInvalid);
    return (
      <Box position="relative" {...containerProps}>
        {name && (
          <input
            aria-hidden="true"
            type="hidden"
            name={name}
            // why would state ever have value.value? - refactor when possible
            value={stateValue ? stateValue.value : ''}
          />
        )}
        <Box
          display="flex"
          role="combobox"
          aria-expanded={isOpen}
          aria-autocomplete="none"
          aria-owns={`list_${name}`}
          outline="none"
          borderBottom={1}
          borderColor={isInvalid ? 'error.0' : 'ui.2'}
          pb={theme.verticalSpace.xxSmall}
          color={selectedOption.label ? 'text.0' : 'text.3'}
          css="cursor:pointer; user-select:none;"
          onClick={() => {
            this.toggleOpen(!isOpen, true);
          }}
          onKeyDown={this.handleKeyDown}
          // onBlur={this.handleBlur}
          innerRef={(ref) => {
            this.comboRef = ref;
          }}
          focus={{ outline: 'none', borderColor: 'ui.0' }}
          tabIndex={0}
        >
          {displayLabel}

          <Icon
            is="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={['bodyM', 'footnote']}
            // old [2, 0]
            height="32px"
            px="16px"
            color="text.3"
          >
            <span
              style={{
                display: 'inline-block',
                transition: 'all 320ms ease-out',
                'transform-origin': 'center center',
                transform: isOpen ? 'rotate(0.5turn)' : undefined,
                paddingTop: '5px',
                top: '-5px',
                position: 'relative',
              }}
            >
              &#x25BC;
            </span>
          </Icon>
        </Box>

        <React.Fragment>
          <Box
            is="ul"
            id={`list_${name}`}
            key="OPTIONS"
            display={isOpen ? 'block' : 'none'}
            position="absolute"
            top="100%"
            width="100%"
            maxHeight="50vh"
            background="white"
            border={1}
            borderTop={0}
            zIndex={Z_INDEX}
            role="listbox"
            p={0}
            m={0}
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              listStyleType: 'none',
            }}
            // onMouseEnter={this.handleMouseEnterOptions}
            // onMouseLeave={this.handleMouseLeaveOptions}
            innerRef={(ref) => {
              this.listboxRef = ref;
            }}
            // onBlur={this.handleListboxBlur}
            tabIndex={-1}
          >
            {options.map((option, idx) => (
              <Box
                is="li"
                key={option.value}
                width="100%"
                colors="selectOption"
                role="option"
                p={theme.gutters}
                css="cursor:pointer; user-select:none;"
                data-value={option.value}
                // data-focused={idx === this.state.currentTabIndex}
                data-selected={option.label === selectedOption.label}
                data-on="click"
                data-event-category="dropdown"
                data-event-action="select"
                data-event-label={option.value}
                onClick={() => {
                  this.handleChange(option);
                }}
                innerRef={
                  option.label === selectedOption.label
                    ? (ref) => {
                        // eslint-disable-next-line react/no-find-dom-node
                        this.selectedItemRef = ReactDOM.findDOMNode(ref);
                      }
                    : undefined
                }
                onKeyDown={this.handleItemKeyDown}
                onFocus={this.handleItemFocus}
                tabIndex={idx === currentTabIndex ? 0 : -1}
              >
                <OptionLabel title={option.label}>{option.label}</OptionLabel>
              </Box>
            ))}
          </Box>

          {isOpen && (
            <Box
              key="OVERLAY"
              position="fixed"
              top="0"
              left="0"
              bottom="0"
              right="0"
              bg="transparent"
              zIndex={Z_INDEX - 1}
              onClick={() => {
                this.toggleOpen(false);
              }}
            />
          )}
        </React.Fragment>

        {/*
          Removed - not sure why this was duplicated from above, but was causing issues
          in readability upon form submission.
          This is left here for posterity - it should be removed in the near future
        */}
        {/* name && <input type="hidden" name={name} value={this.state.value} /> */}
      </Box>
    );
  }
}

const Label = withStyles((themeArg, props, themeType = 'light') => {
  return {
    default: {
      ...themeArg.typography[themeType].selectOption,
      width: '100%',
      color: 'inherit',
      outline: 'none',
    },
    placeholder: {
      ...themeArg.typography[themeType].placeholder,
      width: '100%',
      color: 'inherit',
      outline: 'none',
    },
  };
}, Text);

const OptionLabel = (props) => <Label ellipsis is="span" {...props} />;
OptionLabel.displayName = 'OptionLabel';

const SelectLabel = (props) => <Label ellipsis {...props} />;
SelectLabel.displayName = 'SelectLabel';

const SelectPlaceholder = (props) => (
  <SelectLabel variant="placeholder" {...props} />
);
SelectPlaceholder.displayName = 'SelectPlaceholder';

export { Select, OptionLabel, SelectLabel, SelectPlaceholder };
