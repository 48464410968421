import React from 'react';
import { List, BulletListItem } from 'design-system';

export const PerksListElement = ({ items, ...props }) => (
  <List data-qaid={items ? 'PerksListItem' : null} {...props}>
    {React.Children.map(items, (item) => (
      <BulletListItem>{item}</BulletListItem>
    ))}
  </List>
);
