import React from 'react';

export class PardotFormTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeHeight: '100%',
      queryParams: '',
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
    window.addEventListener('resize', this.sendMessage, false);
    this.setState({ queryParams: location.search });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
    window.removeEventListener('resize', this.sendMessage, false);
  }

  sendMessage = () => {
    const iframeElement = document.getElementById('pardotFormTemplate');
    if (iframeElement) {
      iframeElement.contentWindow.postMessage(
        { width: window.innerWidth },
        '*',
      );
    }
  };

  receiveMessage = (e) => {
    const iframeElement = document.getElementById('pardotFormTemplate');
    if (e.data.height) {
      iframeElement.height = `${e.data.height} px`;
    }
  };

  render() {
    const { iframeHeight, queryParams } = this.state;
    const { enterPardotUrl } = this.props;

    return (
      <iframe
        id="pardotFormTemplate"
        title="foo"
        src={`${enterPardotUrl}${queryParams}`}
        width="100%"
        height={iframeHeight}
        type="text/html"
        frameBorder="0"
        allowTransparency="true"
        allowFullScreen="true"
        scrolling="no"
        onLoad={this.sendMessage}
      />
    );
  }
}
