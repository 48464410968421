import React from 'react';
import { Row, theme, Column, IconButton, Box, Icon } from 'design-system';

export class NewsCardGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homePageCards: props.isHomePage ? props.newsCard.length : 0,
      windowWidth: undefined,
      cardsPerPage:
        props.newsCard.length > props.initialShowingCards
          ? props.initialShowingCards
          : props.newsCard.length,
      page: 1,
    };
  }

  componentDidMount() {
    const { windowWidth } = this.state;
    if (windowWidth === undefined) {
      this.setState({ windowWidth: window.innerWidth });
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(_, prevState) {
    this.changeCards(prevState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleOnClick = () => {
    this.setState((state) => ({ page: state.page + 1 }));
  };

  getCardsToShowPerPage = (totalCards, columns = 2) =>
    totalCards - (totalCards % columns);

  // eslint-disable-next-line react/destructuring-assignment
  isThreeColumns = () => this.state.windowWidth >= theme.rawBreakpoints[4];

  changeCards = (prevState) => {
    const { isHomePage, newsCard } = this.props;
    const { cardsPerPage, windowWidth } = this.state;
    let newCardsPerPage;
    if (prevState.windowWidth === windowWidth) {
      return;
    }
    // initial window size
    if (prevState.windowWidth === undefined && windowWidth !== undefined) {
      // initial window size larger than 1920
      if (this.isThreeColumns()) {
        if (isHomePage) {
          this.setState({ homePageCards: newsCard.length - 1 });
        } else {
          newCardsPerPage = this.getCardsToShowPerPage(cardsPerPage, 3);
          this.setState({ cardsPerPage: newCardsPerPage });
        }
      } else {
        newCardsPerPage = this.getCardsToShowPerPage(cardsPerPage, 2);
        this.setState({ cardsPerPage: newCardsPerPage });
      }
      return;
    }

    // two columns to three columns
    if (
      isHomePage &&
      prevState.windowWidth !== undefined &&
      prevState.windowWidth < theme.rawBreakpoints[4] &&
      windowWidth >= theme.rawBreakpoints[4]
    ) {
      this.setState({ homePageCards: newsCard.length - 1 });
      return;
    }

    // three to two or one
    if (
      isHomePage &&
      prevState.windowWidth !== undefined &&
      prevState.windowWidth >= theme.rawBreakpoints[4] &&
      windowWidth < theme.rawBreakpoints[4]
    ) {
      this.setState({ homePageCards: newsCard.length });
    }
  };

  getTotalCardsToShow = () => {
    const { additionalCards, newsCard } = this.props;
    const { page, cardsPerPage } = this.state;
    let total;
    if (additionalCards !== cardsPerPage) {
      total =
        page === 1 ? cardsPerPage : cardsPerPage + additionalCards * (page - 1);
    } else {
      total = cardsPerPage * page;
    }
    if (total > newsCard.length) {
      return newsCard.length;
    }
    return total;
  };

  render() {
    const {
      newsCard,
      initialShowingCards,
      additionalCards,
      buttonText,
      isHomePage,
      ...props
    } = this.props;
    const { homePageCards } = this.state;
    const totalShownCards = this.getTotalCardsToShow();
    const newGutters = [0, theme.gutters[1]];
    const newsCards = newsCard.slice(
      0,
      isHomePage ? homePageCards : totalShownCards,
    );
    const shownCards = newsCards.map((card, index) => {
      return (
        <Column
          width={[1, 1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 3]}
          // TODO: get a real key in here
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          pl={index === 0 ? 0 : newGutters}
          pr={index === newsCard.length - 1 ? 0 : newGutters}
        >
          {card}
        </Column>
      );
    });
    return (
      <Row {...props}>
        {shownCards}
        {totalShownCards !== newsCard.length && additionalCards !== 0 && (
          <Box display="flex" flexBasis="100%" justifyContent="center">
            <Column
              px={theme.gutters}
              display="flex"
              width={[1, 1 / 3, 1 / 3, 1 / 3, 1 / 3, 1 / 3]}
            >
              <IconButton
                variant="contained"
                themeType="dark"
                iconAfter={<Icon ml={2}>↓</Icon>}
                onClick={this.handleOnClick}
              >
                {buttonText}
              </IconButton>
            </Column>
          </Box>
        )}
      </Row>
    );
  }
}
