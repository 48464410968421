import React, { Component } from 'react';
import { theme, Box, Image } from 'design-system';
import {
  getImageURL,
  // getImageWidth,
  getImageContentType,
} from 'utils';

function getMaxImageWidth(
  breakpoint,
  imageContextWidth,
  imageContextGutterCount,
) {
  // 80% of screenwidth minus the two gutters of 32px on either size
  const fullWidthCalc =
    Math.round(breakpoint * 0.8) - theme.doubleGutters[1] * 2;

  // If the image is a 50% image, or 33% or 75%, then we need to take that into account
  // as well as subtract additional gutter widths
  // Default imageContextGutterCount is 0 and default imageContextWidth is 1, so that this formula works by default for the 100% width images
  const widthCalc = Math.round(
    (fullWidthCalc - theme.doubleGutters[1] * imageContextGutterCount) *
      imageContextWidth,
  );

  return widthCalc;
}

export class ResponsiveImgSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: undefined,
      windowPixelDensity: undefined,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
      windowPixelDensity: window.devicePixelRatio,
    });

  render() {
    const {
      imageDesktop,
      imageMobile,
      imageMobile2x,
      altText,
      imageContextWidth = 1, // Default is full content width images
      imageContextGutterCount = 0, // Default is no extra gutters
      aspectRatio,
      wrapperStyle = {},
      imageStyle = {},
    } = this.props;
    const { width, windowWidth, windowPixelDensity } = this.state;

    // const contentMaxWidth = 0.8;
    let imgDesktop = getImageURL(imageDesktop);
    // const imgDesktopWidth = getImageWidth(imageDesktop);
    // const imgDesktopHeight = getImageHeight(imageDesktop);
    const imgType = getImageContentType(imageDesktop);

    let imgMobile = getImageURL(imageMobile);
    const imgMobile2x = getImageURL(imageMobile2x);

    // By default we'll skip using srcset
    let srcSetMobile = null;

    if (imgDesktop && !imgMobile && !imgMobile2x) {
      // Only the regular desktop image is present, so we'll still skip using srcset, but we'll use the desktop image for mobile
      imgMobile = imgDesktop;
    } else if (imgDesktop && imgMobile && imgMobile2x) {
      // All images are present, so we can use srcset
      srcSetMobile = `${imgMobile} 1x, ${imgMobile2x} 2x`;
    }

    let ImageSet;
    let imageWidth;

    const ImageSetMobile = (
      <Image
        srcSet={srcSetMobile}
        src={imgMobile}
        alt={altText}
        data-width={width}
        data-qaid={altText && imgMobile ? 'ResponsiveImgSet' : null}
        position={aspectRatio ? 'absolute' : 'static'}
        width={1}
        top={aspectRatio ? 0 : null}
        left={aspectRatio ? 0 : null}
      />
    );
    let fullWidthCalc;
    if (imgType !== 'image/svg+xml') {
      if (windowWidth === theme.rawBreakpoints[0]) {
        // 768 context
        // Tablet is special because it has desktop gutters, but the mobile menu,
        // so the content takes up 100% of the main space
        fullWidthCalc = Math.round(
          theme.rawBreakpoints[0] - theme.doubleGutters[1] * 2,
        );
        imageWidth = Math.round(
          (fullWidthCalc - theme.doubleGutters[1] * imageContextGutterCount) *
            imageContextWidth,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (
        windowWidth > theme.rawBreakpoints[0] &&
        windowWidth < theme.rawBreakpoints[1]
      ) {
        // Between 769 to 1023 context
        // Tablet is special because it has desktop gutters, but the mobile menu,
        // so the content takes up 100% of the main space
        fullWidthCalc = Math.round(
          theme.rawBreakpoints[1] - theme.doubleGutters[1] * 2,
        );
        imageWidth = Math.round(
          (fullWidthCalc - theme.doubleGutters[1] * imageContextGutterCount) *
            imageContextWidth,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (windowWidth === theme.rawBreakpoints[1]) {
        // 1024 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[1],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (
        windowWidth > theme.rawBreakpoints[1] &&
        windowWidth < theme.rawBreakpoints[2]
      ) {
        // Between 1025 & 1364 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[2],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (windowWidth === theme.rawBreakpoints[2]) {
        // 1366 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[2],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (
        windowWidth > theme.rawBreakpoints[2] &&
        windowWidth < theme.rawBreakpoints[3]
      ) {
        // Between 1367 & 1439 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[3],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (windowWidth === theme.rawBreakpoints[3]) {
        // 1440 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[3],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (
        windowWidth > theme.rawBreakpoints[3] &&
        windowWidth < theme.rawBreakpoints[4]
      ) {
        // Between 1441 & 1919 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[4],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (windowWidth === theme.rawBreakpoints[4]) {
        // 1920 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[4],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (
        windowWidth > theme.rawBreakpoints[4] &&
        windowWidth < theme.rawBreakpoints[5]
      ) {
        // Between 1921 & 2559 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[5],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (windowWidth === theme.rawBreakpoints[5]) {
        // 2560 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[5],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (
        windowWidth > theme.rawBreakpoints[5] &&
        windowWidth < theme.rawBreakpoints[6]
      ) {
        // Between 2561 & 3839 context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[6],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      } else if (windowWidth >= theme.rawBreakpoints[6]) {
        // 3840 and above context
        imageWidth = getMaxImageWidth(
          theme.rawBreakpoints[6],
          imageContextWidth,
          imageContextGutterCount,
        );
        imageWidth = Math.round(imageWidth * windowPixelDensity);
        imgDesktop = `${imgDesktop}?w=${Math.min(4000, imageWidth)}`;
      }
    }

    // var imageHeight = getNewImageHeight(imageDesktop, imageWidth);

    let paddingBottom;
    if (windowWidth < theme.rawBreakpoints[0]) {
      ImageSet = ImageSetMobile;
      paddingBottom = aspectRatio ? aspectRatio[0] * 100 : null;
    } else if (windowWidth >= theme.rawBreakpoints[0]) {
      ImageSet = (
        <Image
          src={imgDesktop}
          alt={altText}
          position={aspectRatio ? 'absolute' : 'static'}
          width={1}
          top={aspectRatio ? 0 : null}
          left={aspectRatio ? 0 : null}
          data-width={width}
          data-qaid={altText ? 'ResponsiveImgSet' : null}
        />
      );
      paddingBottom = aspectRatio ? aspectRatio[1] * 100 : null;
    }
    const ImageSetToRender = ImageSet
      ? React.cloneElement(ImageSet, { style: imageStyle })
      : ImageSet;

    return (
      <Box
        width={1}
        alignSelf="center"
        justifyContent="center"
        position={aspectRatio ? 'relative' : 'static'}
        pb={aspectRatio ? `${paddingBottom}%` : 0}
        background={aspectRatio ? theme.colors.ui[3] : null}
        style={wrapperStyle}
      >
        {ImageSetToRender}
      </Box>
    );
  }
}

// These are all variants so that we can be more specific on the Contentful side
// on what the dimensions of the images should be for each respective context
//  as well as adding details to the fields such as image aspect ratio required
// for that context.

// 16:9 ratio, desktop width: 1472px
export const SectionImgSingleRespImgSet = (props) => (
  <ResponsiveImgSet {...props} aspectRatio={[1 / 1, 9 / 16]} />
);

// 1:1 both should have the same ratio, desktop width: 720px
export const SectionImgDoubleRespImgSet = (props) => (
  <ResponsiveImgSet {...props} aspectRatio={[1 / 1, 1 / 1]} />
);

// desktop width: 720px
export class SectionHeaderRespImgSet extends ResponsiveImgSet {}

// desktop width: 1472px
export class EditorialImgSingleRespImgSet extends ResponsiveImgSet {}

// If the emphasis is on the left, then the left image's minimum width is 720px and the right image would need to be 540px.
// Vice versa for right emphasis. If they are equal width then they both need to be at least 720px wide.
export const EditorialImgDoubleRespImgSet = (props) => (
  <ResponsiveImgSet
    {...props}
    aspectRatio={
      // eslint-disable-next-line react/destructuring-assignment
      props.imageContextWidth === 0.5 ? [7 / 5, 7 / 5] : [1 / 1, 1 / 1]
    }
  />
);

// desktop width: 720px
export class EditorialContentRespImgSet extends ResponsiveImgSet {}

// 4:5 ratio, desktop width: 720px for 2 across, 470px for 3 across, 304px for 4 across
export class ImageCardRespImgSet extends ResponsiveImgSet {}

// desktop width: 470px for 3 across
export class ArticleCardRespImgSet extends ResponsiveImgSet {}

export class BlogCardResponsiveImgSet extends ResponsiveImgSet {}
