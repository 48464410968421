import React from 'react';
import Helmet from 'react-helmet';
import _map from 'lodash/map';

import { tags as tagsHelper } from '@tryghost/helpers';

export const BlogPostMeta = ({ data }) => {
  const author = data.primary_author;
  const publicTags = _map(
    tagsHelper(data, { visibility: 'public', fn: (tag) => tag }),
    'name',
  );
  const primaryTag = publicTags[0] || '';

  return (
    <Helmet>
      <meta property="article:published_time" content={data.published_at} />
      <meta property="article:modified_time" content={data.updated_at} />
      {publicTags.map((keyword) => (
        <meta
          property="article:tag"
          content={keyword}
          key={`blog-tag-meta-${keyword}`}
        />
      ))}

      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content={author.name} />
      {primaryTag && <meta name="twitter:label2" content="Filed under" />}
      {primaryTag && <meta name="twitter:data2" content={primaryTag} />}
    </Helmet>
  );
};
