import { pseudoStyleWithArg } from './pseudoStyleWithArg';

export const nthChild = pseudoStyleWithArg({
  prop: 'nthChild',
  pseudoClass: 'nth-child',
});

export const nthLastChild = pseudoStyleWithArg({
  prop: 'nthLastChild',
  pseudoClass: 'nth-last-child',
});

export const lastChild = pseudoStyleWithArg({
  prop: 'lastChild',
  pseudoClass: 'nth-last-child',
  arg: '1',
});

export const firstChild = pseudoStyleWithArg({
  prop: 'firstChild',
  pseudoClass: 'nth-child',
  arg: '1',
});
