import React from 'react';
import { Box, Input } from 'design-system';
import { Field } from 'components';

export class PardotFormField extends React.Component {
  state = {
    hasFocus: false,
  };

  componentDidMount() {
    const { value } = this.props;
    this.updateValidationError(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateValidationError(value);
    }
  }

  handleChange = (event) => {
    const {
      // inputComponent,
      // label,
      name,
      onChange,
      // required,
      // requiredMessage,
    } = this.props;

    const { value: eventValue } = event.target || event;
    const value =
      event?.target?.type === 'checkbox' ? event.target.checked : eventValue;

    onChange(name, value);
    this.updateValidationError(value);
  };

  updateValidationError = (value) => {
    const {
      label,
      regex,
      invalidMessage,
      required,
      requiredMessage,
      setValidationError,
    } = this.props;
    const { hasFocus } = this.state;
    let error;
    if (required && !value) {
      error = requiredMessage || `${label} is required`;
    }
    if (regex && value && !value.match(regex) && !hasFocus) {
      error = invalidMessage || `Please enter a valid value`; // TODO - consider using site-wide config
    }
    setValidationError(error);
  };

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };

  handleBlur = (event) => {
    const { onBlur, value } = this.props;
    this.setState({ hasFocus: false }, () => this.updateValidationError(value));
    onBlur(event);
  };

  render() {
    const {
      entryId,
      // Contentful fields
      name,
      type,
      label,
      required,
      // requiredMessage,
      // React/PardotForm props
      readOnly,
      inputComponent,
      // onChange,
      value,
      validationError,
      shouldDisplayErrors,
      inputProps,
      isLabelFloating = true,
      isLabelVisible = true,
      labelCss = '',
      inputPosition = 'after',
      inputStyles = {},
      fieldWrapperProps = {},
    } = this.props;

    const inputId = `input-${entryId}`;
    const labelId = `label-${entryId}`;
    const errorId = `error-${entryId}`;

    const InputComponent = inputComponent || Input;

    const fieldProps = {
      inputId,
      labelId,
      errorId,
      label: `${label}${!required ? ' (optional)' : ''}`,
      error: shouldDisplayErrors ? validationError : undefined,
      isLabelFloating,
      labelCss,
      isLabelVisible,
      inputPosition,
      fieldWrapperProps,
    };

    const baseInputProps = {
      id: inputId,
      name,
      type,
      value,
      readOnly,
      'aria-labelledby': labelId,
      'aria-errormessage': errorId,
      'aria-required': required,
      'aria-invalid': shouldDisplayErrors && validationError,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      style: {
        width: '100%',
        resize: 'none',
        ...inputStyles,
      },
    };

    return (
      <Box>
        <Field {...fieldProps}>
          <InputComponent {...baseInputProps} {...inputProps} />
        </Field>
      </Box>
    );
  }
}
