import React, { useRef, useEffect, useState } from 'react';
import { Box, columnWidth, verticalSpace } from 'design-system';
import { Image, Spacing } from 'components';
import { isMobile } from 'react-device-detect';

import { camelize } from '../patterns';
import { applyTheme } from '../../utils';

export const FullBleed = ({
  backgroundColour,
  blocks = [],
  theme,
  mediaFileName,
  isReducedMotion,
  scrollY,
  ...props
}) => {
  let playCount = 1;
  const playTime = 3;
  const player = useRef();

  const [hoverImageURL, setHoverImageURL] = useState();
  const [isVideoFinished, setIsVideoFinished] = useState(false);

  const pauseVideo = () => {
    if (player && player.current) {
      player.current.pause();
    }
  };

  const playVideo = () => {
    if (player && player.current && !isVideoFinished && !isReducedMotion) {
      player.current.play();
    }
  };

  const handleOnHoverLeave = () => {
    setHoverImageURL();
    playVideo();
  };

  const handleOnScroll = () => {
    if (hoverImageURL) {
      handleOnHoverLeave();
    }
  };

  const handleOnHover = (hoverImageURL) => {
    setHoverImageURL(hoverImageURL);
    pauseVideo();
  };

  const onVideoEnd = () => {
    if (playCount === playTime) {
      player.current.load();
      pauseVideo();
      setIsVideoFinished(true);
    } else {
      playCount += 1;
      playVideo();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);
    return () => window.removeEventListener('scroll', handleOnScroll);
  }, [isVideoFinished]);

  const blocksWithProps = blocks.map((block) =>
    React.cloneElement(block, {
      handleOnHover,
      handleOnHoverLeave,
      isReducedMotion,
      scrollY,
    }),
  );

  // Use dark theme if video is used
  const blocksWithTheme = applyTheme(
    blocksWithProps,
    theme,
    mediaFileName ? 'black' : backgroundColour,
  );

  const fileName = mediaFileName && mediaFileName.split('.')[0];

  const isMP4 = /.*\.(mp4)$/g.test(mediaFileName);

  const videoStyle = {
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    top: 0,
  };

  // If it has a background colour, the nav should be black and the content should span 8 rows
  return (
    <Box
      ml={[0, 0, `-${columnWidth(2)}`]}
      display="flex"
      flexDirection="row"
      position="relative"
      height={['auto', 'auto', 'auto']}
      minHeight={['100vh', '100vh', '70vw']}
      bg={camelize(backgroundColour)}
      className="fullBleed"
      overflow="hidden"
      {...props}
    >
      {/* Content */}
      <Box pl={[0, 0, columnWidth(2)]} width="100%" height="100%" zIndex={3}>
        {blocksWithTheme}
      </Box>
      {mediaFileName ? (
        // Background Video
        <>
          <Box
            position="absolute"
            width="100%"
            height="100%"
            bg="black"
            opacity={hoverImageURL ? 1 : 0.8}
            zIndex={2}
            left={0}
            right={0}
            top={0}
            style={{ transition: 'opacity 400ms' }}
          />
          {/* Hover Image */}
          {hoverImageURL && (
            <Box
              position="absolute"
              left={[0, 0, columnWidth(2)]}
              right="0"
              bottom="0"
              display="flex"
              align-items="flex-end"
              pb="10%"
              zIndex={2}
            >
              <Image
                width="500px"
                url={hoverImageURL}
                opacity="0.3"
                isLoop={false}
                isReducedMotion={isReducedMotion}
              />
            </Box>
          )}
          {/* Defaults SSR page to static image */}
          {isMobile ||
          typeof window === 'undefined' ||
          isReducedMotion ||
          !isMP4 ? (
            <Image
              style={videoStyle}
              imageStyles={{ objectFit: 'cover', height: '100%' }}
              isAnimated={false}
              isReducedMotion
              overideUrl={`https://res.cloudinary.com/rangle/${
                isMP4 ? 'video' : 'image'
              }/upload/${
                isMP4 ? 'so_0' : 'q_auto,f_auto'
              }/rangle.io/${fileName}.jpg`}
            />
          ) : (
            <video
              ref={player}
              poster={`https://res.cloudinary.com/rangle/video/upload/so_0/rangle.io/${fileName}.jpg`}
              style={videoStyle}
              preload="auto"
              // eslint-disable-next-line react/no-unknown-property
              autoplay="true"
              muted="true"
              playsinline
              onEnded={onVideoEnd}
            >
              <source
                src={`https://res.cloudinary.com/rangle/video/upload/rangle.io/${fileName}.webm`}
                type="video/webm"
              />
              <source
                src={`https://res.cloudinary.com/rangle/video/upload/rangle.io/${fileName}.mp4`}
                type="video/mp4"
              />
              <source
                src={`https://res.cloudinary.com/rangle/video/upload/rangle.io/${fileName}.ogv`}
                type="video/ogg"
              />
              Your browser doesn't support HTML5 video tag.
            </video>
          )}
        </>
      ) : (
        // Overlay and Image
        <>
          <Box
            position="absolute"
            width="100%"
            height="100%"
            bg={hoverImageURL ? 'black' : ''}
            opacity={hoverImageURL ? 1 : 0.8}
            zIndex={2}
            style={{ transition: 'opacity 400ms' }}
          />
          {hoverImageURL && (
            <Box
              position="absolute"
              left={[0, 0, columnWidth(2)]}
              right="0"
              bottom="0"
              display="flex"
              align-items="flex-end"
              pb="10%"
              zIndex={2}
            >
              <Image
                width="500px"
                url={hoverImageURL}
                opacity="0.3"
                isLoop={false}
                isReducedMotion={isReducedMotion}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
