import React from 'react';
import { theme, Link } from 'design-system';
import { RangleSvgMobile, RangleSvgDesktopLite } from 'components';

export const RangleLogoMobile = ({ variant, ...props }) => {
  const logoFill =
    variant && variant === 'light' ? '#FFFFFF' : theme.colors.brand[1];

  return (
    <Link
      href="/"
      title="Homepage"
      aria-label="Homepage"
      isInternal
      height="20px"
      focus={{
        outline: `1px solid ${theme.colors.btnTextHover[0]}`,
      }}
      {...props}
    >
      <RangleSvgMobile variant={logoFill} />
    </Link>
  );
};

export const RangleLogoDesktop = ({
  variant,
  linkUrl,
  linkTitle,
  ...props
}) => (
  <Link
    href="/"
    title="Homepage"
    aria-label="Homepage"
    height={['28px', '28px', '28px', '32px']}
    isInternal
    focus={{
      outline: `1px solid ${theme.colors.btnTextHover[0]}`,
    }}
    {...props}
  >
    <RangleSvgDesktopLite />
  </Link>
);
