import React from 'react';
import { Box, Caption, Column, Link, Text, theme } from 'design-system';
import { themeGet } from 'styled-system';
import { useHover } from 'utils';

export const ArticleCard = (props) => {
  const {
    title,
    // author,
    publishedDate,
    linkUrl,
    linkTitle,
    length,
    image,
    cta,
    dataOn,
    dataEventCategory,
    dataEventAction,
    dataEventLabel,
    imageWrapperProps = {},
    chip,
    titlePaddingBottom = theme.verticalSpace.small,
    forceColumnWidthToThirds = false,
  } = props;
  const [hoverState, hoverProps] = useHover();

  const easeShort = themeGet('transitions.easeOut.short', '0.3s ease-out')(
    props,
  );
  let column = length % 3 !== 0 ? 1 / 2 : 1 / 3;
  if (forceColumnWidthToThirds) {
    column = 1 / 3;
  }

  let imageContextWidth;
  let imageContextGutterCount;

  if (forceColumnWidthToThirds || length % 3 === 0) {
    imageContextWidth = 0.33;
    imageContextGutterCount = 2;
  } else {
    imageContextWidth = 0.5;
    imageContextGutterCount = 1;
  }
  let imageWithContext = null;

  if (image !== null && image !== undefined) {
    imageWithContext = React.cloneElement(image, {
      imageContextWidth,
      imageContextGutterCount,
    });
  }

  const ImageContainer = (
    <Box position="relative" {...imageWrapperProps}>
      {imageWithContext}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        background={hoverState ? theme.colors.brand[0] : null}
        display="flex"
        justifyContent="center"
        alignItems="center"
        css={`
          opacity: 0.35;
          transition: background-color ${easeShort};
        `}
      />
      {chip && (
        <Box
          position="absolute"
          top={theme.verticalSpace.small}
          right={theme.gutters}
        >
          {chip}
        </Box>
      )}
    </Box>
  );

  let paddingBottom;

  if (length > 2) {
    paddingBottom = [theme.verticalSpace.medium, theme.verticalSpace.medium];
  } else {
    paddingBottom = [theme.verticalSpace.medium, '0'];
  }

  return (
    <Column
      data-qaid={title ? 'ArticleCard' : null}
      width={[1, column]}
      pb={paddingBottom}
    >
      <Link
        {...hoverProps}
        href={linkUrl || null}
        hover={{
          textDecoration: 'none',
        }}
        title={linkTitle}
        focus={{
          outline: 'none',
        }}
        data-on={dataOn}
        data-event-category={dataEventCategory}
        data-event-action={dataEventAction}
        data-event-label={dataEventLabel}
      >
        {ImageContainer}
        <Box pt={theme.verticalSpace.small} pb={titlePaddingBottom}>
          <Text
            is="p"
            fontFamily="regular"
            fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyM', 'bodyM', 'bodyL']}
            // old {[2, 2, 2, 2, 2, 3]}
            lineHeight={[4, 4, 4, 4, 4, 6]}
            color={hoverState ? theme.colors.btnTextHover[0] : 'text.0'}
            data-qaid={cta ? 'textCTA' : null}
            css={`
              ${hoverState && 'text-decoration: underline;'}
              transition: color ${easeShort};
            `}
            display="inline"
          >
            {cta}
          </Text>
          <span
            style={{
              color: hoverState
                ? theme.colors.btnTextHover[0]
                : theme.colors.text[0],
            }}
          >
            {' '}
            →
          </span>
        </Box>
        {/* <Caption is="p" mt="0" mb="0" display="inline" mr="5px"> */}
        {/* {author} */}
        {/* </Caption> */}
        <Caption is="p" mt="0" mb="0" display="inline" mr="5px">
          {publishedDate}
        </Caption>
      </Link>
    </Column>
  );
};
