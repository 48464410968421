import React from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';

const getTransitionStyles = {
  // Slide from Up to Down
  entering: {
    opacity: '0',
    transform: 'translate(0px, -8px)',
  },
  entered: {
    opacity: '1',
    transform: 'translate(0px, 0px)',
  },
  exiting: {
    opacity: '0',
  },
};

export class PageTransition extends React.PureComponent {
  render() {
    const { children, transition, timing, location } = this.props;
    const defaultStyles = {
      transition: `opacity ${transition}, transform ${transition}`,
      opacity: '0',
    };
    return (
      <TransitionGroup>
        <Transition key={location.pathname} timeout={timing * 1000}>
          {(status) => (
            <div
              style={{
                ...defaultStyles,
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </Transition>
      </TransitionGroup>
    );
  }
}
