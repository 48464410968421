/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { theme, Box, Input, fontSize } from 'design-system';
import { Field } from 'components';

// Algolia
import { connectSearchBox } from 'react-instantsearch-dom';

// Custom SearchBox using pre-existing reusable components from the design system
const SearchBox = ({
  currentRefinement,
  refine,
  placeholderText,
  isSearchOpen,
  isMobileOpen,
}) => {
  const fieldProps = {
    label: placeholderText,
    labelId: 'global-search-label',
    labelProps: {
      lineHeight: ['25px', '30px', '51px'],
    },
    fieldWrapperProps: {
      width: '100%',
    },
  };

  // Clear search query on close
  useEffect(() => {
    if (!isMobileOpen && !isSearchOpen) refine('');
  }, [isSearchOpen]);

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-end">
      <Field {...fieldProps}>
        <Input
          id="global-search"
          name="global-search"
          type="search"
          color="text.1"
          css={`
            & {
              border-bottom: 2px solid ${theme.colors.darkGrey};
            }
            &:focus {
              border-bottom: 2px solid ${theme.colors.darkGrey};
            }

            /* Remove input reset button */
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button {
              -webkit-appearance: none;
            }

            &::-ms-clear {
              display: none;
            }
          `}
          lineHeight={['25px', '30px', '51px']}
          fontSize={fontSize.medium}
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
        />
      </Field>
    </Box>
  );
};

export const CustomSearchBox = connectSearchBox(SearchBox);
