import React from 'react';
import Helmet from 'react-helmet';

import { SITE_CONFIG } from 'utils';

export const ImageMeta = ({ image }) => {
  if (!image) {
    return null;
  }

  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={SITE_CONFIG.shareImageWidth} />
      <meta property="og:image:height" content={SITE_CONFIG.shareImageHeight} />
    </Helmet>
  );
};
