import React from 'react';
import system from 'system-components';

export const Caption = system(
  {
    is: 'div',
    fontSize: ['bodyS', 'bodyS', 'bodyS', 'bodyM'],
    // old [1, 1, 1, 2]
    lineHeight: [3, 3, 3, 4],
    color: 'text.3',
    fontFamily: 'light',
  },
  // core
  'position',
  'top',
  'space',
  'width',
  'color',
  'fontSize',
  'display',
  // typography
  'fontFamily',
  'textAlign',
  'lineHeight',
  'letterSpacing',
);
Caption.displayName = 'Caption';

export const Caption2 = (props) => <Caption color="text.0" {...props} />;
