import React from 'react';
import { Link } from 'design-system';

export const LinkFooter = ({
  url,
  ariaLabel,
  text,
  paddingRight,
  onLinkClick,
}) => (
  <Link
    color="text.1"
    href={url}
    onClick={onLinkClick}
    aria-label={ariaLabel}
    pr={paddingRight}
    letterSpacing="0.19px"
    lineHeight="16px"
    fontSize="footnote" // old rawFontSizes[0]
    fontFamily="light"
    css="text-decoration: none;"
    hover={{
      color: 'btnTextHover.1',
    }}
    focus={{
      color: 'btnTextHover.1',
      outline: 'none',
    }}
    data-qaid={text ? 'LinkFooter' : null}
  >
    {text}
  </Link>
);
