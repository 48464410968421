import React from 'react';
import { Link, Caption } from 'design-system';

export const Breadcrumb = ({
  text,
  url,
  title,
  ariaLabel,
  currentPage,
  ...props
}) => {
  let output;

  const FontSizes = ['bodyS', 'bodyS', 'bodyS', 'bodyM']; // old [1, 1, 1, 2]
  const LineHeights = [3, 3, 3, 4];

  if (currentPage) {
    output = (
      <Caption
        is="span"
        data-qaid={text ? 'BreadcrumbCurrent' : null}
        {...props}
      >
        {text}
      </Caption>
    );
  } else {
    output = (
      <Link
        fontSize={FontSizes}
        fontFamily="regular"
        lineHeight={LineHeights}
        href={url}
        aria-label={ariaLabel}
        title={title}
        css="text-decoration: none;"
        hover={{
          color: 'btnTextHover.0',
          textDecoration: 'underline',
        }}
        data-qaid={text && url && title && ariaLabel ? 'BreadcrumbRoot' : null}
        {...props}
      >
        {text}
      </Link>
    );
  }
  return output;
};
