import React from 'react';
import { theme } from 'design-system';
import { PardotFormField } from 'components/index';

export const PardotFormFieldCheckbox = (props) => (
  <PardotFormField
    {...props}
    type="checkbox"
    isLabelFloating={false}
    labelCss="transform-origin: top left;"
    inputPosition="before"
    inputStyles={{
      width: 'auto',
      marginRight: theme.verticalSpace.xSmall,
      marginTop: theme.verticalSpace.xSmall,
    }}
    fieldWrapperProps={{
      display: 'flex',
    }}
  />
);

PardotFormFieldCheckbox.displayName = 'PardotFormFieldCheckbox';
