import React from 'react';
import system from 'system-components';
import { Box } from './Box';
import { Collapse } from './Collapse';

// Base Styles
const Paper = system(
  { is: Box, fontFamily: 'light', fontSize: '16px', bg: 'transparent' },
  'fontFamily',
);

export class ExpansionPanel extends React.Component {
  // To ensure ARIA always works if not supplied
  defaultLabelId = (Math.random() / 10 ** -16).toString(16);

  defaultControlId = (Math.random() / 10 ** -16).toString(16);

  isControlled = null;

  state = {};

  constructor(props) {
    super(props);
    this.isControlled = props.expanded !== undefined;
    if (!this.isControlled) {
      this.state.expanded =
        props.defaultExpanded !== undefined ? props.defaultExpanded : false;
    }
  }

  handleChange = (event) => {
    const { expanded: expandedProp, onChange } = this.props;
    const { expanded: expandedState } = this.state;
    const expanded = this.isControlled ? expandedProp : expandedState;
    if (!this.isControlled) {
      this.setState({ expanded: !expanded });
    }

    if (onChange) {
      onChange(event, !expanded);
    }
  };

  render() {
    const {
      controlId = this.defaultControlId,
      children: childrenProp,
      defaultExpanded,
      expanded: expandedProp,
      CollapseProps,
      onChange,
      ...other
    } = this.props;

    const { expanded: expandedState } = this.state;

    const expanded = this.isControlled ? expandedProp : expandedState;

    let summary = null;
    let { labelId = this.defaultLabelId } = this.props;
    const children = React.Children.map(childrenProp, (child, idx) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      /*
                Take first index as Summary content, in place of the line below:
                This was done in case you wanted to enhance the ExpansionPanel.

                // child.type && child.type.displayName == 'ExpansionPanelSummary' ) {
            */
      if (idx === 0) {
        labelId = child.props.id || labelId;
        summary = React.cloneElement(child, {
          id: labelId,
          expanded,
          onChange: this.handleChange,
          'aria-controls': controlId,
        });
        return null;
      }

      return child;
    });

    return (
      <Paper {...other}>
        {summary}
        <Collapse
          id={controlId}
          aria-labelledby={labelId}
          aria-hidden={!expanded}
          expanded={expanded}
          {...CollapseProps}
        >
          {children}
        </Collapse>
      </Paper>
    );
  }
}
ExpansionPanel.defaultProps = {
  defaultExpanded: false,
};
ExpansionPanel.displayName = 'ExpansionPanel';
