import React from 'react';
import Helmet from 'react-helmet';

export const PodcastEpisodeMeta = ({ data }) => (
  <Helmet>
    <meta property="article:published_time" content={data.publishedDate} />
    {(data.tags || [])
      .map(({ props }) => props)
      .map(({ title: tagTitle }) => (
        <meta
          property="article:tag"
          content={tagTitle}
          key={`podcast-tag-meta-${tagTitle}`}
        />
      ))}
    {data.primaryTag && <meta name="twitter:label2" content="Filed under" />}
    {data.primaryTag && (
      <meta name="twitter:data2" content={data.primaryTag.props.title} />
    )}
  </Helmet>
);
