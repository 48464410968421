import system from 'system-components';
import { listStyleType } from '../styles';

/**
 * Simple List
 */
export const List = system(
  {
    is: 'ul',
    m: 0,
    p: 0,
    display: 'block',
    listStyleType: 'none',
    // don't allow listStyleType or its alias on the DOM
    blacklist: ['listStyleType', 'marker'],
  },
  'display',
  'space',
  'color',
  'alignItems',
  'alignContent',
  'justifyContent',
  'flexWrap',
  'flexDirection',
  'flex',
  'flexBasis',
  'justifySelf',
  'alignSelf',
  'order',
  'position',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',
  'borders',
  listStyleType,
);

List.displayName = 'List';
