import React, { Component } from 'react';
import { theme, Column, Row, Wrapper, horizontalSpace } from 'design-system';
import { LargestHeading, LargeHeading, Text, Spacing } from 'components';

export class PageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: undefined,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    });

  render() {
    const {
      title,
      titleProps,
      subTitle,
      breadcrumbs,
      subHeader,
      smallerheader,
      homepage,
    } = this.props;
    const { windowWidth } = this.state;

    const breadcrumbsHeightMobile = 56;
    const breadcrumbsHeightDesktop = 53;
    const defaultYSpace = theme.verticalSpace.large;
    const isMobile = windowWidth < theme.rawBreakpoints[2];

    const getHeaderWithBreadcrumbsTopSpace = () =>
      isMobile
        ? defaultYSpace - breadcrumbsHeightMobile
        : defaultYSpace - breadcrumbsHeightDesktop;

    const headerTopSpace = breadcrumbs
      ? getHeaderWithBreadcrumbsTopSpace()
      : defaultYSpace;
    const headerBottomSpace = defaultYSpace;

    return (
      <Wrapper is="header">
        <Spacing height="xLarge" />
        <Row>
          <Column px="0" width={1}>
            {breadcrumbs}
            <Text type="Large Heading" content={title} />
            {subTitle && <Spacing height="Small" />}
            {subTitle && <Text type="Editorial" content={subTitle} />}
          </Column>
          {React.Children.map(subHeader, (sh, index) => (
            <Column
              width={[1, 1 / 3]}
              // TODO: get a real key in here
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              pt={theme.verticalSpace.medium}
            >
              {sh}
            </Column>
          ))}
        </Row>
      </Wrapper>
    );
  }
}
