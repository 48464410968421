import React from 'react';
import styled from 'styled-components';

import { Box } from 'design-system';
import { camelize } from '../patterns';
import { applyTheme } from '../../utils';
import { horizontalSpace, getOffset } from '../../design-system/theme';

const ContainerBase = ({
  paddingHorizontal = 'none',
  backgroundColour,
  blocks = [],
  width = '100%',
  border = 'none',
  theme,
  isReducedMotion,
  handleOnHover,
  handleOnHoverLeave,
  scrollY,
  offset,
}) => {
  const blocksWithProps = blocks.map((block) =>
    React.cloneElement(block, {
      isReducedMotion,
      handleOnHover,
      handleOnHoverLeave,
      scrollY,
    }),
  );
  const blocksWithTheme = applyTheme(blocksWithProps, theme, backgroundColour);

  return (
    <Box
      px={horizontalSpace[camelize(paddingHorizontal)]}
      bg={camelize(backgroundColour)}
      borderTop={camelize(border) === 'top' ? 1 : 0}
      borderColor={theme === 'light' ? 'black' : 'white'}
      width={width}
      mt={getOffset(camelize(offset))}
    >
      <Box
        borderLeft={camelize(border) === 'left' ? 2 : 0}
        borderColor="red"
        pl={camelize(border) === 'left' ? '24px' : 0}
      >
        {blocksWithTheme}
      </Box>
    </Box>
  );
};

export const Container = styled(ContainerBase)``;
