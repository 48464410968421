import React from 'react';

import { theme, Wrapper, Box } from 'design-system';
import { TextMarkdown, SmallCTA } from 'components';
import { SmallHeading } from '../elements/Headings/SmallHeading';

export const Paragraph = ({
  entryId,
  buildContext,
  paragraphText,
  paragraphCta,
  paragraphTitle,
  ...props
}) => {
  return (
    <Wrapper id={entryId} width={[1, 5 / 6, 7 / 8, 1 / 2]} {...props}>
      <Box pb={paragraphCta ? theme.verticalSpace.small : null}>
        {paragraphTitle && (
          <SmallHeading text={paragraphTitle} pb={theme.verticalSpace.small} />
        )}
        <TextMarkdown>{paragraphText}</TextMarkdown>
      </Box>
      {paragraphCta && <SmallCTA iconLink={paragraphCta} />}
    </Wrapper>
  );
};
