import React from 'react';
import { List, BulletListItem } from 'design-system';

export const EmphasizedListElement = ({ items, ...props }) => (
  <List data-qaid={items ? 'EmphasizedListItem' : null} {...props}>
    {React.Children.map(items, (item) => (
      <BulletListItem>{item}</BulletListItem>
    ))}
  </List>
);
