import React from 'react';
import { withStyles, Label } from 'design-system';

const StyledFieldLabel = withStyles(
  (theme) => ({
    default: {
      ...theme.typography.formFieldLabel,
      transition: `all ${theme.transitions.easeOut.short}`,
      'pointer-events': 'none',
      'transform-origin': 'top left',
    },
  }),
  Label,
);

export const FieldLabel = ({ ...props }) => {
  return <StyledFieldLabel is="label" {...props} />;
};
