import system from 'system-components';
// import { transform } from '../styles';

export const Label = system(
  {
    is: 'label',
    display: 'block',
    fontSize: 'bodyS', // old ['14px']
    color: 'text.3',
    fontFamily: 'light',
    width: 1,
    border: '1px solid',
    borderColor: 'transparent',
    blacklist: ['transform'],
  },
  'borders',
  'borderColor',
  'lineHeight',
  // transform,
);

Label.displayName = 'Label';
