import RiformaRegularWOFF2 from 'fonts/RangleRiformaWeb-Regular.woff2';
import RiformaRegularWOFF from 'fonts/RangleRiformaWeb-Regular.woff';

import RiformaLightWOFF2 from 'fonts/RangleRiformaWeb-Light.woff2';
import RiformaLightWOFF from 'fonts/RangleRiformaWeb-Light.woff';

import RiformaMediumWOFF2 from 'fonts/RiformaLL-Medium.woff2';
import RiformaMediumWOFF from 'fonts/RiformaLL-Medium.woff';

const fontFiles = {
  RiformaRegularWOFF2,
  RiformaRegularWOFF,
  RiformaLightWOFF2,
  RiformaLightWOFF,
  RiformaMediumWOFF2,
  RiformaMediumWOFF,
};

const fontFamily = {
  regular: 'Rangle Riforma Regular',
  light: 'Rangle Riforma Light',
};

const fontStack = 'Helvetica Neue, Helvetica, Arial, sans-serif';

export const fonts = {
  regular: `${fontFamily.regular}, ${fontStack}`,
  light: `${fontFamily.light}, ${fontStack}`,
  riforma: `"Rangle Riforma", ${fontStack}`,
};

export const globalFonts = `
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * rangle.io
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

 // to be deprecated
@font-face {
  font-family: ${fontFamily.regular};
  src: url(${fontFiles.RiformaRegularWOFF2}) format("woff2"),
       url(${fontFiles.RiformaRegularWOFF}) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
  fallback: ${fontStack};
}
@font-face {
  font-family: ${fontFamily.light};
  src: url(${fontFiles.RiformaLightWOFF2}) format("woff2"),
       url(${fontFiles.RiformaLightWOFF}) format("woff");
  font-weight: normal;
}
// deprecation end

@font-face {
  font-family: 'Rangle Riforma';
  src: url(${fontFiles.RiformaRegularWOFF2}) format("woff2"),
       url(${fontFiles.RiformaRegularWOFF}) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
  fallback: ${fontStack};
}
@font-face {
  font-family: 'Rangle Riforma';
  src: url(${fontFiles.RiformaLightWOFF2}) format("woff2"),
       url(${fontFiles.RiformaLightWOFF}) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
  fallback: ${fontStack};
}
@font-face {
  font-family: 'Rangle Riforma';
  src: url(${fontFiles.RiformaMediumWOFF2}) format("woff2"),
       url(${fontFiles.RiformaMediumWOFF}) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
  fallback: ${fontStack};
}
`;
