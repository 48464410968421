import system from 'system-components/dist';
import { theme } from '../theme';

export const FooterBlock = system(
  {
    is: 'footer',
    background: theme.colors.bg[0],
    display: 'block',
    width: '100%',
    position: 'relative',
  },
  // core
  'space',
  'width',
  'height',
  'color',
  // borders
  'borders',
  'borderLeft',
  'borderColor',
  'borderRadius',
  // flexbox
  'alignItems',
  'alignContent',
  'justifyContent',
  'flexWrap',
  'flexDirection',
  'flex',
  'flexBasis',
  'justifySelf',
  'alignSelf',
  'order',
);
FooterBlock.displayName = 'FooterBlock';
