import React from 'react';
import { IconButton, theme, SvgIcon } from 'design-system';

const PlayIcon = ({ ...props }) => (
  <SvgIcon
    {...props}
    title="PlayIcon"
    x="0px"
    y="0px"
    width="26px"
    height="25px"
    viewBox="0 0 26 25"
  >
    <g
      id="Desktop-1920px-Our-services-play"
      transform="translate(-411.000000, -1107.000000)"
    >
      <polygon
        id="Medium-heading-Copy-5"
        points="411.64 1107.04 436.6 1119.52 411.64 1132"
      />
    </g>
  </SvgIcon>
);

const PauseIcon = ({ ...props }) => (
  <SvgIcon
    {...props}
    title="PlayIcon"
    x="0px"
    y="0px"
    width="22px"
    height="25px"
    viewBox="0 0 22 25"
  >
    <g
      id="Desktop-1920px-Our-services"
      transform="translate(-413.000000, -1107.000000)"
    >
      <path
        d="M434.68,1132 L427,1132 L427,1107.04 L434.68,1107.04 L434.68,1132 Z M421.24,1132 L413.56,1132 L413.56,1107.04 L421.24,1107.04 L421.24,1132 Z"
        id="Medium-heading-Copy-5"
      />
    </g>
  </SvgIcon>
);

/**
 * This should extend ToggleButton (not yet created) for functionality/accessible, etc.
 */
export const PlayPauseButton = ({ play: isPlaying, ...props }) => {
  return (
    <IconButton
      pt={theme.verticalSpace.xxSmall}
      variant="fab"
      width={['55px', '60px', '60px', '72px']}
      height={['55px', '60px', '60px', '72px']}
      bg={theme.colors.bg[0]}
      role="switch" // make this an accessible toggle button
      aria-checked={isPlaying} // tell non-sighted users the state of this button (on = playing / off = paused)
      focus={{
        outline: `1px solid ${theme.colors.btnTextHover[0]}`,
      }}
      {...props}
    >
      {!!isPlaying && <PauseIcon />}
      {!isPlaying && <PlayIcon />}
    </IconButton>
  );
};
