import React from 'react';
import system from 'system-components';
import Observer from 'react-intersection-observer';
import { theme } from '../theme';

const fadeAnimation = theme.transitions.easeOut.xLong;

// eslint-disable-next-line react/prefer-stateless-function
export class Video extends React.Component {
  render() {
    const { revealDelay = 0, ...props } = this.props;

    return (
      <Observer>
        {({ inView, ref }) => (
          <StyledVideo
            innerRef={ref}
            style={{
              opacity: `${inView ? 1 : 0}`,
              transition: `opacity ${fadeAnimation} ${revealDelay}s`,
            }}
            {...props}
          />
        )}
      </Observer>
    );
  }
}

export const StyledVideo = system(
  {
    is: 'video',
    display: 'block',
    height: 'auto',
  },
  'display',
  'space',
  'color',
  'width',
  'height',
  'position',
  'color',
  'top',
  'left',
  'bottom',
  'right',
  'opacity',
);
StyledVideo.displayName = 'StyledVideo';
