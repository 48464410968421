import system from 'system-components';

export const Heading = system(
  {
    is: 'h1',
    color: 'text.0',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontFamily: 'light',
  },
  // core
  'space',
  'width',
  'color',
  // typography
  'fontSize',
  'fontFamily',
  'textAlign',
  'lineHeight',
  'fontWeight',
  'letterSpacing',
  // variants
  'colorStyle',
  'display',
);
Heading.displayName = 'Heading';
