import React from 'react';
import { Box, Text } from 'design-system';

export const Callout = ({
  calloutTitle,
  calloutText,
  calloutLink = null,
  ...props
}) => {
  return (
    <Box bg="bg.4" p={[4]} {...props} position="relative">
      {calloutTitle && (
        <Text
          lineHeight={[4, 4, 4, 6]}
          fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
          // old {[2, 2, 2, 3]}
          fontFamily="regular"
          mb={[2]}
        >
          {calloutTitle}
        </Text>
      )}
      {calloutText && (
        <Text
          fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
          // old {[2, 2, 2, 3]}
          lineHeight={[4, 4, 4, 6]}
          mb={[3, 3, 3, 4]}
          color="text.0"
        >
          {calloutText}
        </Text>
      )}
      {calloutLink}
    </Box>
  );
};
