import React from 'react';
import ReactMarkdown from 'react-markdown';
import { renderers as defaultRenderers } from './MarkdownStyles';

const defaultExcludes = [
  'heading',
  'image',
  'imageReference',
  'inlineCode',
  'code',
  'blockquote',
  'table',
  'tableHead',
  'tableBody',
  'tableRow',
  'tableCell',
  'strong',
  'emphasis',
  'delete',
  'thematicBreak',
  'definition',
];

const defaultKeep = ['root', 'text', 'break', 'html'];

const defaultIncludes = [
  'link',
  'linkReference',
  'paragraph',
  'list',
  'listItem',
];

export const Markdown = ({
  // one-time override append/override renderers
  renderers: rendererProp = {},
  // allowed or disallowed types - they get merged!
  disallowedTypes: disallowedProp = [],
  allowedTypes: allowedProp = [],
  skipHtml = true,
  // any other ReactMarkdown props
  ...props
}) => {
  // eslint-disable-next-line react/destructuring-assignment
  const QAID = { 'data-qaid': props.children ? 'markdown' : null };

  /* eslint-disable no-bitwise */
  // remove any defaultKeep
  // eslint-disable-next-line no-param-reassign
  disallowedProp = disallowedProp.filter((e) => !~defaultKeep.indexOf(e));

  // list all allowed
  const allowed = [...defaultIncludes, ...allowedProp].filter(
    (e) => !~disallowedProp.indexOf(e),
  );
  // list all disallowed
  const disallowed = [...defaultExcludes, ...disallowedProp].filter(
    (e) => !~allowedProp.indexOf(e),
  );
  // ensure disallowed precedence. (no-need to remove duplicates)
  const disallowedTypes = disallowed.filter((e) => !~allowed.indexOf(e));
  /* eslint-enable no-bitwise */
  // append/override renderers
  const renderers = { ...defaultRenderers, ...rendererProp };

  return (
    <div {...QAID}>
      <ReactMarkdown
        skipHtml={skipHtml}
        disallowedTypes={disallowedTypes}
        components={renderers}
        {...props}
      />
    </div>
  );
};
