import React from 'react';
import { Box } from 'design-system';

export const LargeCTA = ({
  darkBackground,
  iconLink,
  color = darkBackground ? 'darkBackgroundCTAs' : 'lightBackgroundCTAs',
  ...props
}) => (
  <Box
    fontFamily="regular"
    fontSize={['bodyL', 'bodyL', 'bodyL', 'subHeadingXl']}
    // old [3, 3, 3, 5]
    lineHeight={[6, 6, 6, '40px']}
    {...props}
  >
    {React.Children.map(iconLink, (child) =>
      React.cloneElement(child, { colorStyle: color, fontFamily: 'regular' }),
    )}
  </Box>
);
