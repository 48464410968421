import React from 'react';
import { theme, Row, Column, Box, Avatar } from 'design-system';
import { QuoteBody, QuoteAuthor } from 'components';
import { getImageURL } from 'utils';

const Paragraph = (props) => (
  <Box
    is="p"
    m="0"
    css="-webkit-margin-before: 0; margin-before: 0;"
    {...props}
  />
);

const comma = <QuoteAuthor text=", " />;

export const Quote = ({
  quoteBody,
  quoteAuthorName,
  quoteAuthorTitle,
  quoteAuthorAvatar,
  quoteAuthorAvatarAltText,
  ...props
}) => {
  let quoteAuthorRender;
  let author;
  let title;
  let titleWithImage;
  let authorWithImageRender;
  let qaid;

  if (quoteAuthorName) {
    author = <QuoteAuthor text={quoteAuthorName} />;
  }

  if (quoteAuthorTitle) {
    title = <QuoteAuthor text={quoteAuthorTitle} />;
    titleWithImage = <QuoteAuthor display="block" text={quoteAuthorTitle} />;
  }

  const image = getImageURL(quoteAuthorAvatar);

  if (image) {
    qaid =
      quoteAuthorName && quoteAuthorAvatarAltText ? 'QuoteWithAvatar' : null;

    if (quoteAuthorName && !quoteAuthorTitle) {
      authorWithImageRender = (
        <Box alignContent="center" display="flex" alignItems="center">
          <Paragraph lineHeight={[3, 4, 4, '38px']}>{author}</Paragraph>
        </Box>
      );
    } else if (quoteAuthorName && quoteAuthorTitle) {
      authorWithImageRender = (
        <Box alignContent="center" display="flex" alignItems="center">
          <Paragraph lineHeight={[3, 4, 4, '38px']}>
            {author}
            {comma}
            {titleWithImage}
          </Paragraph>
        </Box>
      );
    } else {
      return null;
    }

    quoteAuthorRender = (
      <Box width={1} display="flex">
        <Box
          pr={[
            theme.verticalSpace.small,
            theme.verticalSpace.small,
            theme.verticalSpace.small,
            theme.verticalSpace.medium,
          ]}
        >
          <Box
            width={[
              '92px', // Mobile
              '92px', // Tablet
              '92px', // Desktop
              '157px', // 1366 and up
            ]}
          >
            <Avatar
              src={image}
              alt-text={quoteAuthorAvatarAltText}
              data-qaid={qaid ? 'QuoteAvatar' : null}
            />
          </Box>
        </Box>
        {authorWithImageRender}
      </Box>
    );
  } else {
    qaid = quoteAuthorName && quoteAuthorTitle ? 'Quote' : null;

    if (quoteAuthorName && !quoteAuthorTitle) {
      quoteAuthorRender = <Paragraph width={1}>{author}</Paragraph>;
    } else if (quoteAuthorName && quoteAuthorTitle) {
      quoteAuthorRender = (
        <Paragraph width={1}>
          {author}
          {comma}
          {title}
        </Paragraph>
      );
    } else {
      return null;
    }
  }

  return (
    <Row {...props}>
      <Column width={1}>
        <Box width={[3 / 4, 4 / 6, 4 / 6, 6 / 8]} mx="auto" data-qaid={qaid}>
          <QuoteBody text={quoteBody} />
          {quoteAuthorRender}
        </Box>
      </Column>
    </Row>
  );
};
