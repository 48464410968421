import React from 'react';
import { withTheme } from 'styled-components';
import { theme as staticTheme } from '../theme';

/**
 *
 * @param {*} styleMap - the styles to return based on provided theme and themeType,
 * @param {*} StyledComponent - the Component to style - should already be a styled-component
 */
export const withStyles = (styleMap = () => {}, StyledComponent) => {
  // static theme used here in case theme doesn't get passed (i.e. Testing)
  // TODO:  find a better way to accomodate this.
  return withTheme(
    ({
      theme = staticTheme,
      themeType,
      variant: themeVariant = 'default',
      ...props
    }) => {
      const themeStyles = styleMap(theme, props, themeType);
      const styleProps = themeStyles[themeVariant] || {};
      return <StyledComponent {...styleProps} {...props} />;
    },
  );
};

// Allows to add in a particular `is` or change tag - use sparingly, used in TextMarkdown
// eslint-disable-next-line react/display-name
export const withSemantics = (semTag, StyledComponent, xtraProps = {}) => ({
  is: tag,
  ...props
}) => {
  const combinedProps = { ...xtraProps, ...props };
  return <StyledComponent is={tag || semTag} {...combinedProps} />;
};
