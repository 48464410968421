import React from 'react';
import { Box } from './Box';

export const Row = (props) => (
  <Box
    maxWidth="100%"
    display="flex"
    flexWrap="wrap"
    alignContent="flex-start"
    {...props}
  />
);
