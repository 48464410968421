import React from 'react';
import { Box, Text } from 'design-system';

export const FooterList = ({ title, links, linksContainer }) => {
  return (
    <Box display="flex" flexDirection="column" css="margin-bottom: 35px;">
      <Text
        color="ui.3"
        fontSize="bodyS" // old {1}
        fontFamily="regular"
        lineHeight="28px"
        letterSpacing="0.17px"
        css="margin-bottom: 8px;"
      >
        {title}
      </Text>
      {links && React.Children.map(links, (link) => <Box>{link}</Box>)}
      <Box>
        {React.Children.map(linksContainer, (container) => (
          <Box>{container}</Box>
        ))}
      </Box>
    </Box>
  );
};
