import React from 'react';
import { Heading } from 'design-system';

export const SmallHeading = ({ text, ...props }) => (
  <Heading
    is="h3"
    fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
    // old [2, 2, 2, 3]
    lineHeight={[4, 4, 4, 6]}
    m={0}
    data-qaid={text ? 'smallHeading' : null}
    {...props}
  >
    {text}
  </Heading>
);
