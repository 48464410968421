import React from 'react';
import styled from 'styled-components';
import { space, color, borders, borderColor } from 'styled-system';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetail,
  withStyles,
} from '../../../design-system';
import { PlusMinusIcon } from '../PlusMinusIcon';

const StyledAccordion = withStyles(
  (theme) => ({
    default: {
      px: 0,
      py: theme.verticalSpace.medium,
      mt: '-1px',
      borderBottom: '1px solid',
      borderColor: 'uiDivider',
    },
  }),
  styled(ExpansionPanel)`
    ${space}
    ${color}
    ${borders}
    ${borderColor}
  `,
);

const SummaryHeader = ({ headingLevel, ...props }) => {
  return (
    <div role="heading" aria-level={headingLevel}>
      <ExpansionPanelSummary {...props} />
    </div>
  );
};

const StyledDetail = withStyles(
  (theme) => ({
    default: {
      pt: theme.verticalSpace.small,
    },
  }),
  styled(ExpansionPanelDetail)`
    ${space}
  `,
);

export class AccordionPanel extends React.Component {
  constructor(props) {
    super(props);
    const { expanded } = this.props;
    this.state = { expanded: expanded || false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  render() {
    const {
      // expandedIcon = null,
      // collapsedIcon = null,
      headingLevel,
      summary,
      details,
      dataOn,
      dataEventCategory,
      dataEventAction,
      dataEventLabel,
      ...props
    } = this.props;
    const { expanded } = this.state;

    // old fontSize={4}
    const icon = <PlusMinusIcon fontSize="subHeadingM" isMinus={!!expanded} />;

    return (
      <StyledAccordion
        {...props}
        expanded={expanded}
        CollapseProps={{ role: 'region' }}
      >
        <SummaryHeader
          headingLevel={headingLevel}
          expandIcon={icon}
          onClick={this.handleClick}
          {...(!expanded
            ? {
                'data-on': dataOn,
                'data-event-category': dataEventCategory,
                'data-event-action': dataEventAction,
                'data-event-label': dataEventLabel,
              }
            : {})}
        >
          {summary}
        </SummaryHeader>
        <StyledDetail>{details}</StyledDetail>
      </StyledAccordion>
    );
  }
}
