import React from 'react';

export const Hover = ({
  link,
  image,
  handleOnHover = () => {},
  handleOnHoverLeave = () => {},
  ...props
}) => {
  // to do: validation for whether an image will be required or not
  if (image && link) {
    const imageURL = image.props.url;

    const linkWithProps = React.cloneElement(link, {
      handleOnHover: () => handleOnHover(imageURL),
      handleOnHoverLeave,
      isHoverable: true,
      ...props,
      // This is to prevent title tooltips from showing up on hover
      title: ''
    });

    return linkWithProps;
  }
  return null;
};
