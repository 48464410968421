import React from 'react';
import { Box, theme } from 'design-system';
import Observer from 'react-intersection-observer';

export const FooterCTA = ({
  darkBackground,
  iconLink,
  color = darkBackground ? 'darkBackgroundCTAs' : 'lightBackgroundCTAs',
  revealDelay = false,
}) => (
  <Observer>
    {({ inView, ref }) => (
      <Box
        fontFamily="regular"
        fontSize={['bodyL', 'bodyL', 'bodyL', 'subHeadingXl']}
        // old [3, 3, 3, 5]
        lineHeight={[6, 6, 6, '40px']}
        innerRef={ref}
        css={`
          opacity: ${!inView && revealDelay ? 0 : 1};
          transition: opacity ${theme.transitions.easeOut.xLong}
            ${theme.animationTime[2]}s;
        `}
      >
        {React.Children.map(iconLink, (child) =>
          React.cloneElement(child, {
            colorStyle: color,
            fontFamily: 'regular',
          }),
        )}
      </Box>
    )}
  </Observer>
);
