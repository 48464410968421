import React from 'react';
import { Text, Link, withStyles, withSemantics } from 'design-system';

const first = { 'margin-top': 0 };
const last = { 'margin-bottom': 0 };
const StyledTextMD = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      ...theme.typography[themeType].body1,
      mt: 0,
      mb: theme.verticalSpace.small,
      mx: 0,
      p: 0,
      firstChild: first,
      lastChild: last,
    },
    list: {
      ...theme.typography[themeType].body1,
      mt: 0,
      mb: theme.verticalSpace.small,
      firstChild: first,
      lastChild: last,
    },
    listItem: { ...theme.typography[themeType].body1 },
  }),
  Text,
);

const HeaderStyleMD = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      ...theme.typography[themeType].smallHeading,
      mt: theme.verticalSpace.large,
      mb: theme.verticalSpace.xSmall,
      fontWeight: 'normal',
      firstChild: first,
      lastChild: last,
    },
    h2: {
      ...theme.typography[themeType].mediumHeading,
      mt: theme.verticalSpace.large,
      mb: theme.verticalSpace.xSmall,
      fontWeight: 'normal',
      firstChild: first,
      lastChild: last,
    },
    h3: {
      ...theme.typography[themeType].smallHeading,
      mt: theme.verticalSpace.large,
      mb: theme.verticalSpace.xSmall,
      fontWeight: 'normal',
      firstChild: first,
      lastChild: last,
    },
  }),
  Text,
);

export const HeadingMD = ({ level, variant: variantProp, ...props }) => {
  // Presently the only levels supported are h2 & h3
  const tag = level ? `h${Math.max(2, Math.min(level, 3))}` : null;
  // style based on level or default
  const variant = tag || 'default';
  return <HeaderStyleMD is={tag || 'h3'} variant={variant} {...props} />;
};

export const ParagraphMD = withSemantics('p', StyledTextMD);
export const ListMD = withSemantics('ul', StyledTextMD, { variant: 'list' });
export const ListItemMD = withSemantics('li', StyledTextMD, {
  variant: 'listItem',
});

const StyledLinkMD = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      ...theme.typography[themeType].textLink,
      fontFamily: 'light',
      fontSize: 'inherit',
      textDecoration: 'underline',
    },
  }),
  Link,
);
export const LinkMD = (props) => (
  <StyledLinkMD
    // eslint-disable-next-line react/destructuring-assignment
    aria-label={props.title}
    {...props}
  />
);

/**
 * List of all renderers
 */
export const renderers = {
  // link: LinkMD,
  a: LinkMD,
  // linkReference: LinkMD,
  // paragraph: ParagraphMD,
  p: ParagraphMD,
  // list: ListMD,
  ul: ListMD,
  // listItem: ListItemMD,
  li: ListItemMD,
  // heading: HeadingMD,
  h1: HeadingMD,
  h2: HeadingMD,
  h3: HeadingMD,
  h4: HeadingMD,
  h5: HeadingMD,
  h6: HeadingMD,
};
