import React from 'react';
import { Box, theme, Wrapper } from 'design-system';

export const Section = ({
  sectionImage,
  sectionHeader,
  sectionModules,
  hasBackground,
  id,
}) => {
  const SectionImageWithSpacing = React.Children.map(sectionImage, (child) => {
    // If the Section Header is not hidden then we need to apply the spacing between the image and the header
    return React.cloneElement(child, {
      pb:
        (sectionHeader && !sectionHeader.props.hidden) || sectionModules
          ? theme.verticalSpace.small
          : '0',
    });
  });

  const SectionHeaderWithSpacing = React.Children.map(
    sectionHeader,
    (child) => {
      let sectionHeaderBottomSpace;

      if (
        !sectionHeader.props.hidden &&
        sectionModules &&
        sectionHeader.props.paragraphText
      ) {
        // If the Section Header is not hidden and there are section modules
        // attached then we'll use the large spacing at the bottom
        sectionHeaderBottomSpace = theme.verticalSpace.large;
      } else if (sectionModules && !sectionHeader.props.paragraphText) {
        // If the Section Header only has a title and no paragraph text,
        // then we want to show it more closely related to the Section Modules attached
        sectionHeaderBottomSpace = theme.verticalSpace.medium;
      } else if (
        (sectionHeader.props.hidden && sectionHeader.props.image) ||
        !sectionModules
      ) {
        // If there are no Section Modules attached or if the Section Header
        // is set to hidden then we will have no bottom space
        sectionHeaderBottomSpace = '0';
      }

      return React.cloneElement(child, { pb: sectionHeaderBottomSpace });
    },
  );

  const SectionModulesWithSpacing = React.Children.map(
    sectionModules,
    (child, index) => {
      if (index === sectionModules.length - 1) {
        // The last Section Module in the group does not get any padding below
        // because it's covered by the Section's XLarge default padding
        return React.cloneElement(child, { pb: '0' });
      }
      // All section modules should have a large space separating them, but XLarge is reserved for
      // between separate sections
      return React.cloneElement(child, { pb: theme.verticalSpace.large });
    },
  );

  return (
    <Box
      is="section"
      width={1}
      pb={[theme.verticalSpace.large, theme.verticalSpace.xLarge]}
      data-qaid={sectionHeader && id ? 'section' : null}
      aria-label={sectionHeader && id ? sectionHeader.props.title : null}
    >
      <Box
        width={1}
        bg={hasBackground ? 'bg.3' : null}
        py={
          hasBackground
            ? [theme.verticalSpace.large, theme.verticalSpace.xLarge]
            : null
        }
      >
        <Wrapper px="0">
          {SectionImageWithSpacing}
          {SectionHeaderWithSpacing}
          {SectionModulesWithSpacing}
        </Wrapper>
      </Box>
    </Box>
  );
};
