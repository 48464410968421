import React, { Component } from 'react';
import get from 'lodash/get';

import { Box, Label } from 'design-system';
import { FieldErrorLabel } from './FieldErrorLabel';

class Field extends Component {
  state = {
    isEmpty: true,
    isActive: false,
  };

  handleFocus = (e) => {
    this.setState({ isActive: true });
    const childOnFocus = get(this.props, 'children.props.onFocus');

    if (childOnFocus === 'function') {
      childOnFocus.onFocus(e);
    }
  };

  handleBlur = (e) => {
    this.setState({ isActive: false });
    const childOnBlur = get(this.props, 'children.props.onBlur');

    if (typeof childOnBlur === 'function') {
      childOnBlur(e);
    }
  };

  handleChange = (e) => {
    const isEmpty = get(e, 'target.value', '') === '';
    const childOnChange = get(this.props, 'children.props.onChange');

    this.setState({ isEmpty });
    if (typeof childOnChange === 'function') {
      childOnChange(e);
    }
  };

  isLabelFloating = () => {
    const { isEmpty, isActive } = this.state;
    if (
      // Floating is enabled.
      get(this.props, 'isLabelFloating', true) === false ||
      // Field has value.
      (!isEmpty || get(this.props, 'children.props.value')) ||
      // Field has placeholder
      get(this.props, 'children.props.placeholder') ||
      // Field is active.
      isActive
    ) {
      // Float label to the top.
      return true;
    }

    // Position label over field.
    return false;
  };

  render() {
    const {
      inputId,
      labelId,
      errorId,
      label = '',
      error = '',
      isLabelVisible = true,
      children,
      inputPosition = 'after',
      fieldWrapperProps,
      labelCss = '',
      labelProps = {},
    } = this.props;

    const clonedChildren = React.cloneElement(React.Children.only(children), {
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      isInvalid: !!error,
    });

    return (
      <Box position="relative" {...fieldWrapperProps}>
        {inputPosition === 'before' && clonedChildren}

        {label && (
          // TODO:  Refactor to FieldLabel for styling.
          <Label
            is="label"
            id={labelId}
            htmlFor={inputId}
            fontSize={['bodyM', 'bodyL']}
            // old [2, 3]
            lineHeight={[4, 6]}
            transform={
              this.isLabelFloating()
                ? ['scale(calc(14/16))', 'scale(calc(14/20))']
                : ['translateY(100%)']
            }
            css={`
              visibility: ${isLabelVisible ? 'visible' : 'hidden'};
              transition: all 250ms ease-out;
              pointer-events: none;
              transform-origin: bottom left;
              ${labelCss}
            `}
            {...labelProps}
          >
            {label}
          </Label>
        )}

        {inputPosition === 'after' && clonedChildren}

        {error && (
          // TODO: Consider aria-live
          <FieldErrorLabel id={errorId}>{error}</FieldErrorLabel>
        )}
      </Box>
    );
  }
}

export { Field };
