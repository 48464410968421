import React from 'react';
import system from 'system-components';
import { theme, Box, horizontalSpace } from '../../../design-system';
import { camelize } from '../../patterns/Camelize';

const AccordionContainer = system({
  is: Box,
  px: theme.gutters,
  py: 0,
  m: 0,
});

const StyledAccordion = system({
  is: Box,
  borderTop: '1px solid',
  borderColor: 'uiDivider',
});

export class Accordion extends React.Component {
  defaultEntryId = (Math.random() / 10 ** -16).toString(16);

  render() {
    const {
      entryId = this.defaultEntryId,
      accordionName,
      accordionItems = [],
      buildContext,
      paddingH = 'none',
      ...props
    } = this.props;

    const children = React.Children.map(accordionItems, (child, index) =>
      React.cloneElement(child, {
        // TODO: get a real key in here
        // eslint-disable-next-line react/no-array-index-key
        key: index,
      }),
    );

    const QAID = {
      'data-qaid':
        accordionName && accordionItems.length >= 1 ? 'Accordion' : null,
    };

    return (
      <AccordionContainer
        px={paddingH === 'none' ? 'none' : horizontalSpace[camelize(paddingH)]}
        {...props}
        {...QAID}
      >
        <StyledAccordion name={accordionName || entryId} role="presentation">
          {children}
        </StyledAccordion>
      </AccordionContainer>
    );
  }
}
Accordion.displayName = 'Accordion';
