import React from 'react';
import lottie from 'lottie-web';

export class LottieAnimation extends React.Component {
  componentDidMount() {
    const { url, isReducedMotion, isLoop = true } = this.props;
    this.anim = lottie.loadAnimation({
      container: document.getElementById('lottie-animation'), // the dom element that will contain the animation
      loop: isLoop,
      autoplay: !isReducedMotion,
      path: url, // the path to the animation json
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
    lottie.registerAnimation();
    this.anim.addEventListener('data_ready', this.handleMotionQuery);
  }

  componentWillReceiveProps(nextProps) {
    const { inView } = this.props;
    if (inView !== nextProps.inView && nextProps.inView) {
      this.anim.goToAndPlay(0, true);
    }
  }

  componentDidUpdate(nextProps) {
    const { isReducedMotion } = this.props;
    if (isReducedMotion !== nextProps.isReducedMotion) {
      this.handleMotionQuery();
    }
  }

  componentWillUnmount() {
    lottie.destroy();
  }

  handleMotionQuery = () => {
    const { isReducedMotion } = this.props;
    if (isReducedMotion) {
      this.anim.goToAndStop(this.anim.totalFrames - 1, true);
    } else {
      this.anim.play();
    }
  };

  render() {
    return <div id="lottie-animation" />;
  }
}
