import React from 'react';

export const VideoPlaylistRandomizer = ({ videos }) => {
  const randomNumber = Math.floor(Math.random() * videos.length);
  const getVideoToRender = (video, index) => index === randomNumber;

  return <React.Fragment>{videos.find(getVideoToRender)}</React.Fragment>;
};

export const EditorialContentVideoPlaylistRandomizer = VideoPlaylistRandomizer;
export const EditorialImageVideoPlaylistRandomizer = VideoPlaylistRandomizer;
