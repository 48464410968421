import React from 'react';
// import styled, { keyframes } from 'styled-components';
// import { theme } from 'design-system';

// const logoCount = 97;
// const transitionTiming = 0.15;

// const getLogoAnimationSteps = (logoCountArg, showDuration) =>
//   Array(logoCountArg)
//     .fill()
//     .map((_, i) => i)
//     .map((index) => ({
//       delay: showDuration * index,
//       childNum: index + 3, // <-- skip the first two (for the "R" and "Triangle")
//     }))
//     .map(
//       ({ delay, childNum }) =>
//         `& svg g:nth-child(${childNum}) { animation-delay: ${delay}s;}`,
//     );

// const fadeIn = keyframes`
//     0% { opacity: 1;}
//     100% { opacity: 1; }
//   `;
// const AngleGroup = styled.div`
//     #R {
//       opacity: 1;
//     }
//     #Angle {
//       opacity: 1;
//     }
//     &:hover #Angle { opacity 0; animation: none; }
//     &:hover #R {
//       opacity: 1;
//       animation: none;
//     }
//     & svg > g {
//       opacity: 0;
//       fill: ${theme.colors.brand[1]};
//     }
//     &:hover svg > g {
//       animation-name: ${fadeIn};
//       animation-duration: ${transitionTiming}s;
//     }
//     ${getLogoAnimationSteps(logoCount, transitionTiming)}
//   `;

export const RangleSvgMobile = ({ variant }) => {
  const logoStyle = { fill: variant };
  return (
    <svg width="40px" height="20px" viewBox="0 0 56 28">
      <title>We‘re glad you‘re here</title>
      <g id="R" style={logoStyle}>
        <path
          id="Fill-1"
          d="M14.4,12.4c2.4,0,4-1.6,4-4c0-2.4-1.6-4-4-4H5.2v8H14.4z M5.2,28H0V0h14.4c5.6,0,9.2,3.4,9.2,8.4
        s-3.6,8.4-9.2,8.4h-1.2L24.4,28h-7l-11-11.2H5.2V28z"
        />
      </g>
      <g id="_x30_1_1_" style={logoStyle}>
        <polygon points="56,0 56,28 28,28" />
      </g>
    </svg>
  );
};

export const RangleSvgDesktopLite = ({ variant }) => {
  const logoStyle = { fill: variant };
  return (
    <svg style={{ width: 'auto', height: '100%' }} viewBox="0 0 56 28">
      <title>We‘re glad you‘re here</title>
      <g id="R" style={logoStyle}>
        <path
          id="Fill-1"
          d="M14.4,12.4c2.4,0,4-1.6,4-4c0-2.4-1.6-4-4-4H5.2v8H14.4z M5.2,28H0V0h14.4c5.6,0,9.2,3.4,9.2,8.4
        s-3.6,8.4-9.2,8.4h-1.2L24.4,28h-7l-11-11.2H5.2V28z"
        />
      </g>
      <g id="_x30_1_1_" style={logoStyle}>
        <polygon points="56,0 56,28 28,28" />
      </g>
    </svg>
  );
};
