import React from 'react';

import { Markdown } from 'components';

const allTypes = ['heading', 'paragraph', 'list', 'listItem', 'link'];

export const TextMarkdown = ({ ...props }) => {
  return <Markdown {...props} />;
};
export const FullTextMarkdown = ({ ...props }) => {
  return (
    <Markdown
      skipHtml={false}
      escapeHtml={false}
      allowedTypes={allTypes}
      {...props}
    />
  );
};
