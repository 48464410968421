import React, { Component } from 'react';
import { theme, Box, Video } from 'design-system';
import { getImageURL } from 'utils';
import ReactDOM from 'react-dom';
import { PlayPauseButton } from './PlayPauseButton';

export class ResponsiveVideoSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      isPlaying: true,
      showingControls: false,
    };
    this.posterImagesBeingLoaded = {};
  }

  componentDidMount() {
    this.setState({ windowWidth: window.innerWidth });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleVideoLoaded = () => {
    this.setState({ showingControls: true });
  };

  handleClick = () => {
    const { isPlaying } = this.state;
    // eslint-disable-next-line react/no-find-dom-node
    const rootVideo = ReactDOM.findDOMNode(this.video);
    if (isPlaying) {
      rootVideo.pause();
    } else {
      rootVideo.play();
    }
    this.setState((state) => ({ isPlaying: !state.isPlaying }));
  };

  detectPosterLoad(posterImage) {
    if (!this.posterImagesBeingLoaded[posterImage]) {
      // if (typeof window !== 'undefined') {
      //   const image = new Image();
      //   image.onload = () => {
      //     this.setState({ showingControls: true });
      //   };
      //   image.src = posterImage;
      //   this.posterImagesBeingLoaded[posterImage] = true;
      // }
    }
  }

  render() {
    const { showingControls } = this.state;
    const {
      mp4Desktop,
      webmDesktop,
      mp4Mobile,
      webmMobile,
      posterResponsiveImageSet,
      posterMobile,
      posterDesktop,
      aspectRatio,
      /**
       * videoControls boolean: show or hide the video control box. (default true)
       *
       * In the Future, this should be moved to the Video Component, and allowed to
       * be customized via this property to add controls of any type.
       */
      videoControls = showingControls,
    } = this.props;

    const { windowWidth, isPlaying } = this.state;
    let paddingBottom;
    let posterImage;
    let webmUrl;
    let mp4Url;
    let key;

    if (windowWidth < theme.rawBreakpoints[0]) {
      paddingBottom = aspectRatio ? aspectRatio[0] * 100 : null;
      posterImage = posterResponsiveImageSet
        ? getImageURL(posterResponsiveImageSet.props.imageMobile)
        : posterMobile;
      webmUrl = getImageURL(webmMobile);
      mp4Url = getImageURL(mp4Mobile);
      key = 'VideoMobile';
    } else if (windowWidth >= theme.rawBreakpoints[0]) {
      paddingBottom = aspectRatio ? aspectRatio[1] * 100 : null;
      posterImage = posterResponsiveImageSet
        ? getImageURL(posterResponsiveImageSet.props.imageDesktop)
        : posterDesktop;
      webmUrl = getImageURL(webmDesktop);
      mp4Url = getImageURL(mp4Desktop);
      key = 'VideoDesktop';
    }

    this.detectPosterLoad(posterImage);

    return (
      <Box
        width={1}
        alignSelf="center"
        justifyContent="center"
        position={aspectRatio ? 'relative' : 'static'}
        pb={aspectRatio ? `${paddingBottom}%` : 0}
        background={aspectRatio ? theme.colors.ui[3] : null}
      >
        <Video
          ref={(video) => {
            this.video = video;
          }}
          key={key}
          autoplay="true"
          loop
          muted
          onPlay={this.handleVideoLoaded} // in case there is no poster image, controls will show after video loads
          playsInline
          preload="auto"
          width="100%"
          poster={posterImage}
          css="pointer-events: none;"
          data-qaid={webmUrl || mp4Url ? 'ResponsiveVideoSet' : null}
          position={aspectRatio ? 'absolute' : 'static'}
          top={aspectRatio ? 0 : null}
          left={aspectRatio ? 0 : null}
        >
          <source src={webmUrl} type="video/webm" />
          <source src={mp4Url} type="video/mp4" />
        </Video>
        {videoControls && (
          <Box
            position="absolute"
            zIndex={3}
            left="0"
            width={1}
            bottom="0"
            p="32px 32px"
          >
            <PlayPauseButton
              play={isPlaying}
              onClick={this.handleClick}
              aria-label="Background video"
              fontSize="subHeadingM" // old "24px"
            />
          </Box>
        )}
      </Box>
    );
  }
}

export const SectionImgDoubleRespVideoSet = (props) => (
  <ResponsiveVideoSet {...props} aspectRatio={[1 / 1, 1 / 1]} />
);
export class SectionHeaderRespVideoSet extends ResponsiveVideoSet {}
export class EditorialImgSingleRespVideoSet extends ResponsiveVideoSet {}
export const EditorialImgDoubleRespVideoSet = (props) => (
  <ResponsiveVideoSet
    {...props}
    // eslint-disable-next-line react/destructuring-assignment
    aspectRatio={props.imageContextWidth === 0.5 ? [7 / 5, 7 / 5] : [null]}
  />
);
export class EditorialContentRespVideoSet extends ResponsiveVideoSet {}
export class ImageCardRespVideoSet extends ResponsiveVideoSet {}
export class ArticleCardRespVideoSet extends ResponsiveVideoSet {}
export class OurLocationRespVideoSet extends ResponsiveVideoSet {}
export class BlogFeaturedCardRespVideoSet extends ResponsiveVideoSet {}
export class BlogCardRespVideoSet extends ResponsiveVideoSet {}
