import React from 'react';
import { Box, Column } from 'design-system';
import { EmphasizedParagraph } from 'components';
import { theme } from '../../design-system/theme';

export const EmphasizedParagraphList = ({
  paragraphs,
  largerTitle = false,
  ...props
}) => (
  <Box
    data-qaid={paragraphs ? 'EmphasizedParagraphList' : null}
    width={paragraphs[0].props.cta ? 1 : [1, 1, 1, 6 / 8]}
    mx={0}
    display="flex"
    flexDirection="row"
    flexWrap="wrap"
    {...props}
  >
    {paragraphs &&
      paragraphs.map((paragraph, index, fullList) => {
        const isLastItem = index === fullList.length - 1;
        const isSecondLastItemOfEvenNumberedList =
          fullList.length % 2 === 0 && index === fullList.length - 2;
        const isSecondLastItem = index === fullList.length - 2;
        const isThirdLastItem = index === fullList.length - 3;
        const margin = theme.verticalSpace.large;
        const noMargin = '0px';
        const mobileMarginBottom = isLastItem ? noMargin : margin;
        const width = paragraph.props.cta
          ? [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 3]
          : [1, 1 / 2];
        const marginBottom =
          isLastItem || isSecondLastItemOfEvenNumberedList ? noMargin : margin;
        const marginFor3Column =
          isLastItem || isSecondLastItem || isThirdLastItem ? noMargin : margin;
        return (
          <Column
            // TODO: get a real key in here
            // eslint-disable-next-line react/no-array-index-key
            key={`paragraph-${index}`}
            width={width}
            data-qaid="Column"
            mb={[
              mobileMarginBottom,
              marginBottom,
              marginBottom,
              marginBottom,
              marginBottom,
              marginFor3Column,
            ]}
          >
            <EmphasizedParagraph
              {...paragraph.props}
              largerTitle={largerTitle}
            />
          </Column>
        );
      })}
  </Box>
);
