import styled from 'styled-components';
import system from 'system-components';

import { layout, grid } from 'styled-system-next';

const BaseBox = system(
  // core
  'space',
  'color',
  'fontSize',
  // typography
  'textAlign',
  'lineHeight',
  // borders
  'borders',
  'borderLeft',
  'borderColor',
  'borderRadius',
  // flexbox
  'alignItems',
  'alignContent',
  'justifyContent',
  'flexWrap',
  'flexDirection',
  'flex',
  'flexBasis',
  'justifySelf',
  'alignSelf',
  'order',
  // position
  'position',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',
  // background
  'background',
  'backgroundImage',
  'backgroundSize',
  'backgroundPosition',
  'backgroundRepeat',
  // variants
  'colorStyle',
  'hover',
  'focus',
  'opacity',
);

export const Box = styled(BaseBox)`
  ${layout}
  ${grid}
`;

Box.displayName = 'Box';
