import React from 'react';
import { getImageURL } from 'utils';
import { PageHelmet } from 'components';

export const pageSectionsMapBuilder = (pageSectionNames, pageDataProps) =>
  pageSectionNames.reduce((carriedMap, pageSection) => {
    const pageSectionData = Object.entries(pageDataProps)
      .filter(([dataKey]) => dataKey.includes(`${pageSection}_`))
      .reduce((sectionData, [dataKey, data]) => {
        const res = sectionData;
        const shortDataName = dataKey.replace(`${pageSection}_`, '');
        res[shortDataName] = data;
        return res;
      }, {});

    return {
      ...carriedMap,
      [pageSection]: pageSectionData,
    };
  }, {});

export const renderPageSections = (
  propsMap,
  pageTemplateSectionSelector,
  extraFilter = () => true,
) =>
  Object.entries(propsMap)
    .filter(([key, { Showhide: showHide }]) => showHide && key !== 'opening')
    .filter(extraFilter)
    .map(([key, props]) => {
      const Component = pageTemplateSectionSelector(key);
      return (
        <div key={key} id={key}>
          <Component data={props} />
        </div>
      );
    });

export const buildPageTemplateHelmet = ({
  title,
  description,
  metaImage,
  language = 'en',
}) => {
  const imageUrl = getImageURL(metaImage);

  return (
    <PageHelmet
      language={language}
      title={title}
      description={description}
      image={imageUrl}
    />
  );
};
