import React from 'react';
import { Box } from 'design-system';

/**
 * Honeypot Field - Anti-Spam measure
 * `label` should only be set for testing purposes
 * @see https://help.salesforce.com/articleView?id=pardot_forms_add_honeypot.htm&type=5
 */
export const PardotFormFieldHoneyPot = ({
  visible = false,
  label = null,
  value = undefined,
}) => {
  const position = visible === true ? '' : 'absolute';
  return (
    <Box
      key="honeypot"
      css="
        top: -99999em;
        left: -99999em;
      "
      mt="16px"
      position={position}
    >
      {/* eslint-disable jsx-a11y/label-has-for */}
      <label htmlFor="pardot_extra_field">{label}</label>
      {/* eslint-enable jsx-a11y/label-has-for */}
      <input
        type="text"
        id="pardot_extra_field"
        defaultValue={value}
        tabIndex={-1}
        name="pardot_extra_field"
        aria-hidden="true"
      />
    </Box>
  );
};
PardotFormFieldHoneyPot.displayName = 'PardotFormFieldHoneyPot';
