import React from 'react';
import _get from 'lodash/get';

import { withTrackingValues } from 'utils';
import { Box } from 'design-system';

export const PardotFormFieldHidden = withTrackingValues(
  ({ trackingValues, trackingParamType, ...props }) => (
    <Box
      css="
        top: -99999em;
        left: -99999em;
      "
      position="absolute"
    >
      <input
        aria-hidden="true"
        {...props}
        name={trackingParamType}
        value={_get(trackingValues, trackingParamType, '')}
      />
    </Box>
  ),
);
