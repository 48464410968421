import React from 'react';
import { Column, Row } from 'design-system';
import { horizontalSpace } from '../../design-system/theme';
import { camelize } from '../patterns';

export const ClientLogoGrid = ({ clientLogo, paddingH = 'none' }) => (
  <Row px={paddingH === 'none' ? 'none' : horizontalSpace[camelize(paddingH)]}>
    <Column
      width={1}
      display="flex"
      flexWrap="wrap"
      alignContent="flex-start"
      px="none"
    >
      {React.Children.map(clientLogo, (eachLogo, index) => {
        const randomDelay = (Math.random() * (0.7 - 0.1) + 0.1).toFixed(2);
        return React.cloneElement(eachLogo, {
          index: index + 1,
          total: clientLogo.length,
          // TODO: get a real key in here
          // eslint-disable-next-line react/no-array-index-key
          key: index,
          revealDelay: randomDelay,
        });
      })}
    </Column>
  </Row>
);
