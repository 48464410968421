import { util } from 'styled-system';
import PropTypes from 'prop-types';

export const pseudoStyleWithArg = ({
  prop,
  alias,
  pseudoClass: pseudoClassProp,
  keys = {},
  getters = {},
  numberToPx = {},
  arg: argProp = null,
}) => {
  const fn = (props) => {
    const styleDesc = props[prop] || props[alias] || {};
    const pseudoClass = pseudoClassProp || prop;

    // const th = util.fallbackTheme(props);

    if (argProp && styleDesc) {
      return { [`&:${pseudoClass}(${argProp})`]: styleDesc };
    }

    const finalStyles = Object.keys(styleDesc).reduce((styles, arg) => {
      const style = styleDesc[arg];

      /* eslint-disable no-restricted-syntax, no-continue */
      for (const key in style) {
        if (Object.prototype.hasOwnProperty.call(style, key)) {
          const toPx = numberToPx[key];
          if (!keys[key] && !getters[key] && !toPx) continue;
          const themeKey = [keys[key], style[key]].join('.');
          style[key] = util.getValue(
            util.get(themeKey, style[key]),
            getters[key],
            toPx,
          );
        }
      }
      /* eslint-enable no-restricted-syntax, no-continue */

      // eslint-disable-next-line no-param-reassign
      styles[`&:${pseudoClass}(${arg})`] = style;

      return styles;
    }, {});

    return finalStyles;
  };

  fn.propTypes = {
    [prop]: util.cloneFunc(PropTypes.object),
  };
  fn.propTypes[prop].meta = {
    prop,
    pseudo: true,
    styleType: 'pseudo',
  };
  return fn;
};
