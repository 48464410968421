import React from 'react';

import { Wrapper } from 'design-system';
import { FullTextMarkdown } from 'components';

export const PrivacyPolicy = ({
  entryId,
  buildContent,
  //
  privacyPolicyText,
  ...props
}) => {
  const QAID = { 'data-qaid': privacyPolicyText ? 'PrivacyPolicy' : null };
  return (
    <Wrapper id={entryId} width={[1, 5 / 6, 7 / 8, 1 / 2]} {...props} {...QAID}>
      <FullTextMarkdown>{privacyPolicyText}</FullTextMarkdown>
    </Wrapper>
  );
};
