import React from 'react';
import {
  SectionHeader,
  IconLink,
  SectionHeaderRespImgSet,
  ImageCard,
  ImageCardRespImgSet,
  LargeHeading,
  PodcastEmbed,
} from 'components';
import { buildComponent } from 'utils';
import { theme, Box } from 'design-system';

export const CopyMediaGroup = ({
  layoutMode = 'split',
  heading,
  headingVariant = 'medium',
  cta,
  media,
  paragraph,
  podcastEmbedCode,
  avatar,
  avatarAltText: avatarAltTextProp,
  mediaComponent,
  videoPlayer,
  hideMediaColumn = false,
  ...rest
}) => {
  let mediaProps = {};
  if (media) {
    mediaProps = { image: <SectionHeaderRespImgSet {...media.props} /> };
  } else if (podcastEmbedCode) {
    mediaProps = {
      embedCode: <PodcastEmbed embedCode={podcastEmbedCode} />,
    };
  } else if (mediaComponent) {
    mediaProps = {
      embedCode: mediaComponent,
    };
  } else if (videoPlayer) {
    mediaProps = {
      embedCode: buildComponent(videoPlayer),
    };
  }

  let avatarProps = {};
  if (avatar) {
    avatarProps = {
      imageAboveTitle: avatar,
      imageAboveTitleAltText: avatarAltTextProp || avatar.title,
    };
  }

  if (layoutMode === 'stacked') {
    return (
      <ImageCard
        heading={heading}
        paragraphText={paragraph}
        image={<ImageCardRespImgSet {...media.props} />}
        cta={cta ? <IconLink {...cta.props} /> : null}
        {...rest}
      />
    );
  }
  return (
    <>
      {headingVariant === 'large' && heading ? (
        <Box px={theme.gutters}>
          <LargeHeading text={heading} />
        </Box>
      ) : null}
      <SectionHeader
        title={headingVariant === 'medium' && heading ? heading : null}
        paragraphText={paragraph}
        ctaSection={cta ? <IconLink {...cta.props} /> : null}
        useFullTextMarkdown
        hideMediaColumn={hideMediaColumn}
        {...mediaProps}
        {...avatarProps}
        {...rest}
      />
    </>
  );
};
