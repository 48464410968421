import { withStyles, Text } from 'design-system';

export const FieldErrorLabel = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      ...theme.typography[themeType].formFieldError,
      pt: '5px',
    },
  }),
  Text,
);
