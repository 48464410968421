import React from 'react';

const withNav = (Wrapped) =>
  class ComposedNavState extends React.Component {
    state = {
      // Possible values: undefined, 'default', 'light', 'dark'
      topBlockTheme: 'default',
      pageTheme: undefined,
    };

    updateNavThemes = (state) => {
      this.setState(state);
    };

    render() {
      const { topBlockTheme, pageTheme } = this.state;

      const additionalProps = {
        topBlockTheme,
        pageTheme,
        updateNavThemes: this.updateNavThemes,
      };
      return <Wrapped {...this.props} {...additionalProps} />;
    }
  };

const NavContext = React.createContext();

export const NavProvider = withNav(
  ({ topBlockTheme, updateNavThemes, pageTheme, children }) => (
    <NavContext.Provider value={{ topBlockTheme, updateNavThemes, pageTheme }}>
      {children}
    </NavContext.Provider>
  ),
);

export const withNavState = (Wrapped) => (wrappedProps) => (
  <NavContext.Consumer>
    {(navState) => <Wrapped {...wrappedProps} {...navState} />}
  </NavContext.Consumer>
);
