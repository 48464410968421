import React from 'react';
import { Box } from 'design-system';

export const SmallCTA = ({
  darkBackground,
  iconLink,
  /* eslint-disable  */
  color = (color = darkBackground
    ? 'darkBackgroundCTAs'
    : 'lightBackgroundCTAs'),
  /* eslint-enable  */
  ...props
}) => (
  <Box
    fontFamily="regular"
    fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
    // old [2, 2, 2, 3]
    lineHeight={[4, 4, 4, 6]}
    {...props}
  >
    {React.Children.map(iconLink, (child) =>
      React.cloneElement(child, { colorStyle: color, fontFamily: 'regular' }),
    )}
  </Box>
);
