/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Box, horizontalSpace } from 'design-system';

// Algolia
import { Index, connectStateResults } from 'react-instantsearch-dom';

import { Button } from '../Button';
import { CustomInfiniteHits } from './InfiniteHits';

const prefix = process.env.GATSBY_ALGOLIA_INDEX_PREFIX || '';

const Indices = ({ categories, searchResults, setSearchResults }) => {
  const indices = categories;

  const filterBlogs = () => {
    setSearchResults(`${prefix}Blog`);
  };

  const filterPodcasts = () => {
    setSearchResults(`${prefix}Podcast`);
  };

  // const filterCareers = () => {
  //   setSearchResults(`${prefix}Career`);
  // };

  const filterPages = () => {
    setSearchResults(`${prefix}Page`);
  };

  const filterAll = () => {
    setSearchResults('All');
  };
  return (
    <Box>
      <Box>
        <Button
          mr={horizontalSpace.xSmall}
          color="white"
          onClick={() => filterAll()}
        >
          All
        </Button>
        <Button
          mr={horizontalSpace.xSmall}
          color="white"
          onClick={() => filterPages()}
        >
          Pages
        </Button>
        <Button
          mr={horizontalSpace.xSmall}
          color="white"
          onClick={() => filterBlogs()}
        >
          Articles
        </Button>
        <Button
          mr={horizontalSpace.xSmall}
          color="white"
          onClick={() => filterPodcasts()}
        >
          Podcasts
        </Button>
        {/* <Button
          mr={horizontalSpace.xSmall}
          color="white"
          onClick={() => filterCareers()}
        >
          Careers
        </Button> */}
      </Box>
      {indices.map((index) => (
        <Index key={`${index.toLowerCase()}-index`} indexName={`${index}s`}>
          <CustomInfiniteHits index={index} />
        </Index>
      ))}
    </Box>
  );
};

// If search field is empty, user should see suggested pages
const ConditionalContent = ({ searchState, isDesktop, toggleDisplay }) => {
  const [searchResults, setSearchResults] = useState('All');

  if (searchState && searchState.query) {
    toggleDisplay(true);
    if (searchResults === 'All') {
      return (
        <Indices
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          categories={[
            `${prefix}Page`,
            `${prefix}Blog`,
            `${prefix}Podcast`,
            // `${prefix}Career`,
          ]}
        />
      );
    }
    if (searchResults === `${prefix}Page`) {
      return (
        <Indices
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          categories={[`${prefix}Page`]}
        />
      );
    }
    if (searchResults === `${prefix}Blog`) {
      return (
        <Indices
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          categories={[`${prefix}Blog`]}
        />
      );
    }
    if (searchResults === `${prefix}Podcast`) {
      return (
        <Indices
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          categories={[`${prefix}Podcast`]}
        />
      );
    }
    if (searchResults === `${prefix}Career`) {
      return (
        <Indices
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          categories={[`${prefix}Career`]}
        />
      );
    }
  }

  toggleDisplay(false);

  return <></>;
};

export const ConditionalContentDisplay = connectStateResults(
  ConditionalContent,
);
