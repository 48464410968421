import React from 'react';
import Sticky from 'react-sticky-el';
import { withTheme } from 'styled-components';
import { getWindowWidth } from '../../utils';

export const withSticky = (Component) => {
  class WithSticky extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        stickyEnabled: false,
      };
      this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('orientationchange', this.handleResize);
      this.handleResize();

      // Note(bertrandk): `position: fixed` doesn't work when one of its parent
      //                  elements uses CSS transform (like PageTransition)
      if (window.pageTransitionsOn) {
        const target = document.querySelector('[data-page]').firstElementChild;
        const observerConfig = {
          attributes: true,
          attributeFilter: ['style'],
        };
        let transitionCount = 0;

        const observer = new MutationObserver(function(mutations) {
          mutations.forEach(function(mutation) {
            if (mutation.attributeName === 'style') {
              transitionCount++;

              // Wait for PageTransition to end. There are 3 transitions in
              // total.
              if (transitionCount === 2) {
                target.style.transform = '';
                observer.disconnect();
              }
            }
          });
        });

        observer.observe(target, observerConfig);
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('orientationchange', this.handleResize);
    }

    getBreakpointIndex(bpValue, arr) {
      const {
        theme: { breakpoints },
      } = this.props;
      const idx = Math.max(
        0,
        Math.min(breakpoints.indexOf(bpValue) + 1 || 0, arr.length - 1),
      );
      return idx; // +1 - adjust to account for mobile responsiveStyles in styled-system
    }

    calculateCurrentBreakpoint(screenWidth) {
      const { theme } = this.props;
      const bps = [undefined, ...theme.rawBreakpoints];
      const bpValue = [...bps]
        .reverse()
        .reduce(
          (result, bp) => (!result && screenWidth >= bp ? bp : result),
          null,
        );

      return bpValue;
    }

    handleResize() {
      const { sticky: responsiveValues } = this.props;

      if (!Array.isArray(responsiveValues)) return;

      const screenWidth = getWindowWidth();
      const currentBreakpoint = this.calculateCurrentBreakpoint(screenWidth);
      const responsiveIndex = this.getBreakpointIndex(
        currentBreakpoint,
        responsiveValues,
      );

      this.setState({
        stickyEnabled: responsiveValues[responsiveIndex],
      });
    }

    render() {
      const {
        theme,
        // sticky: isSticky = false,
        stickyProps: stickyPropsProp = {},
        ...componentProps
      } = this.props;

      const { stickyEnabled } = this.state;

      const stickyProps = {
        ...stickyPropsProp,
        disabled:
          stickyPropsProp.disabled !== undefined
            ? stickyPropsProp.disabled
            : !stickyEnabled,
        hideOnBoundaryHit:
          stickyPropsProp.hideOnBoundaryHit !== undefined
            ? stickyPropsProp.hideOnBoundaryHit
            : false,
      };

      return (
        <Sticky {...stickyProps}>
          <Component {...componentProps} />
        </Sticky>
      );
    }
  }
  return withTheme(WithSticky);
};
