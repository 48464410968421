import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import system from 'system-components';
import { textDecoration } from '../styles';

const filterBlacklistProps = ({ blacklist, ...props }) =>
  Object.keys(props)
    .filter((key) => !blacklist.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: props[key] }), {});
// This is an internal helper function to use GatsbyLink for internal links
const GatsbyLinkHelper = ({
  is,
  href,
  isInternal: forcedInternal = null,
  children,
  innerRef,
  target: hasTarget,
  ...props
}) => {
  const filteredProps = filterBlacklistProps(props);

  // Considers links beginning with a single slash to be internal links.
  const isInternal =
    typeof forcedInternal === 'boolean'
      ? forcedInternal
      : /^\/(?!\/)/.test(href);

  // Better RegEx to test against all Rangle subdomains.
  const isRangleSubdomain =
    /^https?:\/\/(.*\.)?rangle\.io\/?.*$/i.test(href) || /^#.*/.test(href);

  // _self is the default, and only overridden if explicitly set, or isInternal == false, or href is Rangle's blog
  const target =
    hasTarget || (isRangleSubdomain || !!isInternal ? null : '_blank');

  return isInternal ? (
    <GatsbyLink
      to={href}
      innerRef={innerRef}
      target={target}
      {...filteredProps}
    >
      {children}
    </GatsbyLink>
  ) : (
    <a
      href={href}
      ref={innerRef}
      {...filteredProps}
      target={target}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export const Link = system(
  {
    is: GatsbyLinkHelper,
    fontSize: 'bodyL', // old 3
    colors: 'lightBackgroundHoverText',
    fontFamily: 'light',
    blacklist: ['textDecoration'],
  },
  // core
  'display',
  'space',
  'width',
  'background',
  'color',
  'fontSize',
  'height',
  // typography
  'fontFamily',
  'textAlign',
  'letterSpacing',
  'lineHeight',
  // states
  'hover',
  'focus',
  // variants
  'colorStyle',
  'boxShadow',
  'maxHeight',
  'position',
  'top',
  'zIndex',
  'left',
  textDecoration,
);
Link.displayName = 'Link';
