import React, { Component } from 'react';
import { Box, Column, Row, theme } from 'design-system';

// 50% width for both images
// On mobile the 2nd image is omitted

export class SectionImgDouble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: undefined,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    });

  render() {
    const { imageSets, ...props } = this.props;
    const { windowWidth } = this.state;
    const imageContextWidth = 0.5;
    const imageContextGutterCount = 1;

    let imgLeftWithContext = null;
    let imgRightWithContext = null;
    let qaid = false;

    if (imageSets !== undefined && imageSets != null) {
      qaid = true;
      if (imageSets[0] !== undefined && imageSets[0] != null) {
        imgLeftWithContext = React.cloneElement(imageSets[0], {
          imageContextWidth,
          imageContextGutterCount,
        });
      }

      if (imageSets[1] !== undefined && imageSets[1] != null) {
        imgRightWithContext = React.cloneElement(imageSets[1], {
          imageContextWidth,
          imageContextGutterCount,
        });
      }
    }

    let imagesLayout;

    if (windowWidth < theme.rawBreakpoints[0]) {
      imagesLayout = (
        <Row>
          <Column width={1}>{imgLeftWithContext}</Column>
        </Row>
      );
    } else if (windowWidth >= theme.rawBreakpoints[0]) {
      imagesLayout = (
        <Row>
          <Column width={[1, 1 / 2]}>{imgLeftWithContext}</Column>
          <Column width={[1, 1 / 2]}>{imgRightWithContext}</Column>
        </Row>
      );
    }

    return (
      <Box width={1} data-qaid={qaid ? 'SectionImgDouble' : null} {...props}>
        {imagesLayout}
      </Box>
    );
  }
}
