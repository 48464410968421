import React from 'react';
import { Box, Column, Row, theme } from 'design-system';
import { LargeCTA, TextEditorial } from 'components';

export const EditorialContent = ({
  editorialText,
  textPosition = 'Left',
  ctaSection,
  image,
  ...props
}) => {
  /* eslint-disable no-nested-ternary */
  const tabletWidthLeft = image
    ? [1, 1 / 2]
    : textPosition === 'Left'
    ? [1, 3 / 4]
    : [1, 1 / 4];
  const tabletWidthRight = image
    ? [1, 1 / 2]
    : textPosition === 'Right'
    ? [1, 3 / 4]
    : [1, 1 / 4];
  /* eslint-enable no-nested-ternary */

  let imageWithContext = null;

  if (image !== undefined && image !== null) {
    imageWithContext = React.cloneElement(image, {
      imageContextWidth: 0.5,
      imageContextGutterCount: 1,
    });
  }

  const spacingBelowText = ctaSection;

  const content = (
    <Box width={1}>
      {editorialText ? (
        <TextEditorial
          spacingBelowText={spacingBelowText}
          text={editorialText}
        />
      ) : null}
      {ctaSection ? (
        <LargeCTA darkBackground={false} iconLink={ctaSection} />
      ) : null}
    </Box>
  );

  const mobileLayout = (
    <Box width={1}>
      {imageWithContext ? (
        <Column width={1} pb={theme.verticalSpace.small}>
          {/*  Image goes here if it exists */}
          {imageWithContext}
        </Column>
      ) : null}

      {content ? (
        <Column width={1}>
          {/* Text Content Always go here */}
          {content}
        </Column>
      ) : null}
    </Box>
  );

  const desktopLayout = (
    <Box display="flex" flexDirection="row" width={1}>
      <Column width={tabletWidthLeft}>
        {textPosition === 'Left' ? content : imageWithContext}
      </Column>

      <Column width={tabletWidthRight}>
        {textPosition === 'Right' ? content : imageWithContext}
      </Column>
    </Box>
  );

  return (
    <Row
      flexDirection="row"
      data-qaid={editorialText ? 'EditorialText' : null}
      {...props}
    >
      <Box display={['flex', 'none']} width={1}>
        {mobileLayout}
      </Box>

      <Box display={['none', 'flex']} width={1}>
        {desktopLayout}
      </Box>
    </Row>
  );
};
