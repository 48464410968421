import React from 'react';
import { breakpoints } from 'design-system';

export function getWindowWidth() {
  if (typeof window !== 'undefined') {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
  return false;
}

export function isMobile(windowWidthParam) {
  const windowWidth = windowWidthParam || getWindowWidth();
  return windowWidth < breakpoints[0];
}

export function isTablet(windowWidthParam) {
  const windowWidth = windowWidthParam || getWindowWidth();
  return windowWidth >= breakpoints[0] && windowWidth < breakpoints[1];
}

export function isDesktop(windowWidthParam) {
  const windowWidth = windowWidthParam || getWindowWidth();
  return windowWidth >= breakpoints[1];
}

export function getActiveBreakpoint(windowWidth) {
  let activeBreakpoint;
  if (isMobile(windowWidth)) {
    activeBreakpoint = 'mobile';
  } else if (isTablet(windowWidth)) {
    activeBreakpoint = 'tablet';
  } else if (isDesktop(windowWidth)) {
    activeBreakpoint = 'desktop';
  }
  return activeBreakpoint;
}

export const withResponsive = (Wrapped) =>
  class ComposedResponsive extends React.Component {
    state = {
      windowWidth: 0,
    };

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (event) => {
      this.setState({
        windowWidth: event ? event.target.innerWidth : window.innerWidth,
      });
    };

    render() {
      const { windowWidth } = this.state;

      const additionalProps = {
        windowWidth,
        activeBreakpoint: getActiveBreakpoint(windowWidth),
      };
      return <Wrapped {...this.props} {...additionalProps} />;
    }
  };

const ResponsiveContext = React.createContext({
  windowWidth: null,
  activeBreakpoint: 'mobile',
});

export const ResponsiveProvider = withResponsive(
  ({ windowWidth, activeBreakpoint, children }) => (
    <ResponsiveContext.Provider value={{ windowWidth, activeBreakpoint }}>
      {children}
    </ResponsiveContext.Provider>
  ),
);

export const withWindowWidth = (Wrapped) => (wrappedProps) => (
  <ResponsiveContext.Consumer>
    {(responsiveContextValue) => (
      <Wrapped {...wrappedProps} {...responsiveContextValue} />
    )}
  </ResponsiveContext.Consumer>
);
