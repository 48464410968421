import React from 'react';
import { Image } from 'design-system';

export const IconImg = ({ image, altText, widthInPixels }) => (
  <Image
    src={
      image && image.props && image.props.file && image.props.file.url
        ? image.props.file.url
        : null
    }
    alt={altText}
    data-qaid={
      altText &&
      image &&
      image.props &&
      image.props.file &&
      image.props.file.url
        ? 'iconImage'
        : null
    }
    width={Array.isArray(widthInPixels) ? widthInPixels : `${widthInPixels}px`}
  />
);
