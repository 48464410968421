import React from 'react';
import { Link } from 'design-system';

export const ListLinkCTA = ({ label, newtab, ariaLabel, url }) => {
  const target = newtab ? `target='_blank'` : null;

  return (
    <Link
      fontSize="bodyS" // old {1}
      lineHeight="28px"
      href={url}
      aria-label={ariaLabel}
      {...target}
      data-qaid={label && newtab && ariaLabel && url ? 'ListLinkCTA' : null}
      color="ui.2"
      css="text-decoration: none;"
      hover={{
        color: 'btnTextHover.1',
      }}
      focus={{
        color: 'btnTextHover.1',
        outline: 'none',
      }}
    >
      {label}
    </Link>
  );
};
