import React from 'react';
import system from 'system-components';
import { Icon } from './Icon';
import { textDecoration, withStyles } from '../styles';
import { OverflowLabel } from './OverflowLabel';
import { isType } from '../../utils';

export const NoTextButton = system(
  {
    is: 'button',
    p: 0,
    bg: 'transparent',
  },
  'borderColor',
  'height',
);

export const Button = system(
  {
    is: 'button',
    fontSize: 'bodyM',
    color: 'inherit',
    fontFamily: 'light',
    border: '0',
    p: 0,
    bg: 'transparent',
    blacklist: ['textDecoration', 'cursor'],
  },
  // core
  'display',
  'space',
  'width',
  'height',
  'background',
  'color',
  'fontSize',
  'borderColor',
  'position',
  'top',
  'left',
  'bottom',
  'right',
  // typography
  'fontFamily',
  'textAlign',
  'lineHeight',
  'letterSpacing',
  // flexbox
  'alignItems',
  'alignContent',
  'justifyContent',
  'flexWrap',
  'flexDirection',
  'flex',
  'flexBasis',
  'justifySelf',
  'alignSelf',
  'order',
  'zIndex',
  // variants
  'hover',
  'colorStyle',
  'boxShadow',
  'focus',
  ({ cursor }) => ({ cursor: cursor || null }),
  textDecoration,
);
Button.displayName = 'Button';

const ButtonVariant = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      colors: props.colors || `${themeType}BackgroundCTAs`,
      display: props.ellipsis ? 'inline-flex' : null,
      width: props.ellipsis ? '100%' : 'auto',
      fontSize: 'inherit',
      cursor: props.disabled || props.ignorePointer ? 'default' : 'pointer',
    },
    contained: {
      colors: props.colors || `${themeType}BackgroundCTAs`,
      bg: themeType === 'dark' ? 'bg.0' : 'bg.1',
      p: ['18px', '18px', '20px'],
      width: '100%',
      textAlign: 'center',
      display: 'block',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      cursor: props.disabled || props.ignorePointer ? 'default' : 'pointer',
    },
    fab: {
      color: 'ui.3',
      bg: 'transparent',
      p: 0,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      cursor: props.disabled || props.ignorePointer ? 'default' : 'pointer',
    },
    ribbon: {
      color: 'ui.3',
      bg: 'transparent',
      p: 0,
      textAlign: 'center',
      display: 'inline',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      cursor: props.disabled || props.ignorePointer ? 'default' : 'pointer',
    },
  }),
  Button,
);
ButtonVariant.displayName = 'ButtonVariant';

export const IconButton = ({
  colorStyle,
  colors: colorsProp,
  icon = null, // IconButton does not have a Contentful model, but here to mimic IconLink (deprecated)
  iconBefore = null,
  iconAfter = null,
  children,
  iconBeforeProps = { mr: 1 },
  iconAfterProps = { ml: 2 },
  ellipsis = false,
  display,
  ...props
}) => {
  /*
     NOTE:  Passing glyphs will be deprecated in favour of passing
            a declarative <Icon /> component. This will allow the dev
            to pass another form of icon (IconImg, SVGIcon, etc).

            The `icon` prop will be left 'as is' for backwards
            compatability as this may be passed from Contentful to
            override a glyph.
  */
  // Test if <Icon> is being passed instead of just a glyph
  /* eslint-disable no-param-reassign */
  if (iconBefore && !isType(iconBefore, 'Icon')) {
    iconBefore = <Icon {...iconBeforeProps}>{iconBefore}</Icon>;
  }
  /* eslint-enable no-param-reassign */
  // The `icon` prop is always a glyph, so a type-check need only be done for iconAfter
  /* eslint-disable no-param-reassign */
  if ((icon || iconAfter) && !isType(iconAfter, 'Icon')) {
    iconAfter = <Icon {...iconAfterProps}>{iconAfter || icon}</Icon>;
  }
  /* eslint-enable no-param-reassign */

  // for overriding colours (should be rare)
  const colors =
    !!colorStyle || !!colorsProp ? { colors: colorStyle || colorsProp } : null;

  return (
    <ButtonVariant {...colors} ellipsis={ellipsis} {...props}>
      {iconBefore}
      <OverflowLabel className="label" ellipsis={ellipsis}>
        {children}
      </OverflowLabel>
      {iconAfter}
    </ButtonVariant>
  );
};
