import React from 'react';
import { theme, Row, Column, Box, horizontalSpace } from 'design-system';

// Double Image
// 3 layouts: Equal Width (default), Left Prioritized, Right Prioritized

export const EditorialImgDouble = ({ imageSets, layoutOption, ...props }) => {
  // Equal is default
  let layoutLeft = 1;
  let layoutRight = 1;
  let imageLeftContextWidth = 0.5;
  let imageRightContextWidth = 0.5;
  const imageContextGutterCount = 1;

  if (layoutOption === 'Equal Width') {
    layoutLeft = 1;
    layoutRight = 1;
    imageLeftContextWidth = 0.5;
    imageRightContextWidth = 0.5;
  } else if (layoutOption === 'Left Prioritized') {
    layoutLeft = 1;
    layoutRight = 3 / 4;
    imageLeftContextWidth = 0.5;
    imageRightContextWidth = 0.375;
  } else if (layoutOption === 'Right Prioritized') {
    layoutLeft = 3 / 4;
    layoutRight = 1;
    imageLeftContextWidth = 0.375;
    imageRightContextWidth = 0.5;
  }

  let imgLeftWithContext = null;
  let imgRightWithContext = null;
  let qaid = false;
  let imgRight = true;

  if (imageSets !== undefined && imageSets !== null) {
    qaid = true;
    if (imageSets[0]) {
      imgLeftWithContext = React.cloneElement(imageSets[0], {
        imageContextWidth: imageLeftContextWidth,
        imageContextGutterCount,
      });
    }
    if (imageSets[1]) {
      imgRightWithContext = React.cloneElement(imageSets[1], {
        imageContextWidth: imageRightContextWidth,
        imageContextGutterCount,
      });
    } else {
      imgRight = false;
    }
  }

  if (layoutOption === 'Right Prioritized' && !imgRight) {
    imgRightWithContext = imgLeftWithContext;
    imgLeftWithContext = null;
  }

  return (
    <Row
      data-qaid={qaid ? 'EditorialImgDouble' : null}
      {...props}
      px={horizontalSpace.layout}
    >
      <Column
        width={[1, 1 / 2]}
        alignItems="center"
        justifyContent="center"
        m="auto"
        pb={[theme.verticalSpace.medium, 0]}
        px="0"
      >
        <Box width={layoutLeft} m="auto">
          {imgLeftWithContext}
        </Box>
      </Column>
      <Column
        width={[1, 1 / 2]}
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Box width={layoutRight} m="auto">
          {imgRightWithContext}
        </Box>
      </Column>
    </Row>
  );
};
