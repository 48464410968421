import React, { useRef, useState, useEffect } from 'react';
import {
  useTransform,
  useSpring,
  motion,
  useViewportScroll,
} from 'framer-motion';

import { springConfig } from '../configs/animation-spring-config';

export const ParallaxImageWrapper = ({
  children,
  topOffset = -350,
  bottomOffset = 350,
  style,
  ...props
}) => {
  const ref = useRef();
  const [elementTop, setElementTop] = useState(0);
  const { scrollY } = props;
  const motionScrollY = scrollY || useViewportScroll().scrollY;

  const onResize = () => {
    setElementTop(ref.current.offsetTop);
  };

  useEffect(() => {
    if (!ref.current) return;
    onResize();
    window.addEventListener('resize', onResize);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', onResize);
  }, [ref]);

  const y = useSpring(
    useTransform(
      motionScrollY,
      [elementTop + topOffset, elementTop + bottomOffset],
      ['0%', '-10%'],
    ),
    springConfig,
  );

  return (
    <motion.div ref={ref} initial={{ y: 0 }} style={{ y, ...style }}>
      {children}
    </motion.div>
  );
};
