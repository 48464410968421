import React from 'react';
import { Heading } from 'design-system';

const variantPropsDict = {
  default: {
    fontSize: ['subHeadingXl', 'headingS', 'headingM', '72px'],
    // old [5, 6, 7, 8]
    lineHeight: [7, 9, 9, 11],
  },
  smaller: {
    fontSize: ['subHeadingM', 'subHeadingXl', 'subHeadingXl', 'headingS'],
    // old [4, 5, 5, 6]
    lineHeight: [15],
  },
};

export const LargeHeading = ({ text, variant = 'default', ...props }) => (
  <Heading
    is="h1"
    {...variantPropsDict[variant]}
    m={0}
    data-qaid={text ? 'largeHeading' : null}
    {...props}
  >
    {text}
  </Heading>
);
