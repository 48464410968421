import React from 'react';
import { theme, Text } from 'design-system';
import { withWindowWidth } from '../../../utils';

const fontSetting = ['bodyL', 'bodyL', 'bodyL', 'headingS'];
// old [3, 3, 3, 6]

const BaseQuoteBody = ({ text, windowWidth, ...props }) => (
  <Text
    is="blockquote"
    fontSize={fontSetting}
    lineHeight={[6, 6, 6, 8]}
    color="text.0"
    m="0"
    pb={theme.verticalSpace.medium}
    css="-webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-margin-start: 0; webkit-margin-end: 0;"
    data-qaid={text ? 'QuoteBody' : null}
    {...props}
  >
    <span
      style={{
        display: 'block',
        position: 'absolute',
        marginLeft: windowWidth >= theme.rawBreakpoints[2] ? '-25px' : '-14px',
      }}
    >
      “
    </span>
    {text}”
  </Text>
);

export const QuoteBody = withWindowWidth(BaseQuoteBody);
