import React from 'react';
import { theme, Box, Link, Image } from 'design-system';
import { getImageURL } from 'utils';
import { themeGet } from 'styled-system';

export class ClientLogoCard extends React.Component {
  state = {
    hoverState: false,
  };

  // componentDidMount() {
  // const { logoHover } = this.props;
  // new Image().src = getImageURL(logoHover);
  // }

  getBorder = (index, total) => {
    const color = theme.colors.uiDivider;
    const mobileBottomBorders =
      total % 2 === 0 ? index < total - 1 : index < total;
    const desktopBottomBorders =
      total % 4 === 0 ? index < total - 3 : index < total - ((total % 4) - 1);
    const mobile = `
      ${color};
      border-style: solid;
      border-width: 0 ${index % 2 === 0 ? 0 : 1}px ${
      mobileBottomBorders ? 1 : 0
    }px 0;
    `;
    const desktop = `
      ${color};
      border-style: solid;
      border-width: 0 ${index % 4 === 0 ? 0 : 1}px ${
      desktopBottomBorders ? 1 : 0
    }px 0;
    `;
    return [mobile, desktop];
  };

  handleMouseEnter = () => {
    this.setState({ hoverState: true });
  };

  handleMouseLeave = () => {
    this.setState({ hoverState: false });
  };

  render() {
    const {
      logo,
      altText,
      logoHover,
      linkUrl,
      index,
      total,
      ariaLabel,
      title,
      revealDelay = 0,
    } = this.props;
    const changeOnHover = !!(logoHover && linkUrl);
    const LinkOrBox = linkUrl ? Link : Box;
    const { hoverState } = this.state;
    const easeShort = themeGet(
      'transitions.easeOut.short',
      '0.3s ease-out',
    )(this.props);

    return (
      <Box
        border={this.getBorder(index, total)}
        width={[1 / 2, 1 / 4]}
        onMouseEnter={changeOnHover ? this.handleMouseEnter : null}
        onMouseLeave={changeOnHover ? this.handleMouseLeave : null}
        onFocus={changeOnHover ? this.handleMouseEnter : null}
        onBlur={changeOnHover ? this.handleMouseLeave : null}
        background={hoverState ? theme.colors.brand[0] : null}
        data-qaid={title && altText ? 'ClientCard' : null}
        css={`
          transition: background-color ${easeShort};
        `}
      >
        <LinkOrBox
          href={linkUrl || null}
          aria-label={changeOnHover ? ariaLabel : null}
          focus={{
            outline: 'none',
          }}
        >
          <Box display="flex">
            <Box pt="100%" flex={[1 / 4, 1 / 3]} />
            <Box flex={[1 / 2, 1 / 3]} flexDirection="column" display="flex">
              <Box flex={[1 / 4, 1 / 3]} />
              <Box
                flex={[1 / 2, 1 / 3]}
                display="flex"
                alignItems="center"
                position="relative"
              >
                {!!changeOnHover && (
                  <Box
                    position="absolute"
                    css={`
                      opacity: ${hoverState ? '1' : '0'};
                      transition: opacity ${easeShort};
                    `}
                  >
                    <Image
                      src={`${getImageURL(logoHover)}`}
                      alt={altText}
                      width="100%"
                    />
                  </Box>
                )}
                <Image
                  src={`${getImageURL(logo)}`}
                  alt={altText}
                  width="100%"
                  revealDelay={revealDelay}
                />
              </Box>
              <Box flex={[1 / 4, 1 / 3]} />
            </Box>
            <Box flex={[1 / 4, 1 / 3]} />
          </Box>
        </LinkOrBox>
      </Box>
    );
  }
}
