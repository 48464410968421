import React from 'react';
import { Text } from 'design-system';

export const TextParagraph = ({ text, children, ...props }) => (
  <Text
    is="p"
    fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
    // [2, 2, 2, 3]
    color="text.3"
    lineHeight={[4, 4, 4, 6]}
    m="0"
    data-qaid={text ? 'text' : null}
    css="-webkit-margin-before: 0; margin-before: 0;"
    {...props}
  >
    {text || children}
  </Text>
);
