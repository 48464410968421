import React from 'react';
import { Tweet } from 'react-twitter-widgets';

function onLoadFunction() {
  const style = document.createElement('style');
  style.innerHTML =
    '.PrerenderedCard { display: grid !important; } .EmbeddedTweet { max-width: none !important; }';
  // we need display grid so the tweet will properly resize
  // we neeed max-width none so the tweet will fill up all available space
  const tweets = document.getElementsByClassName('twitter-tweet');
  for (let i = 0; i < tweets.length; i++) {
    if (
      !tweets[i].shadowRoot.innerHTML.includes(
        '.PrerenderedCard { display: grid !important; } .EmbeddedTweet { max-width: none !important; }',
      )
    ) {
      tweets[i].shadowRoot.appendChild(style);
    }
  }
}

export const SocialMediaWidget = ({ tweetId }) => {
  return <Tweet tweetId={tweetId} onLoad={onLoadFunction} />;
};
