export const getStringType = (node) => {
  if (node && typeof node.type === 'string') return node.type;
  if (node && node.type && node.type.displayName) return node.type.displayName;
  return false;
};

export const isType = (node, type) => {
  const stringType = getStringType(node);
  // eslint-disable-next-line eqeqeq
  return stringType && stringType == type;
};
