import React from 'react';
import { SvgIcon, theme } from 'design-system';

const getClassName = (isMinus) => {
  return isMinus ? 'plus_minus_vertical minus' : 'plus_minus_vertical';
};
const getTitle = (isMinus) => {
  return isMinus ? 'Minus' : 'Plus';
};

export const PlusMinusIcon = ({ isMinus, ...props }) => {
  return (
    <SvgIcon {...props} title={getTitle(isMinus)} viewBox="0 0 64 64" ml="4px">
      <style>
        {`
        .plus_minus_vertical {
          transition: transform ${theme.transitions.easeOut.short};
          transform-origin: 50% 50%;
        }
        .plus_minus_vertical.minus {
          transform: rotate(90deg);
        }
      `}
      </style>
      <rect
        x="0"
        y="28"
        width="64"
        height="8"
        style={{ fill: 'currentColor' }}
      />
      <rect
        x="28"
        y="0"
        width="8"
        height="64"
        style={{ fill: 'currentColor' }}
        className={getClassName(isMinus)}
      />
    </SvgIcon>
  );
};
