import React from 'react';

import {
  LinkedInIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  Link,
} from 'components';

const defaultIconComponent = () => <p>No Icon</p>;

export const SocialIcon = ({
  theme,
  platform,
  linkConfig,
  title = `Connect with us on ${platform}`,
  ...props
}) => {
  const IconComponent = (title) => {
    switch (platform) {
      case 'LinkedIn':
        return <LinkedInIcon title={title} />;
      case 'Twitter':
        return <TwitterIcon title={title} />;
      case 'Facebook':
        return <FacebookIcon title={title} />;
      case 'YouTube':
        return <YoutubeIcon title={title} />;
      case 'Instagram':
        return <InstagramIcon title={title} />;
      default:
        return defaultIconComponent;
    }
  };

  return (
    <Link
      theme={theme}
      linkConfig={linkConfig}
      hideArrow
      display="inline-block"
      {...props}
    >
      {IconComponent(`${title}`)}
    </Link>
  );
};
