import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { colorStyle } from 'styled-system-next';
import { Box } from 'design-system/blocks/Box';

export const LinkStyling = styled.a`
  ${colorStyle}
`;

const SpanRenderer = styled.span``;

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: 2em;
`;

export const StyledListItems = styled.li`
  list-style-type: none;
  &::before {
    display: inline-block;
    content: '\\2014';
    width: 1em;
    margin-left: -2em;
    padding-right: 1em;
  }

  &:not(:last-child) {
    padding-bottom: 8px;
  }
`;

const renderers = (theme) => {
  LinkStyling.defaultProps = {
    colors: theme === 'light' ? 'lightInlineLink' : 'darkInlineLink',
  };

  return {
    // link: LinkStyling,
    a: LinkStyling,
    // paragraph: SpanRenderer,
    p: SpanRenderer,
    // list: StyledList,
    ul: StyledList,
    // listItem: StyledListItems,
    li: StyledListItems,
  };
};

export const Markdown = ({ children, width = '100%', theme = 'light' }) => {
  return (
    <Box width={width}>
      <ReactMarkdown
        components={renderers(theme)}
        colors={colorStyle}
      >
        {children}
      </ReactMarkdown>
    </Box>
  );
};
