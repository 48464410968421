export function getImageURL(image) {
  return image?.props?.file?.url || image?.props?.src || null;
}

export function urlToImage(url) {
  return { props: { file: { url } } };
}

export function getPlaceholderImage(
  width,
  height,
  { text, color = 'ffffff', bkgColor = 'aaaaaa' },
) {
  // Append a character to avoid triggering ad blocking filters for certain dimensions like /300x250/
  const size = `${width}x${height}x`;
  const t = text
    ? encodeURIComponent(text).replace(/%20/g, '+')
    : `${width}x${height}`;
  const url = `https://via.placeholder.com/${size}/${bkgColor}/${color}?text=${t}`;
  return urlToImage(url);
}

export function getImageWidth(image) {
  return (
    (image &&
      image.props &&
      image.props.file &&
      image.props.file.details &&
      image.props.file.details.image &&
      image.props.file.details.image.width) ||
    null
  );
}

export function getImageHeight(image) {
  return (
    (image &&
      image.props &&
      image.props.file &&
      image.props.file.details &&
      image.props.file.details.image &&
      image.props.file.details.image.height) ||
    null
  );
}

export function getImageAspectRatio(image) {
  if (
    image &&
    image.props &&
    image.props.file &&
    image.props.file.details &&
    image.props.file.details.image
  ) {
    return (
      image.props.file.details.image.height /
      image.props.file.details.image.width
    );
  }
}

export function getNewImageHeight(image, newWidth) {
  const width = getImageWidth(image);
  const height = getImageHeight(image);
  const newHeight = (height / width) * newWidth;

  return newHeight;
}

export function getImageContentType(image) {
  return (
    (image &&
      image.props &&
      image.props.file &&
      image.props.file.contentType) ||
    null
  );
}
