import React from 'react';
import { Box, Collapse } from 'design-system';
import { Image, Text } from 'components';

export class SpecialAnnouncements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHideBanner: false,
    };
  }

  handleOnClose = () => {
    this.setState({
      isHideBanner: true,
    });
  };

  render() {
    const { title, link, icon } = this.props;
    const { isHideBanner } = this.state;
    return (
      <Collapse expanded={!isHideBanner}>
        <Box
          display="flex"
          position="sticky"
          top="0"
          left="0"
          zIndex="5000"
          alignItems="center"
          justifyContent={['left', 'center', 'center', 'center']}
          bg="#E1E9EE"
          p={['18px', '27px']}
          width="100%"
          name="special-annoucements"
        >
          {icon && icon.props && (
            <Box
              width="24px"
              minWidth="24px"
            >
              <Image
                isAnimated={false}
                {...icon.props}
              >
                {icon}
              </Image>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            textAlign={['left', 'center', 'center', 'center']}
            style={{ margin: '0px 8px' }}
          >
            <Text type="Body" color="text.0" width="auto">
              {title}
            </Text>
            &nbsp;
            {link}
          </Box>
          <Box
            onClick={this.handleOnClose}
            css="position: absolute; right: 20px;"
          >
            <Image
              url="closeIcon_cpzlof.svg"
              width={['14px', '14px', '14px', '24px']}
              style={{ cursor: 'pointer' }}
              isAnimated={false}
            />
          </Box>
        </Box>
      </Collapse>
    );
  }
}
