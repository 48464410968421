import React from 'react';
import { Heading } from 'design-system';

const variantPropsDict = {
  default: {
    fontSize: ['headingM', 'headingL', 'headingL', 'headingXl'],
    // old [7,9,9,10]
    lineHeight: ['60px', 10, 10, 12],
  },
  smaller: {
    fontSize: ['headingM', '72px', '72px', 'headingL'],
    // old [7, 8, 8, 9]
    lineHeight: [14],
  },
};

export const LargestHeading = ({
  text,
  homepage,
  variant = 'default',
  ...props
}) => (
  <Heading
    is="h1"
    {...variantPropsDict[variant]}
    m={0}
    css="max-width: 100%;"
    data-qaid={text ? 'largestHeading' : null}
    {...props}
  >
    {text}
  </Heading>
);
