import React from 'react';
import system from 'system-components';

export const SvgIcon = ({
  useInline = true,
  children,
  title,
  viewBox,
  ...props
}) => {
  let propsOverrides = {};
  if (!useInline) {
    /**
     * 'useInline' meant to indicate the icon is present with in the text.
     * In this case icon is shrunk to 55% to be in line with the font.
     */
    propsOverrides = {
      width: props.width ? props.width : '100%',
      height: props.height ? props.height : '100%',
    };
  }
  return (
    <StyledSvg
      focusable="false"
      viewBox={viewBox}
      aria-hidden={title ? 'false' : 'true'}
      role={title ? 'img' : 'presentation'}
      {...props}
      {...propsOverrides}
    >
      {children}
      {title ? <title>{title}</title> : null}
    </StyledSvg>
  );
};
SvgIcon.displayName = 'SvgIcon';

const StyledSvg = system(
  {
    is: 'svg',
    width: '.55em',
    height: '.55em',
    display: 'inline-block',
    fill: 'currentColor',
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
    viewBox: '0 0 24 24',
    userSelect: 'none',
    blacklist: ['fill', 'flexShrink', 'verticalAlign', 'userSelect'],
  },
  'space',
  'width',
  'color',
  'fontSize',
  // typography
  'textAlign',
  // layout
  'lineHeight',
  'display',
  'maxWidth',
  'minWidth',
  'height',
  'maxHeight',
  'minHeight',
  // flexbox
  'alignItems',
  'alignContent',
  'justifyContent',
  'flexWrap',
  'flexDirection',
  'flex',
  'flexBasis',
  'justifySelf',
  'alignSelf',
  'order',
  // position
  'position',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',
  // background
  'background',
  // variants
  'colorStyle',
  'hover',
  'focus',
  ({ fill: fillColor, flexShrink, verticalAlign, userSelect }) => ({
    fill: fillColor,
    flexShrink,
    verticalAlign,
    userSelect,
  }),
);
