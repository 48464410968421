import React from 'react';
import { Link, Icon, OverflowLabel, withStyles } from '../../design-system';
import { isType } from '../../utils';

// Now with some variants!
const LinkVariant = withStyles(
  (theme, props, themeType = 'light') => ({
    default: {
      colors: props.colors || `${themeType}BackgroundCTAs`,
      display: props.ellipsis ? 'inline-flex' : null,
      width: props.ellipsis ? '100%' : 'auto',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    contained: {
      colors: props.colors || `${themeType}BackgroundCTAs`,
      bg: themeType === 'dark' ? 'bg.0' : 'bg.1',
      p: ['18px', '18px', '20px'],
      width: '100%',
      textAlign: 'center',
      display: 'block',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  }),
  Link,
);

// Important: This element will be wrapped by many other elements to pass the inherit properties
// It is used to gather information from Contentful
export const IconLink = ({
  entryId,
  buildContext,
  //
  // DEPRECATED: colorStyle, in favour of `colors`
  colorStyle,
  colors: colorsProp,
  internal: isInternal,
  url: href,
  ariaLabel,
  text: label,
  icon: contentfulIcon = null,
  iconBefore = null,
  iconAfter = null,
  iconBeforeProps = { mr: 1 },
  iconAfterProps = { ml: 2 },
  ellipsis = false,
  dataOn,
  dataEventCategory,
  dataEventAction,
  dataEventLabel,
  ...props
}) => {
  let autoArrow;

  if (isInternal && !iconBefore && !iconAfter) {
    autoArrow = '→';
  } else if (isInternal === false && !iconBefore && !iconAfter) {
    autoArrow = '↗';
  } else {
    autoArrow = null;
  }

  /*
     NOTE:  Passing glyphs will be deprecated in favour of passing
            a declarative <Icon /> component. This will allow the dev
            to pass another form of icon (IconImg, SVGIcon, etc).

            The `icon` prop will be left 'as is' for backwards
            compatability as this may be passed from Contentful to
            override a glyph.
  */
  /* eslint-disable no-param-reassign */
  // Test if <Icon> is being passed instead of just a glyph
  if (iconBefore && !isType(iconBefore, 'Icon')) {
    iconBefore = <Icon {...iconBeforeProps}>{iconBefore}</Icon>;
  }
  // The `icon` prop is always a glyph, so a type-check need only be done for iconAfter
  if (
    (contentfulIcon || autoArrow || iconAfter) &&
    !isType(iconAfter, 'Icon')
  ) {
    iconAfter = (
      <Icon {...iconAfterProps}>
        {iconAfter || contentfulIcon || autoArrow}
      </Icon>
    );
  }
  /* eslint-enable no-param-reassign */

  // for overriding colours (should be rare)
  const colors =
    !!colorStyle || !!colorsProp ? { colors: colorStyle || colorsProp } : null;

  const QAID = { 'data-qaid': label && ariaLabel && href ? 'iconLink' : null };
  // The label is joint this way to ensure that the icon wraps with the last word in the label
  const labelMinusLastWord = label
    .split(' ')
    .slice(0, -1)
    .join(' ');

  return (
    <LinkVariant
      {...colors}
      ellipsis={ellipsis}
      isInternal={isInternal}
      href={href}
      aria-label={ariaLabel}
      data-on={dataOn}
      data-event-category={dataEventCategory}
      data-event-action={dataEventAction}
      data-event-label={dataEventLabel}
      {...QAID}
      {...props}
    >
      {ellipsis ? (
        <>
          {iconBefore}
          <OverflowLabel className="label" ellipsis={ellipsis}>
            {label}
          </OverflowLabel>
          {iconAfter}
        </>
      ) : (
        <>
          {iconBefore}
          <span className="label">{labelMinusLastWord}</span>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span className="label labelLastWord">{` ${
              label.split(' ').slice(-1)[0]
            }`}</span>
            {iconAfter}
          </span>
        </>
      )}
    </LinkVariant>
  );
};
