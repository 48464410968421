import React from 'react';
import { theme, Text } from 'design-system';

export const TextEditorial = ({ props, text, spacingBelowText }) => (
  <Text
    is="p"
    color="text.0"
    fontSize={['subHeadingM', 'subHeadingM', 'subHeadingM', 'headingS']}
    // old [4, 4, 4, 6]
    lineHeight={[6, 6, 6, '60px']}
    data-qaid={text ? 'text' : null}
    m="0"
    mb={spacingBelowText && theme.verticalSpace.small}
    css="-webkit-margin-before: 0; margin-before: 0;"
    {...props}
  >
    {text}
  </Text>
);
