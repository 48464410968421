import React from 'react';
import system from 'system-components';

const textShadow = (props) => ({ textShadow: props.textShadow });
const StyledIcon = system(
  {
    is: 'span',
    blacklist: ['textShadow'],
  },
  'space',
  'color',
  'fontSize',
  'fontFamily',
  'lineHeight',
  'width',
  'height',
  'display',
  'flex',
  'justifyContent',
  'alignItems',
  'boxShadow',
  textShadow,
);

export const Icon = ({ children, ...props }) =>
  React.Children.count(children) >= 1 ? (
    <StyledIcon {...props} aria-hidden>
      {children}
    </StyledIcon>
  ) : null;
Icon.displayName = 'Icon';
