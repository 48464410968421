import React from 'react';
import { Row, Column, Box } from 'design-system';

// Single Image
// Width options: 100%, 75%, 50%
// Alignment: Left (default), Center, Right

// 100% width, alignment does nothing at all
// 75% or 50% then alignment applies

// If no mobile image is provided it defaults to desktop. Desktop image is a required field

export const EditorialImgSingle = ({
  imageSet,
  widthOption,
  alignmentOption,
  ...props
}) => {
  let width = 1;

  if (widthOption === '100%') {
    width = 1;
  } else if (widthOption === '75%') {
    width = 0.75;
  } else if (widthOption === '50%') {
    width = 0.5;
  }

  let justify = 'flex-start';
  let ml = null;
  let mr = null;

  if (alignmentOption === 'left') {
    justify = 'flex-start';
    ml = null;
    mr = null;
  } else if (alignmentOption === 'center') {
    justify = 'center';
    ml = 'auto';
    mr = 'auto';
  } else if (alignmentOption === 'right') {
    justify = 'flex-end';
    ml = 'auto';
  }

  let imageSetWithContext = null;
  if (imageSet !== undefined && imageSet !== null) {
    imageSetWithContext = React.cloneElement(imageSet, {
      imageContextWidth: width,
      imageContextGutterCount: 0,
    });
  }

  return (
    <Row
      data-qaid={imageSetWithContext ? 'EditorialImgSingle' : null}
      {...props}
    >
      <Column width={1}>
        <Box
          width={width}
          display="flex"
          alignItems="center"
          justifyContent={justify}
          ml={ml}
          mr={mr}
        >
          {imageSetWithContext}
        </Box>
      </Column>
    </Row>
  );
};
