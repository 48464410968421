/* eslint-disable no-nested-ternary */
import React from 'react';

import { Box, horizontalSpace, verticalSpace, getOffset } from 'design-system';
import { camelize } from '../patterns';
import { applyTheme } from '../../utils';

export const Grid = ({
  cells = [],
  paddingH = 'none',
  paddingV = 'none',
  reverseMobile = false,
  backgroundColour,
  centerContent,
  theme,
  handleOnHover,
  handleOnHoverLeave,
  isReducedMotion,
  scrollY,
  offset,
  gutters = 'none', // column gap
  cellBottomPadding = 'none', // row gap
  // gridTemplate, // to be added in next PR
  columnRatio = '1:1',
  columnRatioTablet = columnRatio,
  columnRatioMobile = '1',
  cellHeight = 'stretch',
}) => {
  const cellsWithProps = cells.map((cell) =>
    React.cloneElement(cell, {
      handleOnHover,
      handleOnHoverLeave,
      isReducedMotion,
      scrollY,
    }),
  );

  const cellsWithTheme = applyTheme(cellsWithProps, theme, backgroundColour);

  const grid = cellsWithTheme;

  // Column ratio
  const desktopColRatio = columnRatio.split(':').join('fr ');
  const tabletColRatio = columnRatioTablet.split(':').join('fr ');
  const mobileColRatio = columnRatioMobile.split(':').join('fr ');

  return (
    <Box
      px={horizontalSpace[camelize(paddingH)]}
      py={verticalSpace[camelize(paddingV)]}
      mt={getOffset(camelize(offset))}
      bg={camelize(backgroundColour)}
      width="100%"
    >
      <Box
        width="100%"
        height="100%"
        display="grid"
        flexDirection="column-reverse"
        gridTemplateColumns={[
          `${mobileColRatio}fr`,
          `${tabletColRatio}fr`,
          `${desktopColRatio}fr`,
        ]}
        gridColumnGap={verticalSpace[camelize(gutters)]}
        gridRowGap={verticalSpace[camelize(cellBottomPadding)]}
        alignItems={camelize(cellHeight) === 'individual' ? 'start' : 'stretch'}
      >
        {grid.map((item, index) => {
          return (
            <Box
              key={`container-${item.key}`}
              display="flex"
              justifyContent={
                centerContent === 'Horizontal' || centerContent === 'Both'
                  ? 'center'
                  : 'inital'
              }
              alignItems={
                centerContent === 'Vertical' || centerContent === 'Both'
                  ? 'center'
                  : 'auto'
              }
              order={[reverseMobile ? `${grid.length - index}` : index, index]}
            >
              {item}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
