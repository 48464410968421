import React from 'react';

export const SearchIcon = (props) => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 24C32 28.4183 28.4183 32 24 32C19.5817 32 16 28.4183 16 24C16 19.5817 19.5817 16 24 16C28.4183 16 32 19.5817 32 24ZM30.6474 32.765C28.8009 34.1676 26.4976 35 24 35C17.9249 35 13 30.0751 13 24C13 17.9249 17.9249 13 24 13C30.0751 13 35 17.9249 35 24C35 26.4976 34.1676 28.8007 32.7651 30.6472L39 36.8821L36.8822 38.9999L30.6474 32.765Z"
      fill="currentColor"
    />
  </svg>
);
