/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';

import { GeneralMeta, HelmetScripts, ImageMeta } from 'components';
import { SITE_CONFIG, formatTitle, withLocation, getSiteSettings } from 'utils';
import url from 'url';

export const BasePageHelmet = ({
  data = {},
  language = SITE_CONFIG.siteLanguageMeta,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  location: { location },
  extraMeta,
  openGraphType = 'website',
}) => {
  // Getting site data from contentful for meta description
  const siteSettings = getSiteSettings();

  const canonical = url.resolve(SITE_CONFIG.siteUrl, location.pathname, `/`);
  const unformattedTitle =
    titleProp ||
    data.meta_title ||
    data.name ||
    data.title ||
    SITE_CONFIG.siteTitleMeta;

  const title = formatTitle(unformattedTitle);
  const description =
    descriptionProp ||
    data.meta_description ||
    data.description ||
    data.excerpt ||
    data.bio ||
    siteSettings.props.metaDescription;

  const shareImage = url.resolve(
    SITE_CONFIG.siteUrl,
    imageProp ||
      (data.feature_image &&
        `https://res.cloudinary.com/rangle/image/fetch/w_700/${
          data.feature_image
        }`) ||
      data.profile_image ||
      SITE_CONFIG.shareImage,
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <script>
          {`
        /**
         * IE 11 Polyfill for CustomEvent (@see https://github.com/damienbod/angular-auth-oidc-client/issues/276)
         * TODO:  include from a better polyfill solution.
         */
        (function () {
          function CustomEvent ( event, params ) {
            params = params || { bubbles: false, cancelable: false, detail: undefined };
            var evt = document.createEvent( 'CustomEvent' );
            evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
            return evt;
          }

          CustomEvent.prototype = window.Event.prototype;

          window.CustomEvent = CustomEvent;
        })();
        `}
        </script>
        <meta
          name="google-site-verification"
          content="TZB0CK0qYKk4Effi3SbiGz86dZSwbzVqWdbq2d4UBes"
        />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />

        <meta
          name="twitter:site"
          content={`https://twitter.com/${SITE_CONFIG.twitterHandle.replace(
            /^@/,
            '',
          )}/`}
        />
        <meta name="twitter:creator" content={SITE_CONFIG.twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />

        <meta property="og:site_name" content={SITE_CONFIG.siteTitleMeta} />
        <meta property="og:type" content={openGraphType} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />

        <html lang={language} />
      </Helmet>
      <GeneralMeta />
      <ImageMeta image={shareImage} />
      {extraMeta}
      <HelmetScripts />
    </>
  );
};

export const PageHelmet = withLocation(BasePageHelmet);
