import React from 'react';
import {
  Box,
  Caption,
  Column,
  Link,
  maintainAspectRatioInnerStyle,
  maintainAspectRatioWrapperStyle,
  Text,
  theme,
} from 'design-system';
import { themeGet } from 'styled-system';
import { useHover } from 'utils';

export const BlogCard = (props) => {
  const {
    title,
    image,
    cta,
    linkUrl,
    featured = false,
    linkTitle,
    // authorName,
    datePublished,
    numberOfCards,
    index,
    dataOn,
    dataEventCategory,
    dataEventAction,
    dataEventLabel,
  } = props;
  const [hoverState, hoverProps] = useHover();

  const easeShort = themeGet(
    'transitions.easeOut.short',
    '0.3s ease-out',
  )(props);

  let imageWithContext = null;

  if (image !== null && image !== undefined) {
    imageWithContext = React.cloneElement(image, {
      imageContextWidth: featured ? 0.5 : 0.25,
      imageContextGutterCount: featured ? 1 : 3,
      wrapperStyle: maintainAspectRatioWrapperStyle,
      imageStyle: maintainAspectRatioInnerStyle,
    });
  }

  const ImageContainer = (
    <Box width={!featured ? [1, 1 / 3, 1 / 3, 1 / 2] : 1} m={0}>
      <Box position="relative" pb="100%" overflow="hidden">
        {imageWithContext}
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          background={hoverState ? theme.colors.brand[0] : null}
          display="flex"
          justifyContent="center"
          alignItems="center"
          css={`
            opacity: 0.35;
            transition: background-color ${easeShort};
          `}
        />
      </Box>
    </Box>
  );

  const BoxOrColumn = featured ? Box : Column;
  return (
    <BoxOrColumn
      data-qaid={title ? 'BlogCard' : null}
      mb={[numberOfCards === index + 1 ? 0 : theme.verticalSpace.large, 0]}
    >
      <Link
        {...hoverProps}
        href={linkUrl || null}
        hover={{
          textDecoration: 'none',
        }}
        focus={{
          outline: 'none',
        }}
        title={linkTitle}
        data-on={dataOn}
        data-event-category={dataEventCategory}
        data-event-action={dataEventAction}
        data-event-label={dataEventLabel}
      >
        <Box
          display={featured ? 'block' : 'flex'}
          mb={
            featured
              ? null
              : [theme.verticalSpace.large, theme.verticalSpace.medium]
          }
          flexDirection={featured ? 'column' : ['column', 'row-reverse']}
        >
          {ImageContainer}
          <Box
            display={featured ? 'block' : 'flex'}
            flexDirection="column"
            width={featured ? 1 : [1, 2 / 3, 2 / 3, 1 / 2]}
            borderTop={featured ? null : `1px solid ${theme.colors.uiDivider}`}
            mr={featured ? null : theme.verticalSpace.medium}
          >
            <Box pt={theme.verticalSpace.small} pb={theme.verticalSpace.xSmall}>
              <Text
                is="p"
                fontSize={['bodyM', 'bodyM', 'bodyM', 'bodyL']}
                // old {[2, 2, 2, 3]}
                lineHeight={[4, 4, 4, 6]}
                color={hoverState ? theme.colors.btnTextHover[0] : 'text.0'}
                data-qaid={cta ? 'textCTA' : null}
                css={`
                    ${hoverState && 'text-decoration: underline;'}
                    transition: color ${easeShort};
                  `}
                display="inline"
              >
                {cta}
              </Text>
              <span
                style={{
                  color: hoverState
                    ? theme.colors.btnTextHover[0]
                    : theme.colors.text[0],
                }}
              >
                {' '}
                →
              </span>
            </Box>
            <Box>
              {/* <Caption is="p" mt="0" mb="0" display="inline" mr="5px"> */}
              {/* {authorName} */}
              {/* </Caption> */}
              <Caption is="p" mt="0" mb="0" display="inline" mr="5px">
                {datePublished}
              </Caption>
            </Box>
          </Box>
        </Box>
      </Link>
    </BoxOrColumn>
  );
};
