import system from 'system-components';
import { withStyles } from '../styles';

export const BaseTextArea = system(
  {
    is: 'textarea',
    background: 'transparent',
    border: '1px solid',
    color: 'btnText.0',
    fontFamily: 'light',
    fontSize: ['bodyM', 'bodyL'], // old [2, 3]
    lineHeight: [4, 6],
    p: '16px',
    width: 1,
    blacklist: ['isInvalid'],
  },
  'space',
  'focus',
  'borderColor',
  () => ({
    outline: 0,
  }),
);
BaseTextArea.displayName = 'BaseTextArea';

export const TextArea = withStyles(
  (theme, props) => ({
    default: {
      borderColor: props.isInvalid ? 'error.0' : 'ui.2',
      focus: {
        borderColor: props.isInvalid ? 'error.0' : 'ui.0',
      },
    },
  }),
  BaseTextArea,
);
TextArea.displayName = 'TextArea';
