import React from 'react';
import styled from 'styled-components';
import { Box } from 'design-system';
import {
  space,
  color,
  layout,
  flexbox,
  typography,
  colorStyle,
  fontWeights,
  lineHeights,
} from 'styled-system-next';
import kebabCase from 'lodash/kebabCase';

import { fontSize, horizontalSpace } from '../../design-system/theme';
import { camelize, Markdown } from '../patterns';
import { FadeInWrapper } from '../../utils/components';

// Using a div instead of a p here to prevent a bug with gatsby/markdown rendering on production builds
// https://github.com/gatsbyjs/gatsby/issues/11108
export const BaseText = styled.div.attrs({
  fontFamily: 'rangle riforma',
  m: 0,
  lineHeight: '1.5em',
})`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
  ${colorStyle}
  ${fontWeights}
  ${lineHeights}
  font-variant-ligatures: none;
`;
BaseText.defaultProps = {
  color: 'black',
  fontWeight: 'regular',
};

const LargeHeading = styled(BaseText)``;
LargeHeading.defaultProps = {
  fontSize: fontSize.xLarge,
  letterSpacing: '-0.01em',
  color: 'black',
  fontWeight: 'regular',
};

const MediumHeading = styled(BaseText)``;
MediumHeading.defaultProps = {
  fontWeight: 'medium',
  fontSize: fontSize.large,
  color: 'black',
};

const SmallHeading = styled(BaseText)``;
SmallHeading.defaultProps = {
  fontWeight: 'medium',
  fontSize: fontSize.medium,
  color: 'black',
};

const widthDictionary = {
  '25%': [
    1, // 0~767
    0.25, // 768
    0.25, // 1024
    0.25, // 1366
  ],
  '50%': [1, 0.5, 0.5, 0.5],
  '75%': [1, 0.75, 0.75, 0.75],
  '100%': [1],
};

export const Text = ({
  type = 'Large Heading',
  content,
  paddingH = 'none',
  width = '100%',
  theme = 'light',
  children,
  alignment = 'left',
  isReducedMotion,
  animation,
  ...props
}) => {
  const { scrollY } = props;
  const Wrapper =
    isReducedMotion || !scrollY || animation ? React.Fragment : FadeInWrapper;
  const wrapperProps =
    isReducedMotion || !scrollY ? {} : { ...props, isInline: type === 'Legal' };
  const chosenSizes = widthDictionary[width];
  const markdownContent = content ? (
    <Markdown theme={theme} width={chosenSizes}>
      {content}
    </Markdown>
  ) : (
    children
  );
  const commonProps = {
    px: horizontalSpace[camelize(paddingH)],
    color: theme === 'dark' ? 'white' : undefined,
    textAlign: camelize(alignment),
    id: kebabCase(content),
  };

  const renderSwitch = () => {
    switch (type) {
      case 'Large Heading':
        return (
          <LargeHeading as="h1" {...commonProps} {...props}>
            {markdownContent}
          </LargeHeading>
        );
      case 'Medium Heading':
        return (
          <MediumHeading as="h2" {...commonProps} {...props}>
            {markdownContent}
          </MediumHeading>
        );
      case 'Small Heading':
        return (
          <SmallHeading as="h3" {...commonProps} {...props}>
            {markdownContent}
          </SmallHeading>
        );
      case 'Editorial':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.large}
            color={theme === 'dark' ? 'lightGrey' : 'darkGrey'}
            {...props}
          >
            {markdownContent}
          </BaseText>
        );
      case 'Quote':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.large}
            color={theme === 'dark' ? 'lightGrey' : 'darkGrey'}
            {...props}
          >
            <Box pl="24px" borderLeft={2} borderColor="red">
              {markdownContent}
            </Box>
          </BaseText>
        );
      case 'Large Body':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.medium}
            color={theme === 'dark' ? 'lightGrey' : 'darkGrey'}
            {...props}
          >
            {markdownContent}
          </BaseText>
        );
      case 'Large Body Dark':
        return (
          <BaseText {...commonProps} fontSize={fontSize.medium} {...props}>
            {markdownContent}
          </BaseText>
        );
      case 'Medium Body':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.small}
            color={theme === 'dark' ? 'lightGrey' : 'darkGrey'}
            {...props}
          >
            {markdownContent}
          </BaseText>
        );
      case 'Medium Body Dark':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.small}
            fontWeight="medium"
            {...props}
          >
            {markdownContent}
          </BaseText>
        );
      case 'Small Body':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.xSmall}
            color={theme === 'dark' ? 'lightGrey' : 'darkGrey'}
            {...props}
          >
            {markdownContent}
          </BaseText>
        );
      case 'Legal':
        return (
          <BaseText
            {...commonProps}
            fontSize={fontSize.xxSmall}
            color={theme === 'dark' ? 'lightGrey' : 'darkGrey'}
            {...props}
          >
            {markdownContent}
          </BaseText>
        );
      default:
        return <Box bg="yellow">NEED TO CHANGE TYPOGRAPHY</Box>;
    }
  };

  return <Wrapper {...wrapperProps}>{renderSwitch()}</Wrapper>;
};
