import React from 'react';
import { Box } from 'design-system';

import { camelize } from '../patterns';
import { horizontalSpace, theme } from '../../design-system/theme';

const rowStyles = {
  display: 'flex',
  flexDirection: ['column', 'row'],
  alignItems: 'start',
  py: 'medium',
  borderTop: `${theme.borders[7]}`,
};

const TableRow = ({ columnRatio, items, children, ...props }) => {
  const cells = items || children;
  // Calculate the number of columns based on the column ratio that's entered in
  const columns = columnRatio.split(':').length;

  return (
    <Box>
      <Box role="row" {...rowStyles} {...props}>
        {!cells || !cells.length ? (
          <span>This row has no items</span>
        ) : (
          [...Array(columns).keys()].map((i) => {
            let mobilePadding = 'xxSmall';
            if (i === 0) {
              mobilePadding = 'xSmall';
            } else if (i === columns - 1) {
              mobilePadding = 'none';
            }

            return (
              <Box
                key={cells[i] ? cells[i].name : i}
                role="cell"
                pb={[mobilePadding, 'none']}
                pr={['none', 'medium']}
                flex={columnRatio.split(':')[i]}
              >
                {cells[i] || <span>No cell was defined</span>}
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export const Table = ({
  name,
  columnRatio = '',
  tableRows,
  paddingH = 'none',
  children,
}) => {
  const row = tableRows || children;

  return (
    <Box px={horizontalSpace[camelize(paddingH)]} role="table" name={name}>
      {!row || !row.length ? (
        <span>This table has no rows</span>
      ) : (
        row.map(({ props = {} }, i) => (
          <TableRow
            key={props.name || props.id}
            columnRatio={columnRatio}
            borderBottom={
              i === row.length - 1
                ? `${theme.borders[7]}`
                : `${theme.borders[0]}`
            }
            {...props}
          />
        ))
      )}
    </Box>
  );
};
