import React from 'react';
import pascalCase from 'pascal-case';

import {
  ArticleCardGrid,
  ArticleCardRespImgSet,
  ArticleCard,
} from 'components';
import {
  Image,
  maintainAspectRatioInnerStyle,
  maintainAspectRatioWrapperStyle,
  theme,
  Box,
  Caption,
} from 'design-system';

const CardChip = ({ typeName }) => (
  <Box
    bg={theme.colors.bg[1]}
    py={[theme.verticalSpace.xSmall]}
    px={theme.gutters}
  >
    <Caption is="span">{typeName}</Caption>
  </Box>
);

export const RelatedContentCardGrid = ({
  contentSpecs,
  cardTitlePaddingBottom = theme.verticalSpace.small,
  forceColumnWidthToThirds = false,
}) => {
  return (
    <ArticleCardGrid
      mx={theme.gutters.map((gutter) => gutter * -1)}
      maxWidth={['none']}
      width={theme.doubleGutters.map(
        (doubleGutter) => `calc(100% + ${doubleGutter})`,
      )}
      articleCard={contentSpecs.map(
        ({ title, imageUrl, publishedDate, linkUrl, nodeType }) => {
          const cardProps = {
            title,
            publishedDate,
            linkUrl,
            linkTitle: title,
            length: contentSpecs.length,
            image: (
              <ArticleCardRespImgSet
                imageDesktop={<Image src={imageUrl} />}
                altText={title}
                wrapperStyle={maintainAspectRatioWrapperStyle}
                imageStyle={maintainAspectRatioInnerStyle}
              />
            ),
            cta: title,
            chip: nodeType ? (
              <CardChip typeName={pascalCase(nodeType)} />
            ) : null,
            imageWrapperProps: {
              pb: '100%',
            },
          };
          return (
            <ArticleCard
              key={`article-card-post-slug-${linkUrl}`}
              {...cardProps}
              titlePaddingBottom={cardTitlePaddingBottom}
              forceColumnWidthToThirds={forceColumnWidthToThirds}
            />
          );
        },
      )}
    />
  );
};
