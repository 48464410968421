import React from 'react';
import { Box, Row, Column, theme } from 'design-system';

export const FooterListContainer = ({ footerList, footerSlogan }) => {
  return (
    <Row py={theme.gutters}>
      <Column
        width={[1, 0, 0, 1 / 4]}
        display={['inherit', 'none', 'none', 'inherit', 'inherit']}
      >
        {footerSlogan}
      </Column>
      <Column
        display="flex"
        width={[1, 1, 1, 3 / 4]}
        flexDirection={['column', 'row']}
      >
        {React.Children.map(footerList, (list) => (
          <Box width={[1, 1 / 3]}>{list}</Box>
        ))}
      </Column>
    </Row>
  );
};
