/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { theme, Box, verticalSpace, columnWidth } from 'design-system';
import { Spacing } from 'components';

// Algolia
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch-dom';

import { ConditionalContentDisplay } from './ConditionalContent';
import { CustomSearchBox } from './SearchBox';

// Algolia client (public facing keys)
const globalSearchClient = algoliasearch(
  'EQ5EN3ADUV',
  '048a253b29f81770d3572c6d1effa18f',
);

const prefix = process.env.GATSBY_ALGOLIA_INDEX_PREFIX || '';

export const SearchModal = ({
  placeholderText,
  isDesktop,
  isMobileOpen,
  isSearchOpen,
  toggleDisplay,
  ...containerProps
}) => (
  <Box
    position={isDesktop ? 'absolute' : 'relative'}
    width={isDesktop ? columnWidth(8) : '100%'}
    minHeight={isDesktop ? '100%' : 'unset'}
    pb={isDesktop ? columnWidth(1) : '0'}
    pt={0}
    px={columnWidth(1)}
    {...containerProps}
  >
    <InstantSearch
      indexName={`${prefix}Pages`}
      searchClient={globalSearchClient}
    >
      <Box
        position="fixed"
        pt={isDesktop ? verticalSpace.large : '0'}
        width={isDesktop ? columnWidth(6) : '80%'}
        background={isDesktop ? theme.colors.black : 'transparent'}
        zIndex={10}
      >
        <Spacing height="xSmall" />
        <CustomSearchBox
          placeholderText={placeholderText}
          isMobileOpen={isMobileOpen}
          isSearchOpen={isSearchOpen}
        />
        <Spacing height="medium" />
      </Box>
      <Box
        position="relative"
        mt={isDesktop ? verticalSpace.large : '0'}
        pt={isDesktop ? verticalSpace.xLarge : '0'}
      >
        <Spacing height="medium" />
        {/* Omit hitsPerPage until indices are amalgamated */}
        {/* <Configure hitsPerPage={6} /> */}
        <ConditionalContentDisplay
          isDesktop={isDesktop}
          toggleDisplay={toggleDisplay}
        />
      </Box>
    </InstantSearch>
  </Box>
);
