import React, { Fragment } from 'react';
import { verticalSpace } from 'design-system';

import kebabCase from 'lodash/kebabCase';
import { Button, Link } from '../../modules';
import { ListItem } from '../../../design-system/blocks';
import { PlusMinusIcon } from '../../elements/PlusMinusIcon';

export const NavigationItem = ({
  subItems,
  label,
  link,
  theme,
  isActive,
  isOpen,
  isSubItem,
  updateExpandedId,
  collapsible,
  ...props
}) => {
  const isNavGroupOpen = isOpen || collapsible === 'No';
  // Builds the HTML for the links that do not expand
  const buildNavigationLink = () => (
    <Link
      display={[
        isSubItem ? 'block' : 'none',
        isSubItem ? 'block' : 'none',
        'block',
      ]}
      href={link}
      linkConfig={{ props: { url: `${link}` } }}
      hideArrow={!isActive || !isSubItem}
      arrowPosition="left"
      colors={`${theme}BackgroundNavLink`}
      type="Small"
      fontWeight="regular"
      isActive={isActive}
      id={`nav-${kebabCase(label)}`}
      letterSpacing="xLarge"
      {...props}
    >
      {label}
    </Link>
  );

  const buildNavigationButton = () => (
    <Button
      display={[
        isSubItem ? 'none' : 'block',
        isSubItem ? 'none' : 'block',
        'none',
      ]}
      onClick={updateExpandedId}
      id={`nav-${kebabCase(label)}`}
      label={label}
      isActive={isActive}
      colors={`${theme}BackgroundNavLink`}
      {...props}
    >
      <span
        tabIndex="-1"
        className="label"
        style={{
          outline: 'none',
        }}
      >
        {label}
        {isOpen ? <PlusMinusIcon isMinus /> : <PlusMinusIcon />}
      </span>
    </Button>
  );

  const MenuItem = subItems ? Fragment : ListItem;

  return (
    <MenuItem display={label ? 'block' : 'none'} pb={verticalSpace.xSmall}>
      {buildNavigationLink()}
      {buildNavigationButton()}
    </MenuItem>
  );
};
